import { AlarmNotificationSettingsComponent } from './modal/alarm-notification-settings/alarm-notification-settings.component';
import { Location } from '@angular/common';
import { Component, DoCheck, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbActiveModal, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import { fromEvent, merge, of } from 'rxjs';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { finalize, map} from 'rxjs/operators';
import { ApplicationService } from '../../../services/application.service';
import { AuthService } from '../../../services/auth.service';
import { NotificationService } from '../../../services/notification.service';
import { AlarmService } from '../../../services/alarm.service';
import { UserTypeResolverService } from '../../../services/user-type-resolver.service';
import { DashboardService } from '../../containers/dashboard/dashboard.service';
import { MainHeaderService } from './main-header.service';
import { LogoutConfirmationModalComponent } from './modal/logout-confirmation-modal/logout-confirmation-modal.component';
import { NotificationSettingsComponent } from './modal/notification-settings/notification-settings.component';
import { SystemNotificationsComponent } from './modal/system-notifications/system-notifications.component';
import { PopUpService } from 'src/services/popUp.service';
import {QRScanPopupComponent} from '../../containers/dashboard/modals/qr-scan-popup/qr-scan-popup.component'
const Jwthelper = new JwtHelperService();
import { KeycloakService } from 'keycloak-angular';
import { UserRole } from 'src/app/models/userrole';
import { Subscription } from 'rxjs';
import * as RouteHelper from 'src/app/utility/routing.helper';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
  providers: [NgbActiveModal]
})
export class MainHeaderComponent implements OnInit, OnChanges, OnDestroy, DoCheck {
  @ViewChild('role', { static: false }) role: ElementRef;
  @ViewChild('version', { static: false }) version: ElementRef;
  @ViewChild('navbar', { static: false }) navbar: ElementRef;
  @ViewChild('hamburger', { static: false }) hamburger: ElementRef;
  @ViewChild('systemWarn', { static: false }) systemWarn: ElementRef;
  @ViewChild('systemDetail', { static: false }) systemDetail: ElementRef;

  public db: any = {};
  public userType: string;
  public RefershTokenCalled: any;
  public project: any;
  userName: string;
  buildVersionNumber: string;
  buildVersionNumberError: boolean;
  userImage;
  showDropdown = false;
  loading = false;
  public topMenu = [];
  public bottomMenu = [];
  public allMenu = {};
  login: boolean;
  public highlightNotification = null;
  public systemNotifications = { relCareConnectionError: false, scadaGlobalError: false, scadaAssetErrors: [] };
  versionList = [];
  isRefreshToken: boolean = false;
  userRole = '';
  roleType = '';
  custName = '';
  projName = '';
  @Input() showExtraInfo: boolean;
  display: string;
  rolePopup = false;
  versionPopup = false;
  public openSide = false;
  selectedRole: any = {};
  selectedVersion: any = {};
  filterRoles: any = [];
  hideClientProjectDetail = false;
  isSystemEnv : boolean;
  ishide = true;
  modalRef;
  public metadataInfo: { title: string, clientName: string, projectName: string, kernelVersion: string, systemDeploymentName: string };
  allRoles: any = [
    { 'name': UserRole.ProjectManager, 'role': 'PM' },
    { 'name': UserRole.ProjectManager_C, 'role': 'Project Manager (C)', 'active': false },
    { 'name': UserRole.ProjectSupport, 'role': 'Project Support' },
    { 'name': UserRole.QualitySME, 'role': 'Quality SME' },
    { 'name': UserRole.PolicySME, 'role': UserRole.PolicySME },
    { 'name': UserRole.OperationSME, 'role': 'Operation SME' },
    { 'name': UserRole.RemoteOperator, 'role': 'Remote Operator' },
    { 'name': UserRole.Planner, 'role': 'PLANNER', 'active': false },
    { 'name': UserRole.ExecutionTeamLead, 'role': 'Execution Team Lead' },
    { 'name': UserRole.MaintenanceManager, 'role': 'MNT_MANAGER', 'active': false },
    { 'name': UserRole.ExecutionOwner, 'role': 'Execution Owner' },
    { 'name': UserRole.SystemManager, 'role': 'System Manager' },
    { 'name': UserRole.SystemSupport, 'role': 'System Support' },
    { 'name': UserRole.TenantAdmin, 'role': 'Tenant Admin' },
    { 'name': UserRole.ProjectMember, 'role': 'Project Member'},
    { 'name': UserRole.PolicyManager, 'role': 'Policy Manager'},
    { 'name': UserRole.ControlRoom, 'role': 'Control Room'},
  ];
  displayRoles: any = [];

  defaultPageSize = 7;
  page = 0;
  alarmPage = 0;

  isOpen = false;
  isAlarmOpen = false;
  routerSub = null;
  online$: Observable<boolean>;
  showServerOfflineError: boolean = false;
  notificationPageSize = 10;
  alarmPageSize = 7;
  isRole: boolean;
  currentRole: any;
  roleselected: any;
  errorrole: any;
  alarmList : any = [];
  private _subscriptions: Subscription[] = [];
  isControlRoomProjEnv : boolean;
  systemRole: boolean;

  constructor(
    public appSvc: ApplicationService, private router: Router, private userTypeService: UserTypeResolverService,
    private authService: AuthService,
    private mainheader: MainHeaderService,
    private dashboard: DashboardService,
    private loc: Location,
    private notificationService: NotificationService,
    private alarmService: AlarmService,
    public activeModal: NgbActiveModal, private modalService: NgbModal, config: NgbModalConfig,
    private popupService: PopUpService,private keycloak:KeycloakService
  ) {
    config.backdrop = 'static';
  }

  isMarkAllRead() {
    return !this.appSvc.notifications.some(x => !x.isRead);
  }

  isAllAcknowledged() {
    return !this.appSvc.allAlarms.some(x => !x.isAcknowledged);
  }

  changeRole(item) {
    this.modalService.dismissAll();
   this.isAlarmOpen = false;
    this.popupService.popup.next('close');
    this.selectedRole = item;
    this.openSide = false;
    this.rolePopup = false;
    this.roleType = item.name;
    this.appSvc.setEquipment("00000000-0000-0000-0000-000000000000");
    this.appSvc.setSubstation("00000000-0000-0000-0000-000000000000");
    this.appSvc.setRegionId("00000000-0000-0000-0000-000000000000");
    this.appSvc.setBayId("00000000-0000-0000-0000-000000000000");
    this.appSvc.setPlannerPlanId('00000000-0000-0000-0000-000000000000');
    this.appSvc.setPlanId("00000000-0000-0000-0000-000000000000");
    sessionStorage.setItem('currentRole', item.name);
    sessionStorage.setItem('currentRoleSelected', item.name);
    const token = JSON.parse(sessionStorage.getItem('previleges'));
    this.systemRole = token?.isSystemRole;
    this.dashboard.changeRole(this.roleType);
    this.isControlRoomProjEnv = this.appSvc.isControlRoomProjEnv() ? true : false;
    if (this.roleType === 'Admin') {
      this.hideClientProjectDetail = true;
    } else {
      this.hideClientProjectDetail = false;
    }
    if(this.roleType.includes('System Manager') || this.roleType.includes('System Support') || this.roleType.includes('Policy Manager') || this.roleType.includes('Project Member') || (this.roleType.includes('Control Room')  && this.systemRole)) {
      this.isSystemEnv = true;
    } else {
      this.isSystemEnv = false;
    }
    if (item.role === 'PLANNER') {
      this.router.navigate([RouteHelper.addProjectPrefix(`/planning/planner`)]);
    } else if (item.role === 'MNT_MANAGER') {
      this.router.navigate([RouteHelper.addProjectPrefix(`/planning/manager`)]);
    } else if (item.role === 'PM') {
      this.router.navigate([RouteHelper.addProjectPrefix(`/dashboard/reliability-center`)]);
    } else if (item.role === 'Project Support') {
      this.router.navigate([RouteHelper.addProjectPrefix(`/dashboard/reliability-center`)]);
    } else if (item.role === 'Project Manager (C)') {
      this.router.navigate([RouteHelper.addProjectPrefix(`/dashboard/reliability-center`)]);
    } else if (item.role === UserRole.PolicySME) {
      this.router.navigate([RouteHelper.addProjectPrefix(`/policy`)]);
    } else if (item.role === 'Operation SME' || item.role === 'Remote Operator') {
      this.router.navigate([RouteHelper.addProjectPrefix(`/operations`), 'view']);
    } else if (item.role === 'Quality SME') {
      // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([RouteHelper.addProjectPrefix(`/execution/landing/qualitySME`)]);
      // );
    } else if (item.role === 'Execution Team Lead') {
      // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([RouteHelper.addProjectPrefix(`/execution/landing/teamLead`)]);
      // );
    } else if (item.role === 'Execution Owner') {
      // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigateByUrl(RouteHelper.addProjectPrefix(`/execution/landing/ExecutionOwner`));
      // );
    } else if (item.role === 'System Manager' || item.role === 'System Support' || item.role === 'Project Member'
      || (item.role === 'Control Room' && !RouteHelper.isInProjectMode())) {
      this.router.navigateByUrl(`/operationscenter`);
    } else if(item.role === 'Control Room' && this.appSvc.isControlRoomProjEnv()) {
      this.router.navigate([RouteHelper.addProjectPrefix(`/dashboard/reliability-center`)]);
    }
    this.isRole = item.name === 'Project Support' ||
      item.name === 'Project Manager (C)' ? true : false;
  }

  changeVersion(item) {
    this.selectedVersion = item;
    sessionStorage.setItem('versionId', this.selectedVersion.id);
    sessionStorage.setItem('versionName', this.selectedVersion.name);
    this.openSide = false;
    this.versionPopup = false;
    if ((this.loc.path() === RouteHelper.addProjectPrefix(`/dashboard/kernel-parameters`)) || (this.loc.path().includes('edit-equipment'))) {
      this.redirectTo(RouteHelper.addProjectPrefix(`/dashboard/kernel-parameters`));
    }
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl(RouteHelper.addProjectPrefix(`/dashboard`), { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  showAlarms(): boolean {
    let currentRole = sessionStorage.getItem("currentRoleSelected");
    if (currentRole ===null || currentRole === ''){
      currentRole = sessionStorage.getItem("currentRole");
    }

    if (!currentRole)
      return false;
    let isRemoteOperator = !!["Operation SME", "Remote Operator"].find(x => x == currentRole);
    return isRemoteOperator;
  }

  ngOnInit() {            
      this.onRolesUpdated();
      this._subscriptions.push(this.mainheader.rolesUpdated$.subscribe(() => {
        this.onRolesUpdated();
      }));
  }

  ngOnChanges() {
    this.allMenu = {
      Projects : this.menuObject('Projects',`/projectdashboard`,'icon-projects-list'),
      Settings: this.menuObject( 'Settings', RouteHelper.addProjectPrefix(`/dashboard/project-settings`),'icon-setting'),
      Policies : this.menuObject('Policies', RouteHelper.addProjectPrefix(`/policy`),'icon-policy'),
      Home: this.menuObject('Home',RouteHelper.addProjectPrefix(`/dashboard/reliability-center`),'icon-menu-home'),
      Information_explorer:this.menuObject('Information explorer',RouteHelper.addProjectPrefix(`/reliability-center/information-explorer`),'icon-menu-ie'),
      Performance_monitoring:this.menuObject('Performance monitoring',RouteHelper.addProjectPrefix(`/dashboard/performance-monitoring`),'icon-info-white',),
      Knowledge_bank:this.menuObject('Knowledge bank',RouteHelper.addProjectPrefix(`/reliability-center/knowledge-bank`),'icon-menu-kb'),
      Audit_Log:this.menuObject('Audit Log',RouteHelper.addProjectPrefix('/audit-logs'),'icon-audit-log'),
      Mobile_configuration:this.menuObject('Mobile configuration',RouteHelper.addProjectPrefix(`/moBile`),'icon-smart-phone'),
      Operation_Center: this.menuObject('Operations center',`/operationscenter`,'icon-op-center'),
      Installed_Base_project:this.menuObject('Installed base',RouteHelper.addProjectPrefix(`/installedbase`),'icon-install-base'),
      Installed_Base:this.menuObject('Installed base','/installedbase','icon-install-base') 
    }          
  }

  onRolesUpdated() {
    this.appSvc.setUserLogin(true);
    this.errorrole = localStorage.getItem('errorrole');
    // sessionStorage.setItem('isUserLogin','true');
    this.login = true;
    const token = JSON.parse(sessionStorage.getItem('previleges'));
    this.systemRole = token?.isSystemRole;
    if (sessionStorage.length > 4) {
      this.roleType = sessionStorage.getItem('UserRoles');
      console.log(!this.roleType.includes('Tenant Admin'));
      if (!this.roleType.includes('Tenant Admin')) {
        this.appSvc.getCurrentBuildVersion().subscribe({next:  (resp) => {
          this.buildVersionNumberError = false;
          this.buildVersionNumber = resp.returnObj;
        }, error : (err) => {
          console.log("currentBuildVersion : Failed" + err);
          if (err != null && err.error != null && err.error.text != null) {
            this.buildVersionNumberError = true;
            this.buildVersionNumber = err.error.text;
          }
        }});
        if (this.roleType.includes('System Manager') || this.roleType.includes('System Support') || this.roleType.includes('Policy Manager') || this.roleType.includes('Project Member') ||  this.roleType.includes('Control Room') && this.systemRole) {
          this.isSystemEnv = true;
        } else {
          this.isSystemEnv = false;
        }
      }
    }
    if (sessionStorage.length > 4) {
      this.ishide = false;

      if (!this.roleType.includes('Tenant Admin')) {
        this.isRefreshToken = (sessionStorage.getItem('RefershToken') != null) ? true : false;
        this.mainheader.navItem$.subscribe({next: (res) => {
          this.appSvc.GetAllKernelVersions().subscribe(version => {
            this.versionList = version;
            this.selectedVersion = version.slice(-1).pop();
            sessionStorage.setItem('versionId', this.selectedVersion.id);
            sessionStorage.setItem('versionName', this.selectedVersion.name);
            localStorage.setItem('versionName', this.selectedVersion.name);
          });
        }, error: () => {}});
      }

      this.mainheader.selectedRole$.subscribe({next: (res) => {
        if (res === 'Execution Team Lead') {
          this.selectedRole = _.find(this.allRoles, { role: 'Execution Team Lead' });
        } else if (res==='Execution Owner'){
          this.selectedRole = _.find(this.allRoles, { role: 'Execution Owner' });
        }
      }, error: () => {}});


      if(!this.roleType.includes('Tenant Admin') && RouteHelper.isInProjectMode()) {
        this.appSvc.GetDefaultCustomerDetails().subscribe({next:  (data) => {
          if (data.returnObj) {
            this.custName = data.returnObj.custname;
            this.projName = data.returnObj.projectname;
          }
        }, error: () => {}});
      }

    if (!this.routerSub) {
      this.routerSub = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((value) => {
        if(!this.roleType.includes('Tenant Admin')) {
          this.getNotifications();
        }
        this.getAlarms();
      });
    }

    if(!this.roleType.includes('Tenant Admin')) {
      this.getNotifications();
    }
    this.getAlarms();

      this.online$ = merge(
        of(navigator.onLine),
        fromEvent(window, 'online').pipe(map(()=> true)),
        fromEvent(window, 'offline').pipe(map(()=>false))
      );
      const roleTypeArr = this.roleType?.split(',');
      //const a = typeof (this.roleType);

      this.roleType = this.roleType.includes('System Manager') ? 'System Manager' : this.roleType;
      if(!this.roleType.includes('Tenant Admin')) {
      this.roleType = this.roleType.includes('Admin') ? 'Admin' : this.roleType;
      }
      let currentRole = sessionStorage.getItem("currentRoleSelected");
    if (currentRole === null || currentRole === ''){
      sessionStorage.setItem('currentRole', this.roleType);
      if (roleTypeArr.length > 1) {
        sessionStorage.setItem('currentRole', roleTypeArr[0]);
      }
      this.isRole = roleTypeArr[0] === 'Project Support' ||
        roleTypeArr[0] === 'Project Manager (C)' ? true : false;
      } else {
        sessionStorage.setItem('currentRole', currentRole);
       if(currentRole === 'Project Support'){
        this.isRole = true;
        }
      }
      this.dashboard.changeRole(this.roleType);
      if (this.roleType === 'Admin' || this.roleType === 'Tenant Admin') {
        this.hideClientProjectDetail = true;
      } else {
        this.hideClientProjectDetail = false;
      }
      // this.appSvc.GetAllKernelVersions().subscribe(version => {
      //   this.versionList = version;
      //   this.selectedVersion = version.slice(-1).pop();
      //   sessionStorage.setItem('versionId', this.selectedVersion.id);
      //   sessionStorage.setItem('versionName', this.selectedVersion.name);
      // });
      this.appSvc.getApplicationMetadata().subscribe((resp) => this.metadataInfo = resp);
      this.userType = this.userTypeService.resolve();
      this.userType = sessionStorage.getItem('currentRoleSelected');
      if (this.userType === null || this.userType === undefined) {
        this.userType = this.appSvc.getUserPrivilages().roles;
      }
      if (this.userType === 'Planner') {
        this.selectedRole = _.find(this.allRoles, { role: 'PLANNER' });
      } else if (this.userType === 'Maintenance Manager') {
        this.selectedRole = _.find(this.allRoles, { role: 'MNT_MANAGER' });
      } else if (this.userType === 'Execution Team Lead') {
        this.selectedRole = _.find(this.allRoles, { role: 'Execution Team Lead' });
      } else if (this.userType === 'Execution Owner') {
        this.selectedRole = _.find(this.allRoles, { role: 'Execution Owner' });
      } else if (this.userType === 'Remote Operator') {
        this.selectedRole = _.find(this.allRoles, { role: 'Remote Operator' });
      } else if (this.userType === 'Operation SME') {
        this.selectedRole = _.find(this.allRoles, { role: 'Operation SME' });
      } else if (this.userType === 'System Manager') {
        this.selectedRole = _.find(this.allRoles, { role: 'System Manager' });
      } else if (this.userType === 'System Support') {
        this.selectedRole = _.find(this.allRoles, { role: 'System Support' });
      } else if (this.userType === 'Project Support') {
        this.selectedRole = _.find(this.allRoles, { role: 'Project Support' });
      } else if (this.userType === 'Project Manager (C)') {
        this.selectedRole = _.find(this.allRoles, { role: 'Project Manager (C)' });
      } else if (this.userType === 'Quality SME') {
        this.selectedRole = _.find(this.allRoles, { role: 'Quality SME' });
      } else if (this.userType === UserRole.PolicySME) {
        this.selectedRole = _.find(this.allRoles, { role: UserRole.PolicySME });
      } else if(this.userType === 'Tenant Admin') {
        this.selectedRole = _.find(this.allRoles, { role: 'Tenant Admin'});
      } else if(this.userType === 'Project Member') {
        this.selectedRole = _.find(this.allRoles, { role: 'Project Member'});
      } else if(this.userType === 'Policy Manager') {
        this.selectedRole = _.find(this.allRoles, { role: 'Policy Manager'});
      } else if(this.userType === 'Control Room') {
        this.selectedRole = _.find(this.allRoles, { role: 'Control Room'});
      } else {
        this.selectedRole = _.find(this.allRoles, { role: 'PM' });
      }

      if ((this.userType === UserRole.ProjectManager) || (this.userType === 'Execution Team Lead') || (this.userType === 'Execution Owner')) {
        // this.appSvc.getAllProjects().subscribe(res => {
        //   this.project = res.returnObj.filter(project => project.projectId === this.authService.getUser().projectId)[0];
        // });
      }

      if (this.userType === 'Execution Team Lead') {
        this.userRole = 'Execution Team Lead';
      }
      if (this.userType === 'Execution Owner') {
        this.userRole = 'Execution Owner';
      }
      this.userName = sessionStorage.getItem('UserName');
      this.userImage = sessionStorage.getItem('UserImage');
      //const getToken = sessionStorage.getItem('AccessToken');
      const token = JSON.parse(sessionStorage.getItem('previleges'));
      const roles = token?.roles;
      // const decodedToken = Jwthelper.decodeToken(getToken);
      // const roles = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

      this.filterRoles = [];

      if (Array.isArray(roles)) {
        roles.forEach(element => {
          this.allRoles.filter(role => {
            if (role.name === element) {
              this.filterRoles.push(role);
            }
          });
        });
      } else {
        this.allRoles.filter(role => {
          if (role.name === roles) {
            this.filterRoles.push(role);
          }
        });
      }

      this.displayRoles = this.filterRoles;
      //this.changeRole(this.selectedRole);
    }
    // this.appSvc.get()
    // .subscribe(res => {
    //   this.db = res;
    // });

    // this.userType = this.userTypeService.resolve();
    // if (this.userType === 'PM') {
    //   this.appSvc.getAllProjects().subscribe(res => {
    //     this.project = res.filter(project => project.projectId === this.authService.getUser().projectId)[0];
    //   });
    // }
  }

  ngOnDestroy() {
    if (this.routerSub !== null) {
      this.routerSub.unsubscribe();
    }
    if (this.appSvc.subscription != undefined) {
      this.appSvc.subscription.unsubscribe();
    }

    this._subscriptions.forEach(sub => sub.unsubscribe());
  }

  getAlarms() {
    if (sessionStorage.length > 1 && (this.selectedRole.name == 'Operation SME' || this.selectedRole.name == 'Remote Operator')) {
      sessionStorage.getItem('RefershToken')
      this.loading = true;
      this.alarmService.getAlarms().subscribe({next:  res => {
        this.loading = false;
        let temp = _.cloneDeep(res.items);
        temp = _.orderBy(temp, 'createdAt', 'desc');
        const list = _.map(temp, item => {
          const data = _.cloneDeep(item);
          let createdAtLocal = moment.utc(data.createdAt).local();
          data.createdAt = createdAtLocal.fromNow();
          return data;
        });
        this.appSvc.allAlarms = list;
        this.appSvc.alarms = list.slice(0, this.alarmPageSize - 1);
        this.alarmList = list.slice(0, this.alarmPageSize - 1);
        this.loading = false;

      }
      });
    }
  }

  getNotifications() {
    if (sessionStorage.length > 4 && !this.roleType.includes('Tenant Admin')) {
      sessionStorage.getItem('RefershToken')
      this.notificationService.getNotifications().subscribe(res => {
        let temp = _.cloneDeep(res);
        temp = _.orderBy(temp, 'createdAt', 'desc');
        const list = _.map(temp, item => {
          const data = _.cloneDeep(item);
          let createdAtLocal = moment.utc(data.createdAt).local();
          data.createdAt = createdAtLocal.fromNow();
          return data;
        });
        this.appSvc.allNotifications = list;
        this.appSvc.notifications = list.slice(0, this.defaultPageSize - 1);
        this.loading = false;

        if (this.appSvc.notificationId) {
          let notificationId = this.appSvc.notificationId;
          let notification = res.find(x => x.notificationIds.includes(notificationId));
          if (notification && notification.subType == 'Substation risks') {
            for (let task of notification.substationIds)
              task.isRead = false;
            this.appSvc.notificationId = null;
          }
          if (notification && notification.subType == 'Asset risks') {
            for (let task of notification.assetIds)
              task.isRead = false;
            this.appSvc.notificationId = null;
          }
        }
      });

      // this.appSvc.subscription = Observable.interval(60000).subscribe(
      //   val => {
      //     this.RefershTokenCalled = sessionStorage.getItem('RefershTokenCalled');
      //     if ((sessionStorage.getItem('isUserLogin') == "true" && !(this.RefershTokenCalled == "true"))) {
      //       this.displayNotificationMessage();
      //     }
      //   }
      // );
    }
  }

  displayNotificationMessage() {
    /// commenting this call until the system goes live.
    this.notificationService.getSystemNotifications().subscribe({
      next:  (value) => {
        this.showServerOfflineError = false;
      },
      error: (err) => {
        if (err.status === 0) {
          this.showServerOfflineError = true;
        }
      }}
    );
  }
  logOut() {
    this.modalService.open(LogoutConfirmationModalComponent, { centered: true }).result.then(ret => {
      if (ret === 'Logout') {
        this.appSvc.logOut().subscribe({next:
          (res) => {
            this.loading = false;
            sessionStorage.setItem('isUserLogin', 'false');
            this.modalService.dismissAll();
            sessionStorage.clear();
            //localStorage.clear();
            this.appSvc.setUserLogin(false);
            this.login = false;
            if (this.appSvc.subscription != undefined) {
              this.appSvc.subscription.unsubscribe();
            }
            this.showDropdown = true;
            this.appSvc.setPlanId('00000000-0000-0000-0000-000000000000');
            this.appSvc.setPlannerPlanId('00000000-0000-0000-0000-000000000000');
            this.keycloak.logout(window.location.origin+'/login').then(() => this.keycloak.clearToken());
          }, error: () => {
            this.loading = false;
          }});
        // this.appSvc.logOut().subscribe(
        //   (res) => {
        //     this.loading = false;
        //     this.modalService.dismissAll();
        //     sessionStorage.clear();
        //     //localStorage.clear();
        //     this.router.navigate(['/']);
        //     this.modalService.dismissAll();
        //   }, err => {
        //     this.loading = false;
        //   });
      } else {
        return;
      }
    }, () => {
    });
  }

  ngDoCheck() {
    this.appSvc.notifications = this.appSvc.allNotifications.slice(0, this.page * this.notificationPageSize - 1 + this.defaultPageSize);
    this.appSvc.alarms = this.appSvc.allAlarms.slice(0, this.alarmPage * this.alarmPageSize - 1 + this.defaultPageSize);
  }

  goTo(item) {
    if (item.name === 'Mobile configuration') {
      item.active = true;
      this.openQR();
      return;
    }
    this.popupService.popup.next('close');
    this.currentRole = sessionStorage.getItem('currentRoleSelected');
    if (this.currentRole === null) {
      this.currentRole = sessionStorage.getItem('currentRole');
    }
    this.appSvc.setEquipment("00000000-0000-0000-0000-000000000000");
    this.appSvc.setSubstation("00000000-0000-0000-0000-000000000000");
    this.appSvc.setRegionId("00000000-0000-0000-0000-000000000000");
    this.appSvc.setBayId("00000000-0000-0000-0000-000000000000");
    this.appSvc.setPlanId("00000000-0000-0000-0000-000000000000");
    if (item.url !== '') {
      this.openSide = false;
      if (item.name === 'Home' && this.currentRole === UserRole.PolicySME) {
        this.router.navigate([RouteHelper.addProjectPrefix(`/policy`)]);
      } else if (item.name === 'Home' && this.currentRole === 'Quality SME') {
        this.router.navigate([RouteHelper.addProjectPrefix(`/execution/landing/qualitySME`)]);
      } else if (item.name === 'Home' && this.currentRole === 'Execution Team Lead') {
        this.router.navigate([RouteHelper.addProjectPrefix(`/execution/landing/teamLead`)]);
      } else if (item.name === 'Home' && this.currentRole === 'Execution Owner') {
        this.router.navigate([RouteHelper.addProjectPrefix(`/execution/landing/ExecutionOwner`)]);
      } else if (item.name === 'Home' && this.currentRole === 'Planner') {
        this.router.navigate([RouteHelper.addProjectPrefix(`/planning/planner`)]);
      } else if (item.name === 'Home' && this.currentRole === 'Maintenance Manager') {
        this.router.navigate([RouteHelper.addProjectPrefix(`/planning/manager`)]);
      } else if (item.name === 'Home' && this.currentRole === 'Operation SME') {
        this.router.navigate([RouteHelper.addProjectPrefix(`/operations/view`)]);
      } else if (item.name === 'Home' && this.currentRole === 'Remote Operator') {
        this.router.navigate([RouteHelper.addProjectPrefix(`/operations/view`)]);
      } else {
        this.router.navigate([item.url]);
      }
    }
  }

  openSlideMenu() {
    this.openSide = !this.openSide;
    let roleTypeArr = sessionStorage.getItem("currentRoleSelected") || sessionStorage.getItem("currentRole");
    const userRolesObject = this.appSvc.getUserPrivilages();
    const token = JSON.parse(sessionStorage.getItem('previleges'));
    this.systemRole = token?.isSystemRole;
    this.allMenu = {
      Projects : this.menuObject('Projects',`/projectdashboard`,'icon-projects-list'),
      Settings: this.menuObject( 'Settings', RouteHelper.addProjectPrefix(`/dashboard/project-settings`),'icon-setting'),
      Policies : this.menuObject('Policies', RouteHelper.addProjectPrefix(`/policy`),'icon-policy'),
      Home: this.menuObject('Home',RouteHelper.addProjectPrefix(`/dashboard/reliability-center`),'icon-menu-home'),
      Information_explorer:this.menuObject('Information explorer',RouteHelper.addProjectPrefix(`/reliability-center/information-explorer`),'icon-menu-ie'),
      Performance_monitoring:this.menuObject('Performance monitoring',RouteHelper.addProjectPrefix(`/dashboard/performance-monitoring`),'icon-info-white',),
      Knowledge_bank:this.menuObject('Knowledge bank',RouteHelper.addProjectPrefix(`/reliability-center/knowledge-bank`),'icon-menu-kb'),
      Audit_Log:this.menuObject('Audit Log',RouteHelper.addProjectPrefix('/audit-logs'),'icon-audit-log'),
      Mobile_configuration:this.menuObject('Mobile configuration',RouteHelper.addProjectPrefix(`/moBile`),'icon-smart-phone'),
      Operation_Center: this.menuObject('Operations center',`/operationscenter`,'icon-op-center'),
      Installed_Base_project:this.menuObject('Installed base',RouteHelper.addProjectPrefix(`/installedbase`),'icon-install-base'),
      Installed_Base:this.menuObject('Installed base','/installedbase','icon-install-base') 
    }          
    // if (this.router.url.indexOf('/dashboard/reliability-center') !== -1 ||
    //   this.router.url.indexOf('dashboard/performance-monitoring') !== -1 ) {
    if (roleTypeArr === 'System Manager' || roleTypeArr === 'Admin'
        || roleTypeArr === 'System Support' || (roleTypeArr === 'Control Room' && !this.appSvc.isControlRoomProjEnv())) {
      this.topMenu = [
        this.allMenu['Operation_Center'],
        this.allMenu['Projects'],
        this.allMenu['Installed_Base'],
       this.allMenu['Settings']
    ];
     this.bottomMenu = [];
    } else if(roleTypeArr === 'Policy Manager'){
      this.topMenu = [
        this.allMenu['Operation_Center'],
        this.allMenu['Projects'],
        this.allMenu['Installed_Base'],
        this.allMenu['Policies']
     ];
      this.bottomMenu = [];
    } else if(roleTypeArr === 'Project Member'){
      this.topMenu = [
        this.allMenu['Operation_Center'],
        this.allMenu['Projects'],
        this.allMenu['Installed_Base']
      ];
      this.bottomMenu = [];
    }  else if (roleTypeArr === 'Project Support' || roleTypeArr === 'Project Manager (C)' ||( roleTypeArr === 'Control Room' && this.appSvc.isControlRoomProjEnv())) {
      this.topMenu = [
        this.allMenu['Home'],
        this.allMenu['Information_explorer'],
        this.allMenu['Performance_monitoring'],
        this.allMenu['Knowledge_bank'],
        this.allMenu['Installed_Base_project']
      ];
      this.bottomMenu = [];
    } else if(userRolesObject.roles === 'Project Manager') {
      this.topMenu = [
        this.allMenu['Home'],
        this.allMenu['Information_explorer'],
        this.allMenu['Performance_monitoring'],
        this.allMenu['Knowledge_bank'],
        this.allMenu['Audit_Log'],
        this.allMenu['Installed_Base_project']
      ];
      this.bottomMenu = [];

    }
    else if(this.selectedRole.name === 'Execution Team Lead' || this.selectedRole.name === 'Execution Owner'){
      this.topMenu = [
        this.allMenu['Home'],
        this.allMenu['Information_explorer'],
        this.allMenu['Knowledge_bank'],
        this.allMenu['Mobile_configuration'],
        this.allMenu['Installed_Base_project']
      ];
      this.bottomMenu = [];
    }
    else {
      this.topMenu = [
        this.allMenu['Home'],
        this.allMenu['Information_explorer'],
        this.allMenu['Knowledge_bank'], 
        this.allMenu['Installed_Base_project']
      ];
      this.bottomMenu = [];
    }
    // Removes Installed base menu option for external projects
    RouteHelper.isExternalProject()? this.topMenu.splice(this.topMenu.length-1): this.topMenu;
  }
  
  menuObject(name, url, icon) {
    let menuitem = { name, url, icon}; 
    menuitem['active'] = this.router.isActive(url, true); 
    if (name === 'Mobile configuration') {
      menuitem['active'] = false;
    }
    return menuitem;
  }

  openQR(){
    if(this.modalRef !=null){
      this.modalRef.dismiss();
    }
    this.modalRef = this.modalService.open(QRScanPopupComponent, { centered: true ,size: 'lg'});
    this.modalRef.componentInstance.title = 'QR code scan';
    this.modalRef.componentInstance.message = 'QR Code ';

  }

  clickedInside($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.role.nativeElement.className = 'role-switch';
    // this.version.nativeElement.className = 'version-switch';
    if (this.roleType === 'System Manager') {
      this.version.nativeElement.className = 'version-switch';
    }
  }

  @HostListener('document:click', ['$event'])
  clickedOutside($event) {
    if (this.rolePopup) {
      this.highlightNotification = null;
    }
    this.rolePopup = false;
    this.versionPopup = false;
    // check if clicked is HTML element
    if ($event.target instanceof Element || $event.target instanceof HTMLDocument) {
      if (!$event.target.className.includes('bell') &&
        !($event.target.offsetParent && $event.target.offsetParent.className.includes('notification'))) {
        this.isOpen = false;
        this.page = 0;
      }
      if (!$event.target.className.includes('exclamation') &&
        !($event.target.offsetParent && $event.target.offsetParent.className.includes('alarm'))) {
        this.isAlarmOpen = false;
        this.alarmPage = 0;
      }
      if (this.openSide) {
        if (!this.navbar.nativeElement.contains($event.target) && !this.hamburger.nativeElement.contains($event.target)) {
          this.openSlideMenu();
        }
      }
      // if d3 created element close menu
    } else {
      this.isOpen = false;
      this.page = 0;

      this.isAlarmOpen = false;
      this.alarmPage = 0;

    }
    if ($event.target.innerHTML === 'Settings') {
      this.isOpen = false;
      this.page = 0;

      this.isAlarmOpen = false;
      this.alarmPage = 0;
    }
  }

  clickedAlarm($event: any) {
    sessionStorage.setItem('substationId', $event.substationId);
    this.router.navigateByUrl(RouteHelper.addProjectPrefix(`/operations/create/${$event.substationName}?substationId=${$event.substationId}&assetId=${$event.assetId}`));

    this.isAlarmOpen = false;
  }

  acknowledgedAlarm($event: any) {
    if ($event.alarm.isActive) return;

    /*
    var i1 = this.appSvc.allAlarms.indexOf($event.alarm);
    var i2 = this.appSvc.alarms.indexOf($event.alarm);

    if (i1 > -1)
      this.appSvc.allAlarms.splice(i1,1);
    */
    this.getAlarms();
  }

  clickNotification($event: any) {
    if ($event && $event.notification.type.toLowerCase() === 'account' && $event.isRead) {
      if ($event.notification.subtype.toLowerCase() === 'add user to a role') {
        this.role.nativeElement.className = 'role-switch highlight shake';
        this.highlightNotification = _.cloneDeep($event.notification);
      }
      else if ($event.notification.subtype.toLowerCase() === 'add usr to team' || $event.notification.subtype.toLowerCase() === 'remove usr from team') {
        let ExecutionOwnerRole = _.find(this.allRoles, { role: 'Execution owner' });
        if (ExecutionOwnerRole)
          this.changeRole(ExecutionOwnerRole);
        else {
          let etlRole = _.find(this.allRoles, { role: 'Execution Team Lead' });
          if (etlRole)
            this.changeRole(etlRole);
          // else do nothing - a user was added to a team but they are neither a ExecutionOwner nor an ETL
        }
      }
    }

    // if (this.isMarkAllRead && !$event.isRead) {
    //   this.isMarkAllRead = false;
    // }

    if ($event.notification.type.toLowerCase() === 'performance'
      || $event.notification.category && $event.notification.category.toLowerCase() === 'planned') {
      this.isOpen = false;
      this.page = 0;
    }

    if ($event.notification.type.toLowerCase() === 'substation') {
      let operationSmeRole = _.find(this.allRoles, { role: 'Operation SME' });
      this.changeRole(operationSmeRole);
      sessionStorage.setItem('substationId', $event.notification.substationIds[0].id);
      this.router.navigateByUrl(RouteHelper.addProjectPrefix(`/operations/create/${$event.notification.detail.match(/(?<=ubstation:\s*).*(?=\.)/)[0]}?substationId=${$event.notification.substationIds[0].id}`));
    }
  }

  checkHighlightRole(role) {
    if (this.highlightNotification) {
      return _.includes(this.highlightNotification.detail, role.name);
    } else {
      const result = _.filter(this.appSvc.allNotifications, notification => {
        return (notification.type === 'account' && _.includes(notification.detail, role.name));
      });
      return result && result.length > 0;
    }
  }

  clickMarkAllRead() {
    this.loading = true;
    this.notificationService.markAllNotificationsRead()
      .pipe(finalize(() => this.loading = false))
      .subscribe(() => {
        for (let not of this.appSvc.notifications) {
          not.isRead = true;
        }
      });
  }

  clickLoadMore(e) {
    e.stopPropagation();
    if (this.appSvc.notifications.length < this.appSvc.allNotifications.length) {
      this.page += 1;
    }
  }

  clickLoadMoreAlarms(e) {
    e.stopPropagation();
    if (this.appSvc.alarms.length < this.appSvc.allAlarms.length) {
      this.alarmPage += 1;
    }
  }

  clickBell() {
    this.isOpen = true;
  }

  clickAlarmIcon() {
    this.isAlarmOpen = true;
  }

  clickSettings() {
    const modalRef = this.modalService.open(NotificationSettingsComponent, { centered: true });
    modalRef.componentInstance.title = 'Notification Settings';
    modalRef.result.then(ret => {
    }, () => {
    });
  }

  clickAlarmSettings() {
    this.modalService.open(AlarmNotificationSettingsComponent, { centered: true, windowClass: "xl-modal" })
      .result.then((result) => {

      }, (reason) => {

      });
  }


  // ngAfterViewInit() {
  // setTimeout(() => {
  //   this.systemWarn.nativeElement.className = 'warn hide';
  //   this.systemDetail.nativeElement.className = 'detail animated fadeIn';
  // }, environment.systemNotificationShowDuration / 3);

  // setTimeout(() => {
  //   this.systemNotifications.nativeElement.className = 'system-notifications hide';
  // }, environment.systemNotificationShowDuration);
  // }

  clickSystemNotifcaionts() {
    const modalRef = this.modalService.open(SystemNotificationsComponent, { centered: true });
    modalRef.componentInstance.title = 'Connection errors to SCADA';
    modalRef.result.then(ret => {
    }, () => {
    });
  }
  getClass(){
    if (this.alarmList.find(x => x.severity == 'High'))
      return 'high';

    if (this.alarmList.find(x => x.severity == 'Medium'))
      return 'medium';

    if (this.alarmList.find(x => x.severity == 'Low'))
      return 'low';
  }
}
