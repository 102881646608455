import settings from '../../../assets/settings.json';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ApplicationService } from '../../../services/application.service';
import { AuthService } from '../../../services/auth.service';
import { EncrDecrService } from '../../../services/EncrDecrService';
import { JwtHelperService } from '@auth0/angular-jwt';
import { KeycloakService } from 'keycloak-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from 'src/services/settings.service';
import { interval, map, mergeMap, of, tap } from 'rxjs';
import jwt_decode from 'jwt-decode';
import * as RouteHelper from 'src/app/utility/routing.helper';



const Jwthelper = new JwtHelperService();

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public state: any = {
    form: {

    }
  };
  loginErrorMessage: string;
  passwordError = false;
  isView = true;
  showEmailError = false;
  showError;
  emailSentMessage;
  order: any;
  modalRef;
  loading = false;
  userRoles;
  captchaToken;
  capchaError;
  CaptchanotVerified;
  resetCaptcha = false;
  showCaptcha = false;
  enablecaptch = false;
  RefreshTokenInterval: number = 270;
  constructor(private authService: AuthService,
    private encrDecrService: EncrDecrService,
    private router: Router, private modalService: NgbModal, private appSvc: ApplicationService,
    private route: ActivatedRoute, private mySettingsService: SettingsService, private keycloak: KeycloakService) {
    this.enablecaptch = this.mySettingsService.settings.enablecaptch;

    this.route.queryParams.subscribe(params => {
      this.order = params;
      console.log(this.order);
  });
  }
  getDecodedAccessToken(token: string): any {
    try {
        return jwt_decode(token);
    }  catch (Error) {
        return null;
    }
  }
  ngOnInit() {
    if (!RouteHelper.isExternalProject()) {
      sessionStorage.setItem('projectId', '00000000-0000-0000-0000-000000000000');
    }
    sessionStorage.removeItem('projectName');
    this.keycloak.getToken().then(token => {
    sessionStorage.setItem('AccessToken', 'Bearer ' + token);
    const tokenInfo = this.getDecodedAccessToken(token);
    this.loginKeycloak(tokenInfo, token);
    });

    this.showCaptcha = this.enablecaptch === true ? true : false;
    this.state.loginFlow = 'normal';
  }
  loginRefresh(){
    this.keycloak.updateToken().then(a =>{
      this.keycloak.getToken().then(token => {
      sessionStorage.setItem('AccessToken', 'Bearer ' + token);
      });
    });

  }
  getTokenExpireTime(token){
    const tokenInfo = this.getDecodedAccessToken(token);
    var timeLocal = new Date().getTime();
    let timeSkew = Math.floor(timeLocal / 1000) - tokenInfo.iat;
    var expiresIn = (tokenInfo['exp'] - (new Date().getTime() / 1000) + timeSkew) * 1000;
    this.RefreshTokenInterval = Math.round(expiresIn / 1000) - 30;
    interval(this.RefreshTokenInterval * 1000).subscribe((func => {
      this.loginRefresh();
      }))
  }
  loginKeycloak(info, token) {
    if(this.order.order == 'popular'){
      sessionStorage.setItem('projectId',this.order.projectId);
    }
    this.loading = true; // show the spinner
    this.appSvc.loginUserKeycloak()
      .pipe(mergeMap(res => {
        if (res.isExternalProject) {
          return this.appSvc.GetDefaultProjectId().pipe(tap(projResp => {
            if (projResp) {
              sessionStorage.setItem('projectId', projResp.item2.projectid);
              sessionStorage.setItem('diagramSymbols', projResp.item2.projdiagsymbolname.toLowerCase());
            }
          })).pipe(map(() => res));
        } else {
          return of(res);
        }
      }))
      .subscribe((res) => {
        sessionStorage.setItem('UserRoles', res.roles);
        sessionStorage.setItem('previleges', JSON.stringify(res));
        this.loading = false;
        sessionStorage.setItem('currentRole', res.roles[0]);
        sessionStorage.setItem('isUserLogin', 'true');
        sessionStorage.setItem('UserId', res.userId);
        sessionStorage.setItem('Email', info.email);
        sessionStorage.setItem('UserName', res.userfullName);
        sessionStorage.setItem('UserImage', res.userPhoto);
        sessionStorage.setItem('isDeputyTeamLead', res.isDeputyTeamLead);
        sessionStorage.setItem('isExternalProject', res.isExternalProject || false);
        const userRolesObject = this.appSvc.getUserPrivilages();
        let roleType = sessionStorage.getItem('UserRoles');
        if (roleType.includes('Tenant Admin')) {
          if (userRolesObject.roles === 'Tenant Admin') {
            this.router.navigateByUrl('/tenantAdmin');
          }
        } else if (this.order.order === 'popular' && sessionStorage.length > 1 && (res.roles.includes('Operation SME')
            || res.roles.includes('Remote Operator' ))) {
          sessionStorage.setItem('substationId', this.order.subId);
          sessionStorage.setItem('isFromLogin', 'false');
          if(this.order.projectName){
          const projectName = this.order.projectName;
          sessionStorage.setItem('projectName', this.order.projectName?.replace(/\s/g, "").toLowerCase());
          }
          let sub = localStorage.getItem('subNameOPC');
          this.appSvc.GetDefaultProjectId().subscribe((resp => {
            if (resp) {
              sessionStorage.setItem('diagramSymbols', resp.item2.projdiagsymbolname.toLowerCase());
            }
            this.router.navigate([RouteHelper.addProjectPrefix(`/operations`), 'scenario-execution', sub, this.order.req,]);
          }));
        } else {
        if (userRolesObject.MemberAndPriviledge &&
          userRolesObject.roles.toLowerCase() === 'admin') {
          this.router.navigateByUrl('/dashboard/members-privileges');

        } else if (res.isExternalProject) {
          let projectRoles = res.roles;
          sessionStorage.setItem('currentRole', projectRoles[0]);
          sessionStorage.setItem('currentRoleSelected', projectRoles[0]);
          if (projectRoles[0] === 'Quality SME') {
            this.router.navigateByUrl(`/execution/landing/qualitySME`);
          } else if(projectRoles[0] === 'Execution Team Lead' || projectRoles[0] === 'Execution Lead') {
            this.router.navigateByUrl(`/execution/landing/teamLead`);
          } else if(projectRoles[0] === 'Execution Owner') {
            this.router.navigateByUrl(`/execution/landing/ExecutionOwner`);
          } else if(projectRoles[0] === 'Project Manager' || projectRoles[0] === 'Project Support' || projectRoles[0] === 'Project Manager (C)') {
            this.router.navigateByUrl(`/dashboard/reliability-center`);
          } else if(projectRoles[0] === 'Policy SME') {
            this.router.navigateByUrl(`/policy`);
          } else if(projectRoles[0] === 'Maintenance Manager') {
            this.router.navigateByUrl(`/planning/manager`);
          } else if(projectRoles[0] === 'Operation SME' || projectRoles[0] === 'Remote Operator') {
            this.router.navigateByUrl(`/operations/view`);
          } else if(projectRoles[0] === 'Planner') {
            this.router.navigateByUrl(`/planning/planner`);
          } else {
            this.router.navigateByUrl('/dashboard');
          }

        } else {
          this.router.navigateByUrl('/operationscenter');
        }
    }
      }, (error) => {
        this.loading = false;
      });
  }
}
