import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstService {

  constructor() { }

  public planningTypeIds = {
    outageRelated: '1d792401-6d7c-4340-9beb-44f3e9daef6b',
  }

  public dateFormat = "yyyy-MMM-dd";
  // public dateFormatRegx = /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/;
  public dateFormatRegx = /(19|20)\d\d[- /.](0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])/;

  public emptyGuid = '00000000-0000-0000-0000-000000000000';

  public responsibilities = {
    taskOwner: 'Task owner',
    taskValidator: 'Task validator',
    taskPreparator: 'Task preparator',
  }

  public prepareCiTypes = {
    save: 1,
    plan: 2,
    reject: 3
  }

  public ConstNames = {
    EMPTY_LABEL_FOR_DATA: "NO_DATA"
  }

  public colors = ['#f291f2', '#cf57cf', '#8f158f', '#4a004a', '#7acfc0', '#339686', '#185c50', '#082b25', '#98d694',
    '#469e3f', '#1e5e19', '#032e00', '#d4c717', '#948a00', '#575100', '#2a2700', '#f2ac66', '#c27223', '#733900',
    '#331b03', '#ff9f8c', '#e85335', '#a31b00', '#4d0d00', '#9ebff7', '#4c85ff', '#2a35ff', '#160c9c', '#9f9f9f',
    '#686868', '#3d3d3d', '#101010'];

  public year = 'Year';
  public currentYear = '9999';
  public minYear = 1900;

  public widgetCurrentYearValue = 9999;
  public RecurrenceTimeframedId = 'd717e631-2315-4bc2-beae-4b24ea352aed';
  public markerType = 'SubStation';
  public substationClassId = 'fd5d27ff-244c-48c7-9fd4-7381143a2b73';

  public installationMasterDataForSystem = ['AssetCount', 'Project', 'SubstationOperator', 'SubstationOwner', 'Technology'];
  public installationMasterDataForProject = ['SubstationOwner', 'SubstationOperator', 'Country', 'Installation', 'Technology', 'AssetCount', 'Location']

  public initialMatrix = {"riskDefinitionId":"4772ef35-c276-4177-a717-0221aab79a83","flexible":{"absolute":{},"flexible":[
    ["medium,medium","medium,medium","medium,medium","medium,high","high,high","high,high","high,high","high,high","high,high","high,high"],
    ["medium,medium","medium,medium","medium,medium","medium,medium","medium,high","high,high","high,high","high,high","high,high","high,high"],
    ["medium,medium","medium,medium","medium,medium","medium,medium","medium,medium","medium,high","high,high","high,high","high,high","high,high"],
    ["low,medium","medium,medium","medium,medium","medium,medium","medium,medium","medium,medium","medium,high","high,high","high,high","high,high"],
    ["low,low","low,medium","medium,medium","medium,medium","medium,medium","medium,medium","medium,medium","medium,high","high,high","high,high"],
    ["low,low","low,low","low,medium","medium,medium","medium,medium","medium,medium","medium,medium","medium,medium","medium,high","high,high"],
    ["low,low","low,low","low,low","low,medium","medium,medium","medium,medium","medium,medium","medium,medium","medium,medium","medium,high"],
    ["low,low","low,low","low,low","low,low","low,medium","medium,medium","medium,medium","medium,medium","medium,medium","medium,medium"],
    ["low,low","low,low","low,low","low,low","low,low","low,medium","medium,medium","medium,medium","medium,medium","medium,medium"],
    ["low,low","low,low","low,low","low,low","low,low","low,low","low,medium","medium,medium","medium,medium","medium,medium"]]},"isAbsoluteActive":false};
    
}
