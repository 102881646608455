import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import { Interaction, NodeType } from './constant.enum';

export class Map {

  static possibleConnection(originNode: NodeType, destinationNode: NodeType) {
    if ((originNode === NodeType.SubStation && destinationNode === NodeType.Source) || (originNode === NodeType.Load) 
          || (destinationNode === NodeType.Source) || (destinationNode === NodeType.Load && originNode === NodeType.Source)) {
      return false;
    }
    return true;
  }

  static getMiddleCoordinate(startCoordinate, endCoordinate) {
    const x = endCoordinate[1] - startCoordinate[1];
    const y = endCoordinate[0] - startCoordinate[0];
    const degrees = 180 + Math.atan2(y, x) * (180 / Math.PI);
    if (degrees < 45) {
      return [startCoordinate[0], endCoordinate[1] + (startCoordinate[0] - endCoordinate[0])];
    } else if (degrees === 45) {
      return [];
    } else if (degrees > 45 && degrees < 90) {
      return [endCoordinate[0] + (startCoordinate[1] - endCoordinate[1]), startCoordinate[1]];
    } else if (degrees === 90) {
      return [];
    } else if (degrees > 90 && degrees < 135) {
      return [endCoordinate[0] - (startCoordinate[1] - endCoordinate[1]), startCoordinate[1]];
    } else if (degrees === 135) {
      return [];
    } else if (degrees > 135 && degrees < 180) {
      return [startCoordinate[0], endCoordinate[1] - (startCoordinate[0] - endCoordinate[0])];
    } else if (degrees === 180) {
      return [];
    } else if (degrees > 180 && degrees < 225) {
      return [startCoordinate[0], endCoordinate[1] + (startCoordinate[0] - endCoordinate[0])];
    } else if (degrees === 225) {
      return [];
    } else if (degrees > 225 && degrees < 270) {
      return [endCoordinate[0] + (startCoordinate[1] - endCoordinate[1]), startCoordinate[1]];
    } else if (degrees === 270) {
      return [];
    } else if (degrees > 270 && degrees < 315) {
      return [endCoordinate[0] - (startCoordinate[1] - endCoordinate[1]), startCoordinate[1]];
    } else if (degrees === 315) {
      return [];
    } else if (degrees > 315 && degrees < 360) {
      return [startCoordinate[0], endCoordinate[1] - (startCoordinate[0] - endCoordinate[0])];
    } else if (degrees === 260 || degrees === 0) {
      return [];
    }
    return [];
  }


  static getNodeIcon(nodeType: NodeType, interaction: Interaction, risk, text = '',Substationname='',ProjectName='') {
    let size = null;
    let image = null;
    if (nodeType === NodeType.Joint) {
      image = 'joint';
      size = [62, 62];
    } else if (nodeType === NodeType.SubStation) {
      image = 'marker';
      if (risk) {
        image += '-' + risk;
      }
      size = [56, 56];
    } else if (nodeType === NodeType.Source) {
      image = 'source';
      size = [56, 56];
    } else if (nodeType === NodeType.Load) {
      image = 'load';
      size = [56, 56];
    }
    if (interaction === Interaction.Hover) {
      image += '-hover';
      if (nodeType === NodeType.SubStation) {
        size = [72, 72];
      }
      if(Substationname){
        return new Style({
          image: new Icon(({
            crossOrigin: 'anonymous',
            src: `/assets/i/${image}.svg`,
            imgSize: size
          })),
          text: new Text({
            backgroundFill:new Fill({
              color: 'rgba(255,255,255, 0.8)'
            }),
            text: 'Installation: ' + Substationname + (ProjectName!=='' ? ('\n' + 'Project: ' + ProjectName): ''),
            fill: new Fill({ color: 'black' }),
            textAlign: 'left',
            offsetX: -70,
            offsetY: -45,
            placement: "point",
            padding:[5, 8, 5, 8],
            font:'13px OpenSans'
          })
        });
      }
    }
    if (interaction === Interaction.Active) {
      image += '-active';
      size = [60, 75];
    }
    return new Style({
      image: new Icon(({
        crossOrigin: 'anonymous',
        src: `/assets/i/${image}.svg`,
        imgSize: size
      })),
      text: new Text({
        text: (text == null ? '': text) + '',
        fill: new Fill({ color: 'white' }),
        offsetX: -4,
        offsetY: -4
      })
    });
  }
}
