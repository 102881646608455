import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map, merge } from 'rxjs';

@Component({
  selector: 'app-add-new-tenant',
  templateUrl: './add-new-tenant.component.html',
  styleUrls: ['./add-new-tenant.component.scss']
})
export class AddNewTenantComponent implements OnInit {
  submitted = false;
  isValid;
  loading = false;
  isInputTouched = false;
  formValue;
  isValueChanged = false;
  form: FormGroup;
  isNext = true;
  @Input() data = {};
  @Input() title;
  @ViewChild('input', { static: false }) vc: ElementRef;
  unChangedValues: any;
  isClientIdandSecret = false;
  isDBconnectionString = false;
  isAzureStorageConnectionString = false;

  
  constructor(private formBuilder: FormBuilder,    public activeModal: NgbActiveModal,
    ) { }

  ngOnInit(): void {
   this.setGroupData(this.data);
    let values: any = [];
      let uniq: any = [];
      const exclude: string[] = [''];
      const formControlNames = ['dBconnectionString', 'azureStorageConnectionString', 'clientIdandSecret'];
      const keys = [];
      Object.keys(this.form.controls).forEach(key => {
        if (exclude.findIndex(q => q === key) === -1) {
          keys.push(this.form.get(key).valueChanges.pipe(
            map(val => (key))
          ));
        }
      });
      const valueWatcherSubscription = merge(...keys);
      valueWatcherSubscription.subscribe((val) => {   
        values.push(val);
        uniq = [...new Set(values)];
        this.unChangedValues = formControlNames.filter(function(e) { return uniq.indexOf(e) > -1? false : true });
      }
    );
  }

  validation(name, type = 'required'): any {
    if(this.title === 'Edit tenant' && name !== 'tenantName' && name !== 'secretKey') {
      return false;
    } 
      this.isValid = this.submitted && this.form.controls[name] && this.form.controls[name].errors && this.form.controls[name].errors[type];
      return this.isValid;
  }
 
  setGroupData(data) {
  this.form = this.formBuilder.group({
    tenantName: [data.tenantName || '', Validators.required],
    dBconnectionString:['', this.title !== 'Edit tenant'? Validators.required : null],
    azureStorageConnectionString:['', this.title !== 'Edit tenant'? Validators.required : null],
    clientIdandSecret:['', this.title !== 'Edit tenant'? Validators.required : null],
    isSystemEnvironment:[data.isSystemEnvironment || false ],
    secretKey: ['', [Validators.required, Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{10,}$")]]
  });
  }
  
  next() {
    this.isNext = false;
  }

  previous() {
    this.isNext = true;
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    const value = this.form.value;
        this.activeModal.close({
          type: 'save', data: value, unchangedValue: this.unChangedValues});
    }
    
//Alpha-numeric only
alphaNumericOnly(event): boolean {
  let events = (event) ? event : window.event;
  var charCode = (events.which) ? events.which : events.keyCode;
  if (charCode > 96 && charCode < 123 || (charCode > 47 && charCode < 58)) {
    return true;
  } else if(charCode > 64 && charCode < 91) {
    return true;
  }
  return false;
}

focusOrBlur(event) {
  if(event.type === 'focus') {
    return false;
  } else if(event.type === 'blur' && event.target.value === "") {
    return true;
  } else {
    return false;
  }
}
isInputFieldTouched(event, fieldName) {
  switch(fieldName) {
    case 'clientIdandSecret':
      this.isClientIdandSecret = this.focusOrBlur(event) ? false : true;
      break;
    case 'dBconnectionString':
      this.isDBconnectionString = this.focusOrBlur(event) ? false : true;
      break;
    case 'azureStorageConnectionString':
      this.isAzureStorageConnectionString = this.focusOrBlur(event) ? false : true;
      break;
  }
}
changeInput(event, fieldName) {
  this.isValueChanged = true;
  this.form.controls[fieldName].setValue(event.target.value);
}
}
