<div class="login-page">
  <div class="login-container">
    <div class="cols">
      <div class="col">
        <div class="fieldset">
          <div class="logo">RelCare™</div>
        </div>
      </div>
      <div class="col">
        <div class="fieldset" *ngIf='success'>
          <h5>{{activationMessage}}</h5>

        </div>
      </div>
    </div>
  </div>
</div>
