import { Component, OnInit } from '@angular/core';
import { SldUtilService } from '../../containers/sld-editor/services/util.service'
import { HelperService } from '../../containers/sld-editor/services/helper.service';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { FileSaverService } from 'ngx-filesaver';
import { ApplicationService } from '../../../services/application.service';

@Component({
  selector: 'app-export-substation-data',
  templateUrl: './export-substation-data.component.html',
  styleUrls: ['./export-substation-data.component.scss'],
  providers: [SldUtilService, HelperService]
})
export class ExportSubstationDataComponent implements OnInit {
  loading = false;
  filterColumns = []
  title;
  showDownload: boolean = false;
  pageType: string;
  isExport: any;
  substationId: string;
  constructor(private utilService: SldUtilService, private route: ActivatedRoute, 
    private appSvc: ApplicationService, private _FileSaverService: FileSaverService) { }

  ngOnInit() {
    this.isExport = this.route.snapshot.queryParams['isExport'];
    this.pageType = this.route.snapshot.queryParams['type'].toLowerCase();
    this.substationId = this.route.snapshot.queryParams['substationId'];
    console.log("initialise app");
    if ((this.isExport == true || this.isExport.toLowerCase() == 'true') && this.pageType == 'sldcomponent') {
      this.title = 'Export Substation Data';
      this.showDownload = true;
    } else if ((this.isExport == false || this.isExport.toLowerCase() == 'false') && this.pageType == 'sldcomponent') {
      this.title = 'Import Substation Data'
    }
    else if ((this.isExport == true || this.isExport.toLowerCase() == 'true') && this.pageType == 'historicaldata') {
      this.title = 'Export Historical Data';
      this.showDownload = true;
    }
    else if ((this.isExport == false || this.isExport.toLowerCase() == 'false') && this.pageType == 'historicaldata') {
      this.title = 'Import Historical Data';
      this.showDownload = true;
    }
    this.loading = true;
    if (this.pageType == 'sldcomponent') {
      this.utilService.getDownloadExcelFormJob(this.substationId, this.isExport).subscribe({
        next: res => {
          // this.utilService.getDownloadExcelFormJob('ef0c3442-6333-4149-8b89-98bf2dfcc9c2','true').subscribe(res => {
          this.filterColumns = res.sldExportSubstationDtosList;
          this.filterColumns.forEach(item => {
            item.excelRequrestTs = moment.utc(item.excelRequrestTs).local().toDate();
            item.excelCompleteTs = moment.utc(item.excelCompleteTs).local().toDate();
          });
          this.loading = false;
        }, error: () => {
          this.loading = false;
        }
      });
    }
    else if (this.pageType == 'historicaldata') {
      this.appSvc.getDownloadExcelFormJobDataHistoriztion(this.substationId, this.isExport,this.pageType).subscribe({
        next: res => {
          // this.utilService.getDownloadExcelFormJob('ef0c3442-6333-4149-8b89-98bf2dfcc9c2','true').subscribe(res => {
          this.filterColumns = res.historicalDataExportSubstationDtosList;
          this.filterColumns.forEach(item => {
            item.excelRequrestTs = moment.utc(item.excelRequrestTs).local().toDate();
            item.excelCompleteTs = moment.utc(item.excelCompleteTs).local().toDate();
          });
          this.loading = false;
        }, error: () => {
          this.loading = false;
        }
      });
    }
  }
  openDownloadModal(item) {
    const sliceSize = 512
    let byteCharacters = atob(item.documentObj);
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    if ((this.isExport == false || this.isExport.toLowerCase() == 'false') && this.pageType == 'historicaldata' && item.isError === true) {
      const blob = new Blob(byteArrays, { type: 'text/plain;charset=utf-8' });
      this._FileSaverService.save(blob, 'Error Report.txt');
    }
    else {
      const blob = new Blob(byteArrays, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      this._FileSaverService.save(blob, 'Component Data.xlsx');
    }
  }
}
