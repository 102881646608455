<div class="loading-box"  *ngIf="loading">
  <div class="img"  alt="loading"></div>
</div>
<div *ngIf="tab == 'Installations' || tab == 'Projects'" class="main-wrapperOpc" [class.expandproject]="expand">
  <a *ngIf="tab == 'Installations' || tab == 'Projects'" (click)="expandModal()" class="expand"><i class="icon"
      aria-hidden="true" [class.icon-prev]="expand" [class.icon-next]="!expand"></i></a>
  <!-- operationMode -->
  <div *ngIf="tab == 'Installations' || tab == 'Projects'" class="tab">
    <a (click)="tabChangefn('Installations');tabChange.emit('Installations')"
      [class.active]="tab === 'Installations'">Installations</a>
    <a (click)="tabChangefn('Projects');tabChange.emit('Projects')" [class.active]="tab === 'Projects'">Projects</a>
  </div>
  <div class="content" *ngIf="tab === 'Installations'">
    <div class="search-bar">
      <input class="input-ctrl search-ctrl" [(ngModel)]="installationSearchText"
       (input)="updateInstallationFilter($event)" placeholder="Type to search"
        type="text" style="width: 160px;" />
    </div>
    <div class="margin-top-30" style="padding-right: 3%" style="flex: 1;">
      <csp-common-filter class="filter1" style="width: 200px;" [filterOptions]="installMainFilterList"
        [multipleFilterOptions]="installCompFilterList" (filterDataEmitter)="applyFilterDataSpareGroup($event)"
        (expandFilterView)="expandFilterView($event)" [tabName]="tabName"  [filterAPICallType]="'Installations'"
        [totalOptionLength]="installAllFilterListCount" [isFullScreen]="expand" [ActualFilterInput]="filterRequest"
        (filterItemEmit)="filterItemEmit($event)" [filterStyles]="filterStyles">
      </csp-common-filter>
    </div>
    <perfect-scrollbar fxFlex="auto" [scrollIndicators]="true">
      <div class="table-wrapper">
        <div class="freeze-table">
          <div class="wrapper">
            <ng-container [ngTemplateOutlet]="table"></ng-container>
          </div>
          <div class="fixed-wrapper">
            <ng-container [ngTemplateOutlet]="table"></ng-container>
          </div>
        </div>
        <ng-template #table>
          <table *ngIf="Installations && Installations.length; else noDataInstallation;" class="base-table"
            aria-hidden="true">
            <tr>
              <ng-container *ngFor="let column of InstallationfilterColumns; let idx = index;">
                <th class="textStyle" id="idinstallationSortBy" [class.expand-table]="expand"
                  (click)="sortInstallation(column.prop)" *ngIf="InstallationfilterColumns[idx].selected">
                  {{column.name}}
                  <i class="default-sort-btn" aria-hidden="true"
                    [class.sort-asc]="this.installationSortDirection && (this.installationSortBy === column.prop)"
                    [class.sort-desc]="!this.installationSortDirection && (this.installationSortBy === column.prop)"></i>
                </th>
              </ng-container>
            </tr>
            <ng-container *ngFor="let item of Installations | paginate: configInstallation; let idx = index;">
              <ng-container>
                <tr (mouseover)="InstallationHo(item.installationId)" (mouseleave)="InstallationHo(null)"
                  [class.active]="hoverOverMarker !== null ? hoverOverMarker.getId() === item.installationId : false">
                  <ng-container *ngFor="let column of InstallationfilterColumns; let idx = index;">
                    <td (click)="clearSubstationNotificationSignal(item.id);installationInformation(item)"
                      *ngIf="InstallationfilterColumns[idx].selected" [class.expand-table]="expand"
                      title="{{item[column.prop]}}">
                      <ng-container>{{item[column.prop]}}</ng-container>
                    </td>
                  </ng-container>
                  <td (click)="openTool()">
                    <button class="btnn" type="button" [ngbTooltip]="tipbudget" [autoClose]="true" triggers="manual"
                      #t="ngbTooltip" (click)="t.open()"><i class="icon square-menu-icon" aria-hidden="true"></i>
                    </button>
                  </td>
                  <ng-template #tipbudget>
                    <div class="pop-container">
                      <div [ngClass]="{disabledNoOfCasesDiv: item.isExternal === 'true'}" class="menu-option" (click)="installationInformation(item)">
                        <div>Installation information</div>
                      </div>
                      <div class="menu-option" (click)="projectInformation(item.projectId)">
                        <div>Project information</div>
                      </div>
                      <div class="menu-option" [ngClass]="{disabledNoOfCasesDiv: showicon}" (click)="gotoProject(item)">
                        <div>Go to project</div>
                      </div>
                    </div>
                  </ng-template>
                </tr>
              </ng-container>
            </ng-container>
          </table>
        </ng-template>
      </div>
    </perfect-scrollbar>
    <div class="show-more-wrapper" (click)="seeMore()" *ngIf="Installations.length > 0">
      <div style="display: inline-flex; height:30px;">
        <pagination-controls (pageChange)="pageChange($event)" nextLabel="" previousLabel="" class="my-pagination">
        </pagination-controls>
        <div style="padding-top: 7px;" *ngIf="expand"> Rows: </div>
        <div style="padding-top: 4px;" *ngIf="expand">
          <select (change)="pageSizeChangedInst($event)" [(ngModel)]="configInstallation.itemsPerPage">
            <option *ngFor="let currentPageSize of pageSizeOption; let i = index" [value]="currentPageSize"
              [selected]="i == 0">{{currentPageSize}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div class="content" *ngIf="tab === 'Projects'">
    <div style="display: flex;">
      <div class="search-bar">
        <input class="input-ctrl search-ctrl" (input)="updateProjectFilter($event)" 
          [(ngModel)]="projectSearchText" placeholder="Type to search"
          type="text" style="width: 160px;"/>
      </div>
      <div class="margin-top-30" style="padding-right: 3%" style="flex: 1;">
        <csp-common-filter class="filter1" style="width: 200px;" [filterOptions]="projMainFilterList"
          [multipleFilterOptions]="projCompFilterList" (filterDataEmitter)="applyFilterDataSpareGroup($event)"
          (expandFilterView)="expandFilterView($event)" [tabName]="tabName" [totalOptionLength]="projAllFilterListCount"
          [isFullScreen]="expand" [ActualFilterInput]="filterRequest" (filterItemEmit)="filterItemEmit($event)"
          [filterStyles]="filterStyles"  [filterAPICallType]="'Projects'">
        </csp-common-filter>
      </div>
    </div>
    <perfect-scrollbar fxFlex="auto">
      <div class="table-wrapper">
        <div class="freeze-table">
          <div class="wrapper" style="min-height: 219px">
            <ng-container [ngTemplateOutlet]="table2"></ng-container>
          </div>
          <div class="fixed-wrapper">
            <ng-container [ngTemplateOutlet]="table2"></ng-container>
          </div>
        </div>
        <ng-template #table2>
          <table *ngIf="Projects && Projects.length; else noDataProjects;" style="margin-bottom: 11px;"
            class="base-table" aria-hidden="true">
            <tr>
              <ng-container *ngFor="let column of projectFilterColumns; let idx = index;">
                <th class="textStyle" id="idSubstationSortBy2" (click)="sortProjects(column.prop)"
                  style="width: 150px; text-overflow: ellipsis;" *ngIf="projectFilterColumns[idx].selected">
                  {{column.name}}
                  <i class="default-sort-btn" aria-hidden="true"
                    [class.sort-asc]="this.projectSortDirection && (this.projectSortBy === column.prop)"
                    [class.sort-desc]="!this.projectSortDirection && (this.projectSortBy === column.prop)"></i>
                </th>
              </ng-container>
            </tr>
            <ng-container *ngFor="let item of Projects | paginate: configProject; let idx = index;">
              <ng-container>
                <tr (mouseover)="projectHighlight(item.projectId)" (mouseleave)="projectHighlight(null)"
                  [class.active]="hoverOverMarker !== null ? hoverOverMarker.projectId === item.projectId : false">
                  <ng-container *ngFor="let column of projectFilterColumns; let idx = index;">
                    <td style="white-space: nowrap ; overflow: hidden;
                    max-width: 100px; text-overflow: ellipsis;" title="{{item[column.prop]}}"
                      *ngIf="projectFilterColumns[idx].selected">
                      <ng-container>{{item[column.prop]}}</ng-container>
                    </td>
                  </ng-container>
                  <td (click)="openTool()">
                    <button class="btnn" type="button" [ngbTooltip]="tipbudget" [autoClose]="true" triggers="manual"
                      #t="ngbTooltip" (click)="t.open()"><i class="icon square-menu-icon" aria-hidden="true"></i>
                    </button>
                  </td>
                  <ng-template #tipbudget>
                    <div class="pop-container">
                      <div class="menu-option" (click)="projectInformation(item.projectId)">
                        <div>Project information</div>
                      </div>
                      <div class="menu-option" (click)="gotoProject(item)" [ngClass]="{disabledNoOfCasesDiv: showicon}">
                        <div>Go to project</div>
                      </div>
                    </div>
                  </ng-template>
                </tr>
              </ng-container>
            </ng-container>
          </table>
        </ng-template>
      </div>
    </perfect-scrollbar>
    <div class="show-more-wrapper" *ngIf="Projects.length > 0">
      <div style="display: inline-flex; height:30px;">
        <pagination-controls (pageChange)="pageChange($event)" nextLabel="" previousLabel="" class="my-pagination">
        </pagination-controls>
        <div style="padding-top: 7px;" *ngIf="expand"> Rows: </div>
        <div style="padding-top: 4px;" *ngIf="expand">
          <select (change)="pageSizeChangedProj($event)" [(ngModel)]="configProject.itemsPerPage">
            <option *ngFor="let currentPageSize of pageSizeOption; let i = index" [value]="currentPageSize"
              [selected]="i == 0">{{currentPageSize}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End of operationMode -->
<div *ngIf="tab !== 'Installations' && tab !== 'Projects'" class="main-wrapper" [class.expand]="expand">
  <a *ngIf="tab !== 'Installations' && tab !== 'Projects'" (click)="expandModal()" class="expand"><i class="icon"
      aria-hidden="true" [class.icon-prev]="expand" [class.icon-next]="!expand"></i></a>
  <div class="tab">
    <a *ngIf="!onlyAssets" (click)="tab = 'Substations'; tabChange.emit('Substations')"
      [class.active]="!onlyAssets && tab === 'Substations'">Substations</a>
    <a *ngIf="fromRelCenter" (click)="tab = 'Assets'; tabChange.emit('Assets')"
      [class.active]="!onlyAssets && tab === 'Assets'">Assets</a>
  </div>
  <div class="content" *ngIf="tab === 'Substations'">
    <div class="search-bar" *ngIf="!fromRelCenter">
      <input (input)="update($event)" placeholder="Type to search" type="text" />
    </div>
    <perfect-scrollbar fxFlex="auto">
      <div class="table-wrapper">
        <div class="freeze-table">
          <div class="wrapper">
            <ng-container [ngTemplateOutlet]="table"></ng-container>
          </div>
          <div class="fixed-wrapper">
            <ng-container [ngTemplateOutlet]="table"></ng-container>
          </div>
        </div>
        <ng-template #table>
          <table *ngIf="substations && substations.length; else noDataSubstation;" class="base-table"
            aria-hidden="true">
            <tr>
              <ng-container *ngFor="let column of filterColumns; let idx = index;">
                <th id="idSubstationSortBy" [class.expand-table]="expand" (click)="sort(column.prop)"
                  *ngIf="filterColumns[idx].selected">
                  {{column.name}}
                  <i class="default-sort-btn" aria-hidden="true"
                    [class.sort-asc]="this.substationSortDirection && (this.substationSortBy === column.prop)"
                    [class.sort-desc]="!this.substationSortDirection && (this.substationSortBy === column.prop)"></i>
                </th>
              </ng-container>
            </tr>
            <ng-container *ngFor="let item of filteredSubstations; let idx = index;">
              <ng-container *ngIf="idx < substationIndex">
                <tr (mouseover)="hoverOnSubstation(item.id)" (mouseleave)="hoverOnSubstation(null)" [class.active]="(hoverOverMarker !== null ? hoverOverMarker.getId() === item.id : false) ||
            (selectedRegion && selectedRegion.get('region') === item.region)">
                  <ng-container *ngFor="let column of filterColumns; let idx = index;">
                    <td (click)="clearSubstationNotificationSignal(item.id)" *ngIf="filterColumns[idx].selected"
                      [class]="column.prop === 'riskValue' ? item.risk: ''" [class.expand-table]="expand"
                      title="{{item[column.prop]}}">
                      <i class="notification" aria-hidden="true" *ngIf="idx === 0"
                        [style.visibility]="shouldDisplaySubstationNotificationSignal(item.id) ? 'visible': 'hidden'"></i>
                      <ng-container>{{item[column.prop]}}</ng-container>
                    </td>
                  </ng-container>
                </tr>
              </ng-container>
            </ng-container>
          </table>
        </ng-template>
      </div>
    </perfect-scrollbar>
    <div class="show-more-wrapper" *ngIf="!fromRelCenter">
      <a class="show-more" (click)="substationIndex = substationIndex + 10"
        *ngIf="substationIndex < substations?.length">See more</a>
    </div>
  </div>

  <div class="content" *ngIf="tab === 'Assets' && (router.url.indexOf('information-explorer') > -1) || fromRelCenter">
    <div class="search-bar">
      <input (input)="updateAssetFilter($event)" placeholder="Type to search" type="text" />
    </div>
    <perfect-scrollbar fxFlex="auto">
      <div class="table-wrapper">
        <div class="freeze-table">
          <div class="filter-section" *ngIf="expand && assets.length>0">
            <div class="filter-container" style="right: -9px;height: 200px"
              [ngClass]="{'filter-hide':!assetFilterShow}">
              <div class="header">
                <i class="icon icon-filter" aria-hidden="true" (click)="assetFilterShow=!assetFilterShow"
                  [ngClass]="{'checked':!assetFilterShow}"></i>
                <span i18n>Column filter</span>
              </div>
              <div class="filter-option" *ngFor="let item of assetFilterColumns;let i = index"
                (click)="item.selected=!item.selected;">
                <i class="icon icon-checkbox" aria-hidden="true" [ngClass]="{'checked':item.selected}"></i>
                <span>{{item.name}}</span>
              </div>
            </div>
          </div>
          <div class="wrapper" style="min-height: 219px">
            <ng-container [ngTemplateOutlet]="table2"></ng-container>
          </div>
          <div class="fixed-wrapper">
            <ng-container [ngTemplateOutlet]="table2"></ng-container>
          </div>
        </div>
        <ng-template #table2>
          <table *ngIf="assets && assets.length; else noDataAsset;" style="margin-bottom: 11px;" class="base-table"
            aria-hidden="true">
            <tr>
              <ng-container *ngFor="let column of assetFilterColumns; let idx = index;">
                <th id="idSubstationSortBy2" (click)="sortAssert(column.prop)"
                  style="width: 150px; text-overflow: ellipsis;" *ngIf="assetFilterColumns[idx].selected">
                  {{column.name}}
                  <i class="default-sort-btn" aria-hidden="true"
                    [class.sort-asc]="this.assetSortDirection && (this.assetSortBy === column.prop)"
                    [class.sort-desc]="!this.assetSortDirection && (this.assetSortBy === column.prop)"></i>
                </th>
              </ng-container>
            </tr>
            <ng-container *ngFor="let item of assets | paginate: config; let idx = index;">
              <ng-container *ngIf="idx < assetIndex">
                <tr (mouseover)="hoverOnSubstation(item.substation, item)" (dblclick)="goToAssetInfo(item.id)"
                  (mouseleave)="hoverOnSubstation(null)" [class.active]="(hoverOverMarker !== null && !hover && assetHover === null ? hoverOverMarker.getId() === item.substation : false) ||
            (!hover && assetHover !== null ? item.id=== (hoverOverAsset!=null && hoverOverAsset.includes(item.id)?item.id:false): false) ||
            (selectedRegion && !hover && selectedRegion.get('region') === item.region)">
                  <ng-container *ngFor="let column of assetFilterColumns; let idx = index;">
                    <td style="white-space: nowrap ; overflow: hidden;
                max-width: 100px; text-overflow: ellipsis;" title="{{item[column.prop]}}"
                      (click)="clearAssetNotificationSignal(item.id)" *ngIf="assetFilterColumns[idx].selected"
                      [class]="column.prop === 'riskValue' ? item.riskColor: ''">
                      <i class="notification" aria-hidden="true" *ngIf="idx === 0"
                        [style.visibility]="shouldDisplayAssetNotificationSignal(item.id) ? 'visible': 'visible'"></i>
                      <ng-container>{{item[column.prop]}}</ng-container>
                    </td>
                  </ng-container>
                </tr>
              </ng-container>
            </ng-container>
          </table>
        </ng-template>
      </div>
    </perfect-scrollbar>
    <div class="show-more-wrapper" (click)="seeMore()"
      *ngIf="(router.url.indexOf('information-explorer') > -1) || fromRelCenter">
      <a class="show-more" (click)="assetIndex = assetIndex + 10;" *ngIf="assetIndex < assets.length">See more</a>
      <pagination-controls *ngIf="assetIndex!==10" (pageChange)="pageChange($event)" class="my-pagination">
      </pagination-controls>
    </div>
  </div>
</div>
<div *ngIf="!fromRelCenter">
  <ng-template #noDataSubstation>
    <div class="not-found-info">
      Cannot find any substation: {{substationSearch}}
    </div>
  </ng-template>
</div>
<ng-template #noDataAsset>
  <div class="not-found-info">
    Cannot find any asset: {{assetAssets}}
  </div>
</ng-template>
<ng-template #noDataProjects>
  <div class="not-found-info">
    Cannot find any projects
  </div>
</ng-template>
<ng-template #noDataInstallation>
  <div class="not-found-info">
    Cannot find any installation
  </div>
</ng-template>