<div class="loading-box" *ngIf="loading">
  <div class="img" alt="loading"></div>
</div>
<div class="modal-container">
  <div class="modal-header">
    <span class="modal-title" id="modal-basic-title" i18n>Manager user skill</span>
    <button type="button" class="close" aria-label="Close" (click)="deleteSkill('close')">
      <span aria-hidden="false">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div>
      <div class="label" i18n>Skill name</div> 
      <select [(ngModel)]="skill.skillId" (change)="selectedskill($event.target,skill.skillName)" class="select-ctrl">
        <option *ngFor="let possibleSkill of skills" [value]="possibleSkill.skillId">{{possibleSkill.skillName}}
        </option>
      </select>
      <span *ngIf="skillNameRequired" class="error-indicate" i18n>Skill name is Reqiured</span>

      <div class="level-of-expertise">
        <div class="label" i18n>Level of expertise</div>
        <div class="custom-slider">
        <mat-slider class="slider" [min]="1" [max]="5" [step]="1" discrete="true" showTickMarks="true">
        <input matSliderThumb [(ngModel)]="skillLevel">
        </mat-slider>
          <div class="slider-labels">
            <span>1</span>
            <span>2</span>
            <span>3</span>
            <span>4</span>
            <span>5</span>
          </div>
        </div>
      </div>
      <div class="items">
        <div class="item header">
          <span class="label">File</span>
          <div class="detail label">Validity</div>
          <div class="detail label"></div>
        </div>
        <div class="item" *ngIf="skill.skillCertificateName">
          <label (click)="removeSkill()">
            <i class="icon icon-remove" aria-hidden="true"></i>
          </label>
          <span>{{skill.skillCertificateName}}</span>
          <select [(ngModel)]="skill.skillCertificateValidityPeriodMonth" (change)="onChange($event.target.value)"
            class="select-ctrl detail">
            <option *ngFor="let month of months" [value]="month.id">{{month.item}}</option>
          </select>
          <select [(ngModel)]="skill.skillCertificateValidityPeriodYear" class="select-ctrl detail">
            <option *ngFor="let year of years" [value]="year">{{year}}</option>
          </select>
        </div>
        <a class="item add-new" (click)="attachDocument()">
          <label>
            <i class="icon icon-add-new" aria-hidden="true"></i>
          </label>
          <span i18n>Attach document</span>
          <input #fileInput type="file" [(ngModel)]="documentFile" hidden (change)="onInputFileSelected($event)">
        </a>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <a class="btn btn-save" (click)="deleteSkill('delete')" i18n>Delete</a>
    <a class="btn btn-save" (click)="save()" i18n>Assign</a>
  </div>
</div>