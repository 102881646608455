import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import kebabCase from 'lodash/kebabCase';
import { ApplicationService } from '../../../services/application.service';
import * as RouteHelper from 'src/app/utility/routing.helper';

export interface RiskMatrixValue {
  lowRisk: number;
  highRisk: number;
}
const LOW_RISK_DEFAULT = 30;
const HIGH_RISK_DEFAULT = 70;

const RISK_MIN = 10;
const RISK_MAX = 90;

@Component({
  selector: 'app-budget-risk-matrix',
  templateUrl: './budget-risk-matrix.component.html',
  styleUrls: ['./budget-risk-matrix.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => BudgetRiskMatrixComponent),
    multi: true
  }]
})
export class BudgetRiskMatrixComponent implements OnInit {

  @Input() riskValues = [];
  @Input() hoverOverMarker = null;
  @Input() hoverOverAsset = null;
  @Input() viewTab = null;
  @Input() selectedRegion = null;
  @Input() chartOnly: any;
  @Input() pointSize: any;
  @Input() contextMenu = false;
  @Input() resizeOnHover: any;
  @Input() fromReliabilityCenter = false;
  @Input() decimalDelimiterValue = '';
  @Output() substationHover = new EventEmitter();
  @Output() assetHover = new EventEmitter();
  riskBudgetInfo = false;
  isAbsoluteActive = false;
  currencyAbbreviation = '';
  loading = false;
  toolTipData = [];
  currencyDelimiter: any;
  riskMatrixdata = false;
  

  riskMatrixValue = { absolute: { lowRisk: LOW_RISK_DEFAULT, highRisk: HIGH_RISK_DEFAULT }, flexible: [] };
  userRole= '';
  @ViewChild('riskMatrix', { static: false }) riskMatrixElement: any;

  /**
   * Invoked when the model has been changed
   */
  onChange: (_: any) => void = (_: any) => {
  }

  /**
   * Invoked when the model has been touched
   */
  onTouched: () => void = () => {
  }

  constructor(
    private router: Router, private appSvc: ApplicationService,
    private route: ActivatedRoute
  ) {
    
  }

  ngOnInit() {
    this.userRole = sessionStorage.getItem('currentRoleSelected');
    this.loadRiskMatrixData(undefined);
    this.loadCurrencyValue();
    // const tt = this.chartOnly;
    this.appSvc.getProjectCurrencyDelimiter().subscribe(data => {
      if (data != null && data !== undefined) {
      this.currencyDelimiter =  data.decimalDelimiter;
      }
    });
  }

  tooltip() {
    if ((this.toolTipData.length > 5 ) && (this.toolTipData[0].chart === undefined)) {
      this.riskMatrixdata = true;
    } else if ((this.toolTipData.length > 1 ) && (this.toolTipData[0].chart !== undefined)) {
      this.riskMatrixdata = true;
    } else {
      this.riskMatrixdata = false;
    }
  }

  loadCurrencyValue() {
    this.appSvc.GetCurrencyDetails().subscribe(data => {
      if (data != null && data !== undefined) {
        this.currencyAbbreviation = data.returnObj;
      }
    });
  }

  getClassName(name) {
    return kebabCase(name);
  }

  loadRiskMatrixData(delay) {
    // this.appSvc.getRiskMatrix(delay).subscribe((matrix: {riskMatrixAbsolute, riskMatrixFlexible}) => {
    this.loading = true;
    this.appSvc.getRiskMatrix().subscribe({
      next: (matrix) => {
     // debugger;
      this.riskMatrixValue = { absolute: matrix.flexible.absolute, flexible: [...matrix.flexible.flexible] };
      this.isAbsoluteActive = matrix.isAbsoluteActive;
      this.loading = false;
    }});
  }

  setTooltipData(data) {
    this.toolTipData = [];
    for (let i = 0; i < this.riskValues.length; i++) {
      if (this.riskValues[i].risk === data.risk && this.riskValues[i].risk1 === data.risk1) {
        if(this.riskValues[i].chart !== undefined) {
          this.riskBudgetInfo = true;
        }
        this.toolTipData.push(this.riskValues[i]);
      }
    }
  }

  showInformation() {
    this.router.navigate([RouteHelper.addProjectPrefix(`/planning/asset-details/1`)]);
  }
  hoverOnSubstation(id) {
      this.substationHover.emit(id);
  }

  hoverOnAsset(asset) {
    let tooldisplay = false;
    if(asset){
      tooldisplay = true;
      for (let i = 0; i < this.riskValues.length; i++) {
        if (this.riskValues[i] && this.riskValues[i].risk === asset.risk && this.riskValues[i].risk1 === asset.risk1) {
          this.assetHover.emit(this.riskValues[i]);
        }
      }
    }
    else{
      this.assetHover.emit(null);
    }
    if(tooldisplay === true){
      let element:HTMLElement = document.getElementById('ele');
      element.click();
    }
  }

  // getBudgetrow(budget) {
  //   return budget.toString().replace('.', this.decimalDelimiterValue).replace(',', this.decimalDelimiterValue);
  // }

}
