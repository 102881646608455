import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { SuccesspopupComponent } from '../app/containers/dashboard/modals/successpopup/successpopup.component';
import { NgbModal, NgbModalConfig, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from './settings.service'
import tokens from '../assets/token.json';
import { KeycloakService } from 'keycloak-angular';
import { ConstService } from '../services/const.service';
const acesstoken = 'Bearer ' + tokens.token;
import * as RouteHelper from 'src/app/utility/routing.helper';

// const API_GET = environment.apiURL;

@Injectable()
export class HelperService {
  response: any;
  count = 0;
  msurl = 'from direct assignment';
  exaurl = 'from direct assignment';
  APIurl = 'from direct assignment';
  auditLogurl = '';
  tenantAdminUrl;
  commonurl = '';
  loadDataUrl = '';
  pmPropertyUrl = '';
  pmSubsystemUrl = '';
  operationCenter;
  constructor(
    private http: HttpClient,
    private router: Router, private activatedRoute: ActivatedRoute,
     private modalService: NgbModal, config: NgbModalConfig,
     mysettingsService: SettingsService,private keycloak:KeycloakService,
    private constService: ConstService,
  ) {
    config.backdrop = 'static';
    this.msurl = mysettingsService.settings.managesystemApiURL;
    this.exaurl = mysettingsService.settings.exaURL;
    this.APIurl = mysettingsService.settings.managesystemApiReportURL;
    this.commonurl = mysettingsService.settings.CommonAPIUrl;
    this.loadDataUrl =  mysettingsService.settings.loadDataApiURL;
    this.pmPropertyUrl = mysettingsService.settings.performanceModelPropertyApiURL;
    this.pmSubsystemUrl = mysettingsService.settings.performanceModelSubsystemApiURL;
    this.tenantAdminUrl = mysettingsService.settings.tenantAdminAPIUrl;
    this.auditLogurl = mysettingsService.settings.auditApiURL;
    this.operationCenter = mysettingsService.settings.operationCenter;
    const token = sessionStorage.getItem('RefershToken');
    if (this.router.url === '/' && token === null) {
     // sessionStorage.setItem('AccessToken', acesstoken);
    }

  }
  get1(url: string, options?: any): Observable<any> {
    return this.http
      .get(url, this.requestOptions(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }
  getdd(url: string, options?: any): Observable<any> {
    const API = window.location.origin;
    return this.http
      .get(API + url, this.requestOptions(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }
  get2(url: string, options?: any): Observable<any> {
    const API = window.location.origin;
    return this.http
      .get(API + url, this.requestOptions(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }
  /**
  * Performs a request with `get` http method.
  * @param url the url
  * @param options the request options
  * @returns Observable<any>
  */

  get(url: string, options?: any): Observable<any> {
    const API = this.msurl;
    // if (url.split('/')[1] === 'assets') {
    //   API = API_GET;
    // }
    return this.http
      .get(API + url, this.requestOptions(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }

  getAuditLog(url: string, options?: any): Observable<any> {
    const API = this.auditLogurl;
    return this.http
      .get(API + url, this.requestOptions(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }

  getAuditFile(url: string, options?: any): Observable<any> {
    const API = this.auditLogurl;
    return this.http
      .get(API + url, this.requestOptionFile(options, '',true))
      .pipe(catchError(err => this.catchAuthError(err)));
  }

  getOpCenter(url: string, options?: any): Observable<any> {
    const API = this.operationCenter;
    return this.http
      .get(API + url, this.requestOptionsWithEmptyProjectId(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }

  getLogIn(url: string, options?: any): Observable<any> {
    const API = this.msurl;
    return this.http
      .get(API + url, this.requestOptionsLogin(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }

  getTenantAdmin(url: string, options?: any): Observable<any> {
    const API = this.tenantAdminUrl;
    return this.http
      .get(API + url, this.requestOptions(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }
  postTenantAdmin(url: string, data: any, options?: any): Observable<any> {
    return this.http.post(this.tenantAdminUrl + url, data, this.requestOptions(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }
  deleteTenantAdmin(url: string, options?: any): Observable<any> {
    return this.http.delete(this.tenantAdminUrl + url, this.requestOptions(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }
  putTenantadmin(url: string, PutData: any, options?: any): Observable<any> {
    return this.http.put(this.tenantAdminUrl + url, PutData, this.requestOptions(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }
  getUrl(url: string, options?: any): Observable<any> {
    const API = this.APIurl;
    return this.http
      .get(API + url, this.requestOptions(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }

  getPmProperty(url: string, options?: any): Observable<any> {
    const API = this.pmPropertyUrl;
    return this.http
      .get(API + url, this.requestOptionsReport(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }

  getPmSubsystem(url: string, options?: any): Observable<any> {
    const API = this.pmSubsystemUrl;
    return this.http
      .get(API + url, this.requestOptionsReport(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }

  getTaskPlanStatus(url: string, options?: any): Observable<any> {
    const API = this.msurl;
    return this.http
      .get(API + url, this.requestOptionsReport(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }
  getreportaccess(url: string, options?: any): Observable<any> {
        const API = this.commonurl;
    return this.http
      .get(API + url, this.requestOptionsreports(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }
  getLink(url: string, options?: any): Observable<any> {
    const API = this.msurl;
return this.http.get(API + url).pipe(catchError(er => this.catchAuthError(er)));
  }
  /**
   * Performs a request with `post` http method.
   * @param url the url
   * @param data the Post request Payload
   * @param options the request options
   * @returns {Observable<any>}
   **/

  postLogin(url: string, data: any, options?: any): Observable<any> {
    // return this.http.post(API_URL + url, data, this.requestOptions(options));
    return this.http.post(this.msurl + url, data)
      .pipe(catchError(err => this.catchAuthError(err)));
  }
  /**
    * Performs a request with `post` http method.
    * @param url the url
    * @param data the Post request Payload
    * @param options the request options
    * @returns {Observable<any>}
    **/

  post(url: string, data: any, options?: any): Observable<any> {
    // return this.http.post(API_URL + url, data, this.requestOptions(options));
    return this.http.post(this.msurl + url, data, this.requestOptions(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }
  patchOperationCenter(url: string, PatchData: any, options?: any): Observable<any> {
    return this.http.patch(this.operationCenter + url, PatchData, this.requestOptionsWithEmptyProjectId(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }
  postInstBase(url: string, PostData: any, options?: any): Observable<any> {
    return this.http.post(this.operationCenter + url, PostData, this.requestOptionsWithEmptyProjectId(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }
  getInstBase(url: string, options?: any): Observable<any> {
    return this.http.get(this.operationCenter + url, this.requestOptionsWithEmptyProjectId(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }
  postData(url: string, data: any, options?: any): Observable<any> {
    return this.http.post(this.loadDataUrl + url, data, this.requestOptions(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }
  postMsUrl(url: string, data: any, options?: any): Observable<any> {
    // return this.http.post(API_URL + url, data, this.requestOptions(options));
    return this.http.post(this.msurl + url, data, this.requestOptionsMsUrl(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }
  getData(url: string, options?: any): Observable<any> {
    return this.http.get(this.loadDataUrl + url, this.requestOptions(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }
  /**
  * Performs a request with `post` http method.
  * @param url the url
  * @param PutData the Post request Payload
  * @param options the request options
  * @returns {Observable<any>}
  **/

  put(url: string, PutData: any, options?: any, consumerHandledErrorCodes?: { [key: string]: boolean }): Observable<any> {
    return this.http.put(this.msurl + url, PutData, this.requestOptions(options))
      .pipe(catchError(err => this.catchAuthError(err, consumerHandledErrorCodes)));
  }

  putd(url: string, PutData: any, options?: any): Observable<any> {
    return this.http.put(this.msurl + url, PutData, this.requestOptionsMsUrl(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }

  /**
 * Performs a request with `get` http method.
 * @param url the url
 * @param options the request options
 * @returns Observable<any>
 * @todo remove and maintain only one GET
 */

  getSample(url: string, options?: any): Observable<any> {
    const op = options;
    return this.http.get(this.exaurl + url, this.requestOptions(options));
  }

  postSample(url: string, data: any, options?: any): Observable<any> {
    // return this.http.post(API_URL + url, data, this.requestOptions(options));
    return this.http.post(this.exaurl + url, data, this.requestOptionsSWMS(options, 'post'));
  }
  putSample(url: string, PutData: any, options?: any): Observable<any> {
    return this.http.put(this.exaurl + url, PutData, this.requestOptions(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }
  uploadSample(url: string, data: any, options?: any): Observable<any> {
    const formData: FormData = new FormData();
    for (var i = 0; i < data.length; i++) {
      formData.append(data[i].id, data[i].image);
    }
    return this.http.post(this.exaurl + url, formData, this.requestOptions(options, 'upload'))
      .pipe(catchError(err => this.catchAuthError(err)));
  }

  /**
   * Performs a request with `post` http method.
   * @param url the url
   * @param PutData the Post request Payload
   * @param options the request options
   * @returns {Observable<any>}
   **/

  putUserConfirm(url: string, PutData: any, options?: any): Observable<any> {
    return this.http.put(this.msurl + url, PutData)
      .pipe(catchError(err => this.catchAuthError(err)));
  }

  /**
  * Performs a request with `patch` http method.
  * @param url the url
  * @param PatchData the Patch request Payload
  * @param options the request options
  * @returns {Observable<any>}
  **/

  patch(url: string, PatchData: any, options?: any): Observable<any> {
    // return this.http.patch(API_URL + url, PatchData, this.requestOptions(options));
    return this.http.patch(this.msurl + url, PatchData, this.requestOptions(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }

  delete(url: string, options?: any): Observable<any> {
    return this.http.delete(this.msurl + url, this.requestOptions(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }
  private requestOptionsMsUrl(options?: any, method?: string): any {
    if (!options) {
      options = {};
    }
    const roleselected = sessionStorage.getItem('currentRoleSelected');
    const currentRole = sessionStorage.getItem('currentRole');
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': sessionStorage.getItem('AccessToken'),
        'ProjectId' : RouteHelper.getProjectIdForAPIHeader(),
        'RoleName' : roleselected || currentRole || ''
       //  'Authorization': acesstoken,
      });
    // if (options.headers || method === 'put' || method === 'post') {
    options.headers = headers;
    // }
    return options;
  }

  // getCurrentRole() {
  //   const roleselected = sessionStorage.getItem('currentRoleSelected');
  //   const currentRole = sessionStorage.getItem('currentRole');
  //   if(roleselected === null) {
  //     return currentRole;
  //   } else {
  //     return roleselected;
  //   }
  // }
  /**
   * Request options.
   * @param method the method
   * @returns RequestOptionsArgs
   */
  private requestOptions(options?: any, method?: string): any {
    if (!options) {
      options = {};
    }

    const roleselected = sessionStorage.getItem('currentRoleSelected');
    const currentRole = sessionStorage.getItem('currentRole');
    const headers = {
      'Authorization': sessionStorage.getItem('AccessToken'),
      'ProjectId' : RouteHelper.getProjectIdForAPIHeader(),
      'RoleName' : roleselected || currentRole || ''
    };

    if (options.headers) {
      options.headers = new HttpHeaders(Object.assign({}, headers, options.headers));
    } else {
      options.headers = new HttpHeaders(headers);
    }

    return options;
  }

    /**
   * Request options.
   * @param method the method
   * @returns RequestOptionsArgs
   */
    private requestOptionsWithEmptyProjectId(options?: any, method?: string): any {
      if (!options) {
        options = {};
      }

      const roleselected = sessionStorage.getItem('currentRoleSelected');
      const currentRole = sessionStorage.getItem('currentRole');
      const headers = {
        'Authorization': sessionStorage.getItem('AccessToken'),
        'ProjectId' : this.constService.emptyGuid,
        'RoleName' : roleselected || currentRole || ''
      };

      if (options.headers) {
        options.headers = new HttpHeaders(Object.assign({}, headers, options.headers));
      } else {
        options.headers = new HttpHeaders(headers);
      }
      return options;
    }
  private requestOptionFile(options?: any, method?: string, isFile?: boolean): any {
    if (!options) {
      options = {};
    }
    const roleselected = sessionStorage.getItem('currentRoleSelected');
    const currentRole = sessionStorage.getItem('currentRole');
    const headers = new HttpHeaders({
      'Authorization': sessionStorage.getItem('AccessToken'),
      'ProjectId' : RouteHelper.getProjectIdForAPIHeader(),
      'RoleName' : roleselected || currentRole || ''
    });
    options.headers = headers;
    if (isFile) {
      options.responseType = 'arraybuffer';
    }
    return options;
  }

  private requestOptionsReport(options?: any, method?: string): any {
    if (!options) {
      options = {};
    }
    const roleselected = sessionStorage.getItem('currentRoleSelected');
    const currentRole = sessionStorage.getItem('currentRole');
    const headers = new HttpHeaders({
      'Authorization': acesstoken,
      'RoleName' : roleselected || currentRole || ''
    });
    options.headers = headers;
    return options;
  }

  private requestOptionsSWMS(options?: any, method?: string): any {
    if (!options) {
      options = {};
    }
    const roleselected = sessionStorage.getItem('currentRoleSelected');
    const currentRole = sessionStorage.getItem('currentRole');
    if (options.headers || method === 'put' || method === 'post') {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('AccessToken'),
        'ProjectId' : RouteHelper.getProjectIdForAPIHeader(),
        'RoleName' : roleselected || currentRole || ''
      });
      options.headers = headers;
    } else if (method === 'upload') {
      const headers = new HttpHeaders({
        'Authorization': sessionStorage.getItem('AccessToken'),
        'ProjectId' : RouteHelper.getProjectIdForAPIHeader(),
         'RoleName' : roleselected || currentRole || ''
      });
      options.headers = headers;
    }
    return options;
  }
  /**
  * Request options.
  * @param method the method
  * @returns RequestOptionsArgs
  */
  private requestOptionsLogin(options?: any, method?: string): any {
    if (!options) {
      options = {};
    }
    // if (options.headers || method === 'put' || method === 'post') {
    const headers = new HttpHeaders({
      // 'Content-Type': 'application/json'
      'Authorization': sessionStorage.getItem('AccessToken'),
      'ProjectId' : RouteHelper.getProjectIdForAPIHeader()

    });
    options.headers = headers;
    // }
    return options;
  }
   private requestOptionsreports(options?: any, method?: string): any {
    if (!options) {
      options = {};
    }
    // if (options.headers || method === 'put' || method === 'post') {

   // }
    return options;
  }
  /**
   * catches the auth error
   * @param error the error response
   */
  catchAuthError(error, consumerHandledErrorCodes?: { [key: string]: boolean }): Observable<Response> {

    // if the consumer wants to handle the error directly, let it
    if (consumerHandledErrorCodes && consumerHandledErrorCodes[`${error.status}`]) {
      return throwError(() => new HttpErrorResponse(error));
    }

    // this.spinnerService.hide(); // hide the spinner if success
    if (error.status === 409) {
      const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
      if (error.error !== null && error.error.errorMessage) {
        modalRef.componentInstance.title = 'Warning';
        modalRef.componentInstance.message = error.error.errorMessage;
      }
      return throwError(() => new Error(error));

    } else if (error.status === 400) {
      const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
      if (error.error !== null && error.error.errorMessage) {
        modalRef.componentInstance.title = 'Warning';
        modalRef.componentInstance.message = error.error.errorMessage;
      } else if (error.error !== null && error.error.message === 'Validation Failed') {
        modalRef.componentInstance.title = 'Warning';
          modalRef.componentInstance.message = error.error.errors[0].message;
      } else if ((!Array.isArray(error.error)) && error.error.length > 0) {
        modalRef.componentInstance.title = 'Warning';
        modalRef.componentInstance.message = error.error;
      } else if ( error.error.errors.PmReportComment) {
        modalRef.componentInstance.title = 'Warning';
        modalRef.componentInstance.message = error.error.errors.PmReportComment;
      } else if ( error.error.errors){
        Object.keys(error.error.errors).forEach(key => {
          const value = error.error.errors[key];
          modalRef.componentInstance.title = 'Warning';
          modalRef.componentInstance.message = value;
        });
      }
      else {
        Object.keys(error.error).forEach(key => {
          const value = error.error[key];
          modalRef.componentInstance.title = 'Warning';
          modalRef.componentInstance.message = value;
        });
      }
      return throwError(() => new Error(error));

    } else if (error.status === 404) {
      const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
      modalRef.componentInstance.title = 'Not found';
      if (error.error !== null && error.error.errorMessage) {
        modalRef.componentInstance.message = error.error.errorMessage;
      } else {
        modalRef.componentInstance.message = 'Sorry, the page you asked for could not be found';
      }
      return throwError(() => new Error(error));

    } else if (error.status === 422) {
      const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
      if (error.error !== null && error.error.errorMessage) {
        modalRef.componentInstance.title = 'Warning';
        modalRef.componentInstance.message = error.error.errorMessage;
      } else {
        modalRef.componentInstance.title = 'Warning';
        modalRef.componentInstance.message = 'Unprocessable Entity';
      }
      return throwError(() => new Error(error));
    } else if (error.status === 401) {
      sessionStorage.clear();
      //localStorage.clear();
      if (this.router.url === '/project-settings') {
        this.keycloak.logout(window.location.origin+'/login').then(() => this.keycloak.clearToken());

      } else if (this.router.url === '/task-criterias') {
        this.keycloak.logout(window.location.origin+'/login').then(() => this.keycloak.clearToken());
      }else {
        this.modalService.dismissAll();
        this.keycloak.logout(window.location.origin+'/login').then(() => this.keycloak.clearToken());
      }
      // this.modalService.dismissAll();
      // this.router.navigate(['/login'], { queryParams: { order: 'popular' } });
      return throwError(() => new Error(error));

    } else if (error.status === 403 || error.status  === 417 ) {
      return throwError(() => new Error(error));
    }else if (error.status === 0){
      return throwError(() => new Error(error));
    } else {
      if (error.status !== undefined && error.status !== null) {
      const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
      modalRef.componentInstance.title = 'Error';
      modalRef.componentInstance.message = 'An error occurred. please try again later';
      return throwError(() => new Error(error));
      }
    }
     //return throwError(error);
  }

  convertNgbDateToDate(date: NgbDate) {
    var converted: Date = new Date();

    converted.setDate(date.day);
    converted.setMonth(date.month - 1);
    converted.setFullYear(date.year);

    return converted;
  }

  getFileName(name: string): string {
    var lastDot = name.lastIndexOf('.');
    return name.slice(0, lastDot);
  }

  getFileExtension(name: string): string {
    var parts = name.split('.')
    return parts[parts.length - 1]
  }
}
