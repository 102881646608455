<div class="modal-container">
  <div class="modal-header">
    <span class="modal-title" id="modal-basic-title" i18n>{{title}}</span>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('back')">
    </button>
  </div>
  <div class="modal-body">
    <div class="detail-container" *ngFor="let item of systemNotifications">
      <div class="message">
        <span class="bolder">{{item.message[0]}}</span>
        <span class="normal">lost</span>
        <span class="bolder">{{item.message[1]}}</span>
      </div>
      <span class="normal">{{item.createdAt}}</span>
    </div>
  </div>
</div>
