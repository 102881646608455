<div class="modal-container">
  <div class="modal-header">
    <span *ngIf="edit" class="modal-title" i18n>Editing Source</span>
    <span *ngIf="!edit" class="modal-title" i18n>Creating Source</span>
    <button type="button" class="close" (click)="deleteMarker()">
    </button>
  </div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-body">
      <h2 class="h2-title" i18n>Basic</h2>
      <div class="row">
        <div class="col-lg-6 col-sm-12">
          <div class="form-wrapper">
            <label class="label-element" i18n><sup>*</sup>Source reference</label>
            <input [class.invalid]="validation('sourceReference')" formControlName="sourceReference" type="text"
              class="form-input" />
          </div>
        </div>
        <div class="col-lg-6 col-sm-12">
          <div class="form-wrapper">
            <label class="label-element" i18n>Substation reference</label>
            <input [class.invalid]="validation('substationReference')" formControlName="substationReference" type="text"
              class="form-input" />
          </div>
        </div>
      </div>
      <h2 class="h2-title" i18n>Technical</h2>
      <div class="row">
        <div class="col-lg-6 col-sm-12">
          <div class="form-wrapper">
            <label class="label-element" i18n>Type of source</label>
            <select [class.invalid]="validation('typeOfSource')" formControlName="typeOfSource" 
              class="form-input" >
               <option *ngFor="let data of options;" [value]="data">{{data}}</option>
          </select>
          </div>
        </div>
        <div class="col-lg-6 col-sm-12">
            <div class="form-wrapper">
              <label class="label-element" i18n><sup>*</sup>Rated power (MW)</label>
              <input [class.invalid]="validation('ratedPower')" (keypress)="only_numbers(form.value['typeOfSource'], $event)" formControlName="ratedPower" type="number"
                class="form-input" maxlength="5" appTwoDigitDecimaNumber ="1" />
      </div>
        </div>
      </div>
        <div class="col-lg-6 col-sm-12" style='padding-left:0px'>
          <div class="form-wrapper" *ngIf="this.form.value['typeOfSource'] =='Others'">
            <input type="text" class="form-input" formControlName="typeOfSourceEtc" />
          </div>
        </div>
</div>
<div class="modal-footer" *ngIf="userRolesObject.GISSLDEditorEdit">
  <button *ngIf="edit" (click)="deleteMarker()" type="button" class="btn btn-link" i18n>Delete Source</button>
  <button *ngIf="!edit" (click)="onSubmit()" type="button" class="btn btn-primary" i18n>Save</button>
  <button *ngIf="edit" type="submit" class="btn btn-primary" i18n>Edit Source</button>

</div>
</form>
</div>