<div class="page-container">
    <div class="header">
        <header class="title">Select project</header>
        <sub class="sub">Information can be retrieved from the following projects:</sub>
    </div>

    <div class="projects-selection-listing">
        <div class="main_div">
            <ng-container ng-diable="disable">
                <div class="search-wrapper">
                    <input type='text' class="input-ctrl search-ctrl" (keyup)="search($event.target.value)"
                        placeholder='Type to search' />
                    <div class="margin-top-30" style="padding-right: 3%" style="flex: 1;">
                        <csp-common-filter class="filter1" style="width: 200px;" 
                            [filterOptions]="projMainFilterList"
                            [multipleFilterOptions]="projCompFilterList" 
                            [totalOptionLength]="projAllFilterCount"
                            [isFullScreen]="true" 
                            [isPopup]="filterAPICall" [filterAPICallType]="'Projects'"
                            (filterItemEmit)="filterItemEmit($event)"
                            [filterStyles]="filterStyles">
                        </csp-common-filter>
                    </div>
                </div>
            </ng-container>
            <ngx-datatable #projectsTable [rows]="projectsData | paginate: configPlan" 
            [count]="configPlan.totalItems" [offset]="configPlan.currentPage" [limit]="configPlan.itemsPerPage" (page)="pageChangeProjects($event)" [columnMode]="'force'" [rowHeight]="56" 
                [rowClass]="getRowClass" (sort)="onSortProjects($event)" (activate)="onRowClick($event)">

                <ngx-datatable-column *ngIf="filterColumnsProjects[0].selected" name="Project" prop='project' [draggable]="false">
                    <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
                        <div class="country-header">
                            <span [ngbTooltip]="row.projectName">{{row.projectName}}</span>
                        </div>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column *ngIf="filterColumnsProjects[1].selected" name="Status" prop='status' [draggable]="false">
                    <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
                        <div class="country-header">
                            <span [ngbTooltip]="row.projectStatusName">{{row.projectStatusName}}</span>
                        </div>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column *ngIf="filterColumnsProjects[2].selected" name="Start Date" prop='startDate' [draggable]="false">
                    <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
                        <div class="country-header" >
                            <span [ngbTooltip]="row.startDate">{{row.startDate}}</span>
                        </div>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column *ngIf="filterColumnsProjects[3].selected" name="End Date" prop='endDate' [draggable]="false">
                    <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
                        <div class="country-header" >
                            <span [ngbTooltip]="row.endDate">{{row.endDate}}</span>
                        </div>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column [sortable]="true" *ngIf="!filterColumnsProjects[0].selected && !filterColumnsProjects[1].selected &&
                !filterColumnsProjects[2].selected && !filterColumnsProjects[3].selected"> 
              </ngx-datatable-column>
     
              <ngx-datatable-footer> 
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" 
                  let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible"> 
                <div class="page-count"></div> 
                </ng-template> 
              </ngx-datatable-footer>

            </ngx-datatable>

            <div class="pagination">
                <pagination-controls id="pagination" (pageChange)="pageChangeProjects($event)"
                    previousLabel="Previous" nextLabel="Next">
                </pagination-controls>
            </div>

        </div>
    </div>
</div>

<div class="footer">
    <button class="btn btn-primary" (click)="close()">Cancel</button>
    <button class="btn btn-primary" [disabled]="!footerEnabled" (click)="goToInfoFile()">Information file</button>
    <button class="btn btn-primary" [disabled]="!footerEnabled || projectStatusName != 'Active'" 
    (click)="goToProject()">
        Go to project
    </button>
</div>