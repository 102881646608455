import { Pipe, PipeTransform } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
import { ConstService } from '../../services/const.service';

@Pipe({
    name: 'APItoUImappingFilter'
})
export class APItoUImappingFilterPipe implements PipeTransform {

    constructor(private appSvc: ApplicationService, private constService: ConstService) {
    }

    public transform(value: string, projectId: string, filterAPICallType: string): string {
        let text: string;
        //For System Environment == '00000000-0000-0000-0000-000000000000'
        if (projectId == this.constService.emptyGuid) {
            switch (value) {
                case 'ProjectName':
                    text = "Projects";
                    break;
                case 'CustomerName':
                    text = "Customer";
                    break;
                case 'Country':
                    text = "OU";
                    break;
                case 'StartDate':
                    text = "Start date";
                    break;
                case 'EndDate':
                    text = "End date";
                    break;
                case 'SubstationOwner':
                    text = "Owner";
                    break;
                case 'SubstationOperator':
                    text = "Operator";
                    break;
                case 'AssetCount':
                    text = "No. of assets";
                    break;
                case 'EqptType':
                    text = "Equipment type";
                    break;
                case 'Voltage':
                    text = "Operating voltage";
                    break;
                case 'Years':
                    text = "Manufacturing year";
                    break;
                default:
                    text = value;
            }
        }
        //For Project Environment  != '00000000-0000-0000-0000-000000000000'
        else if (projectId != this.constService.emptyGuid && filterAPICallType == 'Installation') {
            switch (value) {
                case 'ProjectName':
                    text = "Projects";
                    break;
                case 'CustomerName':
                    text = "Customer";
                    break;
                case 'StartDate':
                    text = "Start date";
                    break;
                case 'EndDate':
                    text = "End date";
                    break;
                case 'SubstationOwner':
                    text = "Owner";
                    break;
                case 'SubstationOperator':
                    text = "Operator";
                    break;
                case 'AssetCount':
                    text = "No. of assets";
                    break;
                case 'EqptType':
                    text = "Equipment type";
                    break;
                case 'Voltage':
                    text = "Operating voltage";
                    break;
                case 'Years':
                    text = "Manufacturing year";
                    break;
                case 'Installation':
                    text = "Location";
                    break;
                default:
                    text = value;
            }
        }
        //For Project Environment  != '00000000-0000-0000-0000-000000000000'  if (projectId != this.constService.emptyGuid) 
        else {
            switch (value) {
                case 'ProjectName':
                    text = "Projects";
                    break;
                case 'CustomerName':
                    text = "Customer";
                    break;
                case 'StartDate':
                    text = "Start date";
                    break;
                case 'EndDate':
                    text = "End date";
                    break;
                case 'SubstationOwner':
                    text = "Owner";
                    break;
                case 'SubstationOperator':
                    text = "Operator";
                    break;
                case 'AssetCount':
                    text = "No. of assets";
                    break;
                case 'EqptType':
                    text = "Equipment type";
                    break;
                case 'Voltage':
                    text = "Operating voltage";
                    break;
                case 'Years':
                    text = "Manufacturing year";
                    break;
                default:
                    text = value;
            }
        }
        return text;
    }
}

