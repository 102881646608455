<div class="loading-box" *ngIf="loading">
  <div class="img" alt="loading"></div>
</div>

<div class="page-container">
  <header class="header-container">
    <div class="header-item header-mid">
      <h3 i18n> Projects </h3>
    </div>
  </header>
</div>

<div class="project-listing">
  <div class="main_div">
    <ng-container ng-diable="disable">
      <div class="search-wrapper">
        <input type='text' class="input-ctrl search-ctrl" placeholder='Search' [(ngModel)]="filterStr"
          (keypress)="!isSpecialCharacter($event)" (keyup)='updateFilter($event.target.value,$event)' />
      </div>
    </ng-container>
    <div class="filter-container" [ngClass]="{'filter-hide':!filterShow}">
      <div class="header">
        <i class="icon icon-filter" aria-hidden="true" (click)="filterShow=!filterShow"
          [ngClass]="{'checked':!filterShow}"></i>
        <span i18n>Column filter</span>
      </div>
      <div class="filter-option" *ngFor="let item of filterColumns;let i = index"
        (click)="item.selected=!item.selected;">
        <i class="icon icon-checkbox" aria-hidden="true" [ngClass]="{'checked':item.selected}"></i>
        <span>{{item.name}}</span>
      </div>
    </div>
    <perfect-scrollbar fxFlex="auto" [scrollIndicators]="true" [ngClass]="isSystemManagerSupportRole ? 'scrollbar-with-create-new': 'scrollbar'">
      <ngx-datatable (activate)="onActivate($event)" #projectTable [rows]="filteredData | paginate: configPlan"
        [count]="configPlan.totalItems" [offset]="configPlan.currentPage" [limit]="configPlan.itemsPerPage"
        (page)="pageChange($event)" [columnMode]="'force'" [rowHeight]="56" (sort)="onSort($event)">
        <ngx-datatable-column *ngIf="filterColumns[0].selected" name="Project name" prop='projectname' [width]="auto"
          [draggable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
            <div class="country-header">
              <span class="company" [ngbTooltip]="row.projName">{{row.projName}}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="filterColumns[1].selected" name="Customer" prop='customer' [width]="250"
          [draggable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
            <div class="country-header">
              <span class="company" [ngbTooltip]="row.custName">{{row.custName}}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="filterColumns[2].selected" name="Continent" prop='country' [width]="250"
          [draggable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
            <div class="country-header">
              <span class="company" [ngbTooltip]="row.areaName">{{row.areaName}}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="filterColumns[3].selected" name="Project manager" prop='projectmanager'
          [width]="auto" [draggable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
            <div class="tcell-user">
              <img *ngIf="row.usersList[0]?.photo; else templateName" [src]="row.usersList[0]?.photo"
                class="icon icon-avatar icon-font-margin " alt="Avatar" />
              <ng-template #templateName>
                <i class="icon icon-avatar" aria-hidden="true"></i>
              </ng-template>
            </div>
            <div class="tcell-user" [ngbTooltip]="row.usersList[0]?.firstName + ' ' + row.usersList[0]?.surName">

              <span class="uname" 
                style="padding-right : 5px;">{{row.usersList[0]?.firstName}}</span>
              <span class="uname" >{{row.usersList[0]?.surName}}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="filterColumns[4].selected" name="Deployment" prop='deployment' [width]="230"
          [draggable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
            <div class="country-header">
              <span class="company"
                [ngbTooltip]="row.projectDeploymentTypeName">{{row.projectDeploymentTypeName}}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="filterColumns[5].selected" name="Status" prop='status' [width]="230"
          [draggable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
            <div class="country-header">
              <span class="company" [ngbTooltip]="row.projectStatusName">{{row.projectStatusName}}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="filterColumns[6].selected" name="Duration" prop='projectstartdate' [width]="350"
          style="margin-left:50px" [draggable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
            <div class="duration-cell-container">
              <div class="year">
                <span [ngbTooltip]="row.projStartDate">{{row.projStartDate}}</span>
                <span [ngbTooltip]="row.projEndDate">{{row.projEndDate}}</span>
              </div>
              <div class="year-progress">
                <div class="progress" [ngStyle]="{'width': row.projectPercentageCompletion*1 + '%'}"
                  [class.projectCompleted]="row.projectPercentageCompletion === 100"></div>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
          [resizeable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
            <button cursorTooltip [tooltipOptions]="{
          arrow: false,
          placement: 'bottom',
          followCursor: true,
          content: 'Project information'
          }" class="last-icon" (click)="openProjectInfo(row)">
              <i class="icon square-menu-icon" aria-hidden="true"></i>
            </button>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [sortable]="false" *ngIf="!filterColumns[0].selected && !filterColumns[1].selected &&
          !filterColumns[2].selected &&!filterColumns[3].selected &&
          !filterColumns[4].selected && !filterColumns[5].selected && !filterColumns[6].selected">
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
            let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
            <div class="page-count"></div>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </perfect-scrollbar>

    <div class="pagination">
      <pagination-controls id="pagination1" (pageChange)="pageChange($event)" previousLabel="Previous" nextLabel="Next">
      </pagination-controls>
    </div>



    <a class="item add-new" *ngIf="isSystemManagerSupportRole" routerLink="/project">
      <label>
        <i class="icon icon-add-new" aria-hidden="true"></i>
      </label>
      <span i18n>Create new project</span>
    </a>
  </div>

</div>