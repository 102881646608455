<div class="modal-container">
  <div class="modal-header">
    <span class="modal-title" i18n>Managing connection</span>
    <button type="button" class="close" (click)="closeModal()">
    </button>
  </div>
  <div class="modal-body">
    <label>Connection name</label>
    <input type="text" class="input-ctrl connection-name" [(ngModel)]="name"/>

    <table aria-hidden="true">
      <tr>
        <th id="idReference" i18n>Reference</th>
        <th id="idOrigin" i18n>Origin</th>
        <th id="id1">&nbsp;</th>

        <th id="idDestination" i18n>Destination</th>
        <th id="id1">&nbsp;</th>
        <th id="id2">&nbsp;</th>
      </tr>

      <tr *ngFor="let connection of localConnections" [class.hidden]="connection.removed">
        <td><input class="form-input" type="text" [value]="connection.reference"  [(ngModel)]="connection.reference"/></td>
        <td><input class="form-input substation" type="text" [value]="connection.origin" readonly /></td>
        <td>
          <button [disabled]="!connection.canExchange" (click)="swap(connection)" class="image-button"><i
              class="icon icon-refresh" aria-hidden="true"></i></button>
        </td>
 
        <td><input class="form-input substation" type="text" [value]="connection.destination" readonly /></td>
        <td [class.disabled]="connection.id === '00000000-0000-0000-0000-000000000000'">
          <button (click)="openAssetInfo(connection)" class="image-button"><i class="icon icon-file" aria-hidden="true"></i></button>

        </td>
        <td *ngIf="userRolesObject.GISSLDEditorEdit">
          <a (click)="remove(connection.id)" href="javascript:;" class="remove"></a>
        </td>
      </tr>
      <tr>
        <td colspan="6">
          <p>Add new connection</p>
        </td>
      </tr>
      <tr class="add-row" *ngIf="userRolesObject.GISSLDEditorEdit">
        <td><input class="form-input" type="text" [value]="addNewConnection.reference" /></td>
        <td><input class="form-input substation" type="text" [value]="addNewConnection.origin" /></td>
        <td>
          <button [disabled]="!addNewConnection.canExchange" (click)="swap(addNewConnection)" class="image-button"><i
              class="icon icon-refresh" aria-hidden="true"></i></button>
        </td>

        <td><input class="form-input substation" type="text" [value]="addNewConnection.destination" /></td>
        <td>&nbsp;</td>
        <td>
          <a (click)="add(addNewConnection)" href="javascript:;" class="add"></a>
        </td>
      </tr>
    </table>
  </div>
  <div class="modal-footer" *ngIf="userRolesObject.GISSLDEditorEdit">
    <button (click)="deleteAll()" type="button" class="btn btn-link" i18n>Delete connections</button>
    <button (click)="confirm()" type="submit" class="btn btn-secondary" i18n>Confirm</button>
  </div>

</div>
