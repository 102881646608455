<div class="modal-container">
  <div class="modal-header">
    <span *ngIf="edit" class="modal-title" i18n>Editing Load</span>
    <span *ngIf="!edit" class="modal-title" i18n>Creating Load</span>
    <button type="button" class="close" (click)="deleteMarker()">
    </button>
  </div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-body">
      <h2 class="h2-title" i18n>Basic</h2>
      <div class="row">
        <div class="col-lg-6 col-sm-12">
          <div class="form-wrapper">
            <label class="label-element" i18n><sup>*</sup>Load reference</label>
            <input [class.invalid]="validation('loadReference')" formControlName="loadReference" type="text"
              class="form-input" />
          </div>
        </div>
        <div class="col-lg-6 col-sm-12">
          <div class="form-wrapper">
            <label class="label-element" i18n>Substation reference</label>
            <input [class.invalid]="validation('substationReference')" formControlName="substationReference" type="text"
              class="form-input" />
          </div>
        </div>
      </div>
      <h2 class="h2-title" i18n>Technical</h2>
      <div class="row">
        <div class="col-lg-4 col-sm-12">
          <div class="form-wrapper">
            <label class="label-element" i18n>Type of load</label>
              <select  [class.invalid]="validation('typeOfLoad')" type = "text" formControlName="typeOfLoad" class="form-input" >
                <option *ngFor="let data of options;" [value]="data">{{data}}</option>
              </select>
          </div>
        </div>
        <div class="col-lg-4 col-sm-12">
          <div class="form-wrapper">
            <label class="label-element" i18n><sup>*</sup>Rated power (MW)</label>
            <input [class.invalid]="validation('ratedPower')" (keypress)="only_numbers(form.value['typeOfLoad'], $event)" formControlName="ratedPower" type="text"
              class="form-input" maxlength="5" appTwoDigitDecimaNumber ="1"/>
          </div>
        </div>
        <div class="col-lg-4 col-sm-12">
          <div class="form-wrapper">
            <label class="label-element" i18n>Rated power factor</label>
            <input [class.invalid]="validation('ratedPowerFactor')" (keypress)="only_numbers(form.value['typeOfLoad'], $event)" formControlName="ratedPowerFactor" type="text"
              class="form-input" maxlength="5" appTwoDigitDecimaNumber ="2"/>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-12" style='padding-left:0px'>
          <div class="form-wrapper" *ngIf="this.form.value['typeOfLoad'] =='Others'">
            <input type="text" class="form-input" formControlName="typeOfLoadEtc" />
          </div>
        </div>
    </div>
    <div class="modal-footer" *ngIf="userRolesObject.GISSLDEditorEdit">
      <button *ngIf="edit" (click)="deleteMarker()" type="button" class="btn btn-link" i18n>Delete Load</button>
      <button *ngIf="!edit" (click)="onSubmit()" type="button" class="btn btn-primary" i18n>Save</button>
      <button *ngIf="edit" type="submit" class="btn btn-primary" i18n>Edit Load</button>

    </div>
  </form>
</div>