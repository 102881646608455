<div class="modal-container">
  <div class="modal-header">
    <span class="modal-title" id="modal-basic-title">Delete {{deleteItemName}}</span>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('back')">
      <span aria-hidden="false">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <span *ngIf="message === '' && lastdeleteItemName == false" i18n>Are you sure you want to delete the {{deleteItemName}}?</span>
    <span *ngIf="message === '' && lastdeleteItemName == true" i18n>You are about to delete last outage in this outage area. There will be no outage to plan the task for the asset(s) belongs to this outage area. Are you sure you want to delete the outage?</span>
    <span *ngIf="message !== '' && lastdeleteItemName == false" i18n>{{message}}</span>
  </div>
  <div class="modal-footer">
    <a class="btn btn-delete" (click)="activeModal.close('cancel')" i18n>Cancel</a>
    <a class="btn btn-delete" (click)="activeModal.close('delete')" i18n>Delete</a>
  </div>
</div>
