import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationService } from 'src/services/application.service';
import { UserRole } from 'src/app/models/userrole';

@Component({
  selector: 'app-new-role-template',
  templateUrl: './new-role-template.component.html',
  styleUrls: ['./new-role-template.component.scss']
})
export class NewRoleTemplateComponent implements OnInit {

  roles: any = [];
  loading: boolean;
  @Input() id;
  @Input() title = 'Create new role';
  @Input() isPMCToBeAdded = false;
  rolesDescription = [
    {
      name: 'System Manager',
      description: [
        'Manage RelCareTM installed base',
        'Create and trigger new project delivery',
        'Manage and maintain default content',
      ]
    },    
    {
      name: 'Policy Manager',
      description: [
        'Define / refine maintenance actions',
        'Define / refine original SWMS',
        'Manage recommendations from execution'
        ]
    }, 
    {
      name: 'Project Member',
      description: [
        'Setup, configure, and manage project',
        'Manage users and roles',
        'Manage project communications',
      ]
    },
    {
      name: UserRole.ProjectManager,
      description: [
        'Setup, configure, and manage project',
        'Manage users and roles',
        'Manage project communications',
      ]
    },
    {
      name: 'Project Support',
      description: [
        'Setup, configure, and manage project',
        'Manage users and roles',
        'Manage project communications',

      ]
    },
    {
      name: 'System Support',
      description: [
        'Manage RelCareTM installed base',
        'Create and trigger new project delivery',
        'Manage and maintain default content',

      ]
    },
    {
      name: UserRole.PolicySME,
      description: [
        'Define / refine maintenance actions',
        'Define / refine original SWMS',
        'Manage recommendations from execution'
      ]
    },
    {
      name: 'Quality SME',
      description: [
        'Initiate continuous improvement task and assign preparation and execution Owners',
        'Validate continuous improvement tasks'
      ]
    },
    {
      name: 'Maintenance Manager',
      description: [
        'Define project constraints (outage time and budget)',
        'Approve/dispatch or reject plan'
      ]
    },
    {
      name: 'Planner',
      description: [
        'Schedule tasks and assign them to execution teams and assign validators',
        'Make the plan ready for approval and dispatching'

      ]
    },
    {
      name: 'Execution Team Lead',
      description: [
        'Assign task preparation/execution Owner',
        'Refine execution schedule',
        'Validate tasks or assign task validators']
    },
    {
      name: 'Execution Owner',
      description: [
        'Prepare tasks and fine tune SWMS',
        'Download tasks to tablet, execute them, and sync them back to web application'
      ]
    },
    {
      name: 'Operation SME',
      description: [
        'Create remote operation scenario',
        'Define operation steps'
      ]
    },
    {
      name: 'Remote Operator',
      description: [
        'Record operation request',
        'Analyze and execute operation scenario',
        'Send out the operation report'
      ]
    },
    {
      name: 'Project Manager (C)',
      description: [
        'Manage knowledge flow in to RelCareTM',
        'Manage customer interactions',
        'Monitor dashboards and widgets'
      ]
    },
    {
      name: 'Control Room',
      description: [        
        'View on-going RelCare projects (Internal & External)',
        'View imported IdentiQ sites',
        'Directly access project and installation files (view)',
        'Directly access projects (view)'
      ]
    }


  ];
  constructor(private appSvc: ApplicationService,
    public activeModal: NgbActiveModal) { }

  ngOnInit() {
    // this.spinnerService.show();
    this.loading = true;
    this.appSvc.getRolesinEditUser(this.id).subscribe({next:res => {
      this.loading = false;
      // this.spinnerService.hide();
      this.roles = res;
      if (this.isPMCToBeAdded === false) {
        for (let i = 0; i < this.roles.length; i++) {
          if (this.roles[i].roleName  === 'Project Manager (C)') {
            this.roles.splice(i,1);
          }
        }
      }

      this.roles = res.map(role => ({
        selected: false, roleName: role.roleName, roleId: role.roleId,
        rolePrivilegesSecurable: role.rolePrivilegesSecurable,assignDescriptions: "",eventSelected: false
      }));
      this.roles.forEach(ele => {        
        ele.assignDescriptions = this.rolesDescription.filter(role => role.name === ele.roleName)
      });
    }, error:() => {
      this.loading = false;
    }});
  }

  save() {
    this.activeModal.close(this.roles.filter(role => role['selected']));
  }

  selectedItemCount() {
    return this.roles.filter(role => role['selected']).length;
  }
}
