<div [class.line-chart-wrapper]="showTitleArea" class="line-chart-container">

  <div *ngIf="showTitleArea" class="title">
    <h1>{{settings.title}} (&#8486;) by years</h1>
    <div class="settings" [class.open]="settingsPopup">
      <a (click)="settingsPopup = !settingsPopup;" href="javascript:;"><i class="icon icon-setting" aria-hidden="true"></i></a>
      <div *ngIf="settingsPopup" class="setting-popup p-3">
        <h2>Chart customisation</h2>
        <div class="inner">
          <div class="row">
            <div class="col-md-12">
              <h3 class="d-flex flex-row align-items-center">
                <span class="mr-3 d-flex flex-grow-0 text-nowrap">Y axis</span>
                <span class="border-bottom d-flex w-100"></span>
              </h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>Chart title</label>
              <select (change)="renderChart()" [(ngModel)]="settings.title" type="text">
                <option value="NGR Resistance test">NGR Resistance test</option>
                <option value="NGR Resistance test2">NGR Resistance test2</option>
              </select>
            </div>
            <div class="col-md-6">
              <label>Custom Label</label>
              <input [(ngModel)]="settings.yCustomLabel" type="text"/>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label>Min value</label>
              <input (change)="renderChart()" [(ngModel)]="settings.yMin" type="number"/>
            </div>
            <div class="col-md-6">
              <label>Max value</label>
              <input (change)="renderChart()" [(ngModel)]="settings.yMax" type="number"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <h3 class="d-flex flex-row align-items-center">
                <span class="mr-3 d-flex flex-grow-0 text-nowrap">X axis</span>
                <span class="border-bottom d-flex w-100"></span>
              </h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>Chart title</label>
              <select (change)="renderChart()" [(ngModel)]="settings.chartTitle" type="text">
                <option value="Year">Year</option>
              </select>
            </div>
            <div class="col-md-6">
              <label>Custom label</label>
              <input (change)="renderChart()" [(ngModel)]="settings.xCustomLabel" type="text"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>Min value</label>
              <input (change)="renderChart()" [(ngModel)]="settings.xMin" type="number"/>
            </div>
            <div class="col-md-6">
              <label>Max value</label>
              <input (change)="renderChart()" [(ngModel)]="settings.xMax" type="number"/>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <h3 class="d-flex flex-row align-items-center">
                <span class="mr-3 d-flex flex-grow-0 text-nowrap">Line Segment</span>
                <span class="border-bottom d-flex w-100"></span>
              </h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>Chart title</label>
              <select (change)="renderChart()" [(ngModel)]="settings.lineSegmentTitle" type="text">
                <option value="Actual">Actual</option>
              </select>
            </div>
            <div class="col-md-6">
              <label>Custom label</label>
              <input (change)="renderChart()" [(ngModel)]="settings.segmentCustomLabel" type="text"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>Lower limit line</label>
              <input (change)="renderChart()" [(ngModel)]="settings.segmentLowerLimit" type="number"/>
            </div>
            <div class="col-md-6">
              <label>Uper limit line</label>
              <input (change)="renderChart()" [(ngModel)]="settings.segmentUpperLimit" type="number"/>
            </div>
          </div>

          <ng-container *ngFor="let item of settings.yAxis; let idx = index;">
            <div class="row">
              <div class="col-md-12">
                <h3 class="d-flex flex-row align-items-center">
                  <span class="mr-3 d-flex flex-grow-0 text-nowrap">Line Segment</span>
                  <span class="border-bottom d-flex w-100"></span>
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>Chart title</label>
                <div class="axis-input">
                  <div class="color" (click)="item.open = true;" [style.backgroundColor]="item.color">
                    <div *ngIf="item.open" class="color-pallet">
                      <div class="color-dots" *ngFor="let c of colors"
                           [class.active]="c === item.color"
                           (click)="selectColor($event, item, c)"
                           [style.backgroundColor]="c"></div>
                    </div>
                  </div>
                  <div class="value">{{item.value}}</div>
                  <a *ngIf="settings.yAxis.length > 1" (click)="settings.yAxis.splice(idx, 1); renderChart()" href="javascript:;" class="close"><i
                    class="icon icon-remove" aria-hidden="true"></i></a>
                </div>
              </div>
              <div class="col-md-6">
                <label>Custom label</label>
                <input (change)="renderChart()" [(ngModel)]="item.label" type="text"/>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="getYAxisValues().length > 0">
            <div class="row">
              <div class="col-md-12">
                <h3 class="d-flex flex-row align-items-center">
                  <span class="mr-3 d-flex flex-grow-0 text-nowrap">Line Segment</span>
                  <span class="border-bottom d-flex w-100"></span>
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div *ngIf="getYAxisValues().length > 0" [class.watermark]="selectedComboAxis === ''">
                  <select *ngIf="getYAxisValues().length > 0" placeholder="Select to add more" (change)="addYAxis()"
                          [(ngModel)]="selectedYAxis">
                    <option></option>
                    <option *ngFor="let item of getYAxisValues()" [value]="item">{{item}}</option>
                  </select>
                  <span *ngIf="selectedComboAxis === ''">Select to add more</span>
                </div>
              </div>
            </div>
          </ng-container>

        </div>
      </div>
    </div>
  </div>
  <div class="legends justify-content-start align-items-center">

    <div *ngIf="settings">
      <ng-container *ngFor="let item of settings.yProperties; let idx = index;">
        <div class="legend-item align-items-center mr-3">
          <span class="legend-border" [style.border-color]="item.color"></span>
          <span class="label">{{ item.name || item.label }}</span>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="line-chart" #resistanceChart ></div>

</div>
