<div *ngIf="message" class="modal-container">
  <div class="modal-header">
    <span class="modal-title" id="modal-basic-title">{{title}}</span>

  </div>
  <div class="modal-body">
    <span i18n>{{message}}</span>
  </div>
  <div class="modal-footer">
      <form (submit)="close()">
     <button type="submit" class="btn btn-cancel">OK
    </button>  </form>
  </div>
</div>