import { Substation } from './../../models/substation';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { ApplicationService } from 'src/services/application.service';
import * as RouteHelper from 'src/app/utility/routing.helper';
import { first } from 'rxjs/operators';
import { MainHeaderService } from 'src/app/components/main-header/main-header.service';
import { ConstService } from 'src/services/const.service';
import { OpCenterService } from 'src/services/op-center.service';
import { BackNavigationService } from 'src/services/back-navigation.service';
import { SuccesspopupComponent } from '../../containers/dashboard/modals/successpopup/successpopup.component';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { GoToProjectService } from 'src/services/go-to-project.service';

@Component({
  selector: 'app-information-explorer-side',
  templateUrl: './information-explorer-side.component.html',
  styleUrls: ['./information-explorer-side.component.scss']
})
export class InformationExplorerSideComponent implements OnInit {
  filterStyles: any = { button: 30, space: 50, search: 20 };
  @Input() data = null;
  @Input() installMainFilterList = null;
  @Input() installCompFilterList = null;
  @Input() installAllFilterListCount: number = null;
  @Input() projMainFilterList = null;
  @Input() projCompFilterList = null;
  @Input() projAllFilterListCount = null;
  @Input() hoverOverMarker = null;
  @Input() selectedRegion = null;
  @Input() onlySubstations = false;
  @Input() hoverOverAsset = null;
  @Output() substationHover = new EventEmitter();
  @Output() assetHover = new EventEmitter();
  @Output() tabChange = new EventEmitter();
  @Output() searchEnd = new EventEmitter();
  @Output() updateMap = new EventEmitter();
  @Output() updateFilters = new EventEmitter();
  @Output()  filterItemPassEmit = new EventEmitter();
  @Input() tabName;
  @Output() InstallationHover = new EventEmitter();
  @Output() projectHover = new EventEmitter();

  filterData: any;

  private _tab = 'Substations';
  fromNotification: any;
  public get tab() {
    return this._tab;
  }
  @Input()
  public set tab(value) {
    this.assetAssets = '';
    this.substationSearch = '';
    this.paginateData();
    this.paginateAssetData();
    this._tab = value;
  }
  @Input() onlyAssets = false;
  @Input() fromRelCenter = false;
  substations: any = [];
  filteredSub: any = [];
  filteredSubstations: any = [];
  assets: any = [];
  expand = false;
  substationIndex = 10;
  assetIndex = 10;
  projectIndex = 10;
  installationIndex = 10;
  Installations;
  hover = false;
  filterShow = false;
  substationSortBy = 'name';
  substationSearch = '';
  substationSortDirection = true;
  direction = 'ASC';
  substation1: any;
  installationdirection = "ASC";
  installationSortDirection = true;
  @Input() filterColumns = [
    { prop: 'name', name: 'Reference', selected: true },
    { prop: 'region', name: 'Continent', selected: true },
    { prop: 'riskValue', name: 'Risk', selected: true },
  ];
  @Input() InstallationfilterColumns = [
    { prop: 'installation', name: 'Installation', selected: true },
    { prop: 'project', name: 'Project', selected: true }
  ];
  loading = false;
  assetFilterShow = false;
  assetSortBy = 'name';
  assetAssets = '';
  assetSortDirection = true;
  projectSortDirection = true;
  projectSortBy = 'projectName';
  config;
  configProject;
  configInstallation;
  Projects;
  projectSearchText = '';
  installationSearchText = '';
  showItems = false;
  showicon = false;
  seeMoreclickProject = false;
  installationSortBy = 'installation';
  pageSizeOption = [10,20,50,100];
  assetFilterColumns = [
    { prop: 'name', name: 'SLD reference', selected: true },
    { prop: 'region', name: 'Region', selected: true },
    { prop: 'riskValue', name: 'Risk', selected: true },
  ];
  projectFilterColumns = [
    { prop: 'projectName', name: 'Project', selected: true },
    { prop: 'customerName', name: 'Customer', selected: true }
  ];
  constructor(public router: Router, private appSvc: ApplicationService, private route: ActivatedRoute,
    private mainHeaderService: MainHeaderService, private constService: ConstService,
    private opService: OpCenterService, private backnavigation:BackNavigationService,
    private goToProjectSvc: GoToProjectService, private modalService: NgbModal,) {
    this.config = {
      currentPage: 1,
      itemsPerPage: 20,
      totalItems: 20
    };
    this.configProject = {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 20
    };
    this.configInstallation = {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 20
    };
    this.opService.searchItem$.subscribe(value => {
      if(value !== ''){
        if(this._tab === 'Installations') {
        this.updateInstallationFilter(value);
        } else if(this._tab === 'Projects') {
          this.updateProjectFilter(value);
        }
      }
    });
  }

  ngOnInit() {
    if ((sessionStorage.getItem('currentRole') === 'System Manager' ||
      sessionStorage.getItem('currentRole') === 'System Support' || sessionStorage.getItem('currentRole') === 'Policy Manager')) {
      this.showicon = true;
    } 
    // this.filterData = this.appSvc.getfilterData();
    if (this._tab === 'Installations') {
      this.tab = this._tab;
      this.onlyAssets = true;
      this.reloadOnQueryParamChange();
      this.route.queryParams.subscribe(params => {
        this.fromNotification = params['fromNotification'];
        if (this.fromNotification === 'true') {
          this.fromRelCenter = true;
        }
      });
      this.loading = false;
      this.Installations = this.data.totalInstallations?.totalInstallations;
      this.Projects = this.data.totalProjects.totalProjects;
      this.configProject.totalItems = this.data.totalProjects.totalNoOfProjects;
      this.configInstallation.totalItems = this.data.totalInstallations.totalNoOfSubstations;
    } else {
      this.reloadOnQueryParamChange();
      this.route.queryParams.subscribe(params => {
        this.fromNotification = params['fromNotification'];
        if (this.fromNotification === 'true') {
          this.fromRelCenter = true;
        }
      });

      this.substations = this.data.substations;
      if (this.data.assets !== undefined && this.data.assets !== null) {
        this.assets = this.data.assets;
      }
      this.data = _.cloneDeep(this.data);
      if (this.tab === 'Assets' && this.onlyAssets === false) {
        this.expandModal();
      }
      if (this.route.snapshot.queryParamMap.get('openOnAssetsTab')) {
        this.tab = 'Assets';
      }
      this.leaveClickedNotificationsUnread();
      this.config.totalItems = sessionStorage.getItem('totalAsset');
      if (this.substations) {
        this.filteredSub = this.substations.filter(item => {
          return item.type === 'substation';
        });
        this.substation1 = this.filteredSub.map(x => x.props);
        this.filteredSubstations = this.substation1;
      }
    }
  }
  projectInformation(projectId) {
    this.router.navigate([`/viewProject/${projectId}`], {queryParams : {from:'Operations center'}});
  }

  installationInformation(item) {
    if(item.isExternal === "true"){
      const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
      modalRef.componentInstance.title = 'Message';
      modalRef.componentInstance.message = 'External projects cannot be accessed.';    
      return;
    }
    this.router.navigate([RouteHelper.addProjectPrefix(`/substationinfo`)],
    {queryParams: { substationId: item.installationId, classId: this.constService.substationClassId,
        equipmentName: this.constService.markerType, name: item.installation, isOpCenter: true, from: 'Operations center', country: item.country}, queryParamsHandling: 'merge'});
        sessionStorage.setItem('projectIdfromOpCenter', item.projectId);
        sessionStorage.setItem('projectNamefromOpCenter', item.project);
     }
     openTool() {
      if ((sessionStorage.getItem('currentRole') === 'System Manager' ||
      sessionStorage.getItem('currentRole') === 'System Support' || sessionStorage.getItem('currentRole') === 'Policy Manager')) {
      this.showicon = true;
    } else {
      this.showicon = false;
    }
     }

  gotoProject(id) {
    sessionStorage.setItem('projectId', id.projectId);
    if(id.projectName){
      if(id.projectType === 'External'){
        const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
        modalRef.componentInstance.title = 'Message';
        modalRef.componentInstance.message = 'External projects cannot be accessed.';    
        return;
      }
    sessionStorage.setItem('projectName', id.projectName?.replace(/\s/g, "").toLowerCase());
    }else {
      if(id.isExternal === "true"){
        const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
        modalRef.componentInstance.title = 'Message';
        modalRef.componentInstance.message = 'External projects cannot be accessed.';    
        return;
      }
      sessionStorage.setItem('projectName', id.project?.replace(/\s/g, "").toLowerCase());
    }
    this.goToProjectSvc.goToProject();
  }

  tabChangefn(tabName) {
    this.filterData = undefined;
    this.tab = tabName;
    if(this.tab == 'Installations') { 
      this.installationSearchText ='';
      this.getAllInstallationForOperationCenter();
    }else {
      this.projectSearchText = '';
      this.getAllProjectsForOperationCenter();
    }
  }
  pageSizeChangedInst(event){
    this.configInstallation.itemsPerPage = event.target.value;
    this.configInstallation.currentPage = 1;    
    this.getAllInstallationForOperationCenter();
  }
  pageSizeChangedProj(event){
    this.configProject.itemsPerPage = event.target.value;
    this.configProject.currentPage = 1;
    this.getAllProjectsForOperationCenter();
  }
  pageChange(newPage: number) {
    if (this._tab == 'Projects') {
      this.configProject.currentPage = newPage;
      this.getAllProjectsForOperationCenter();

    } else if (this._tab == 'Installations') {
      this.configInstallation.currentPage = newPage;
      this.getAllInstallationForOperationCenter();

    } else {
      this.config.currentPage = newPage;
      const substationId = sessionStorage.getItem('substationId');
      this.loading = true;
      setTimeout(() => {
        this.appSvc.GetAllForInformationExplorerBySubstation(substationId, this.config.currentPage, this.config.itemsPerPage, this.assetAssets, "", "")
          .subscribe({
            next: (data) => {
              this.loading = false;
              this.assets = data.returnObj.riskMatrix;
              this.data.assets = this.assets;
              this.config.totalItems = data.returnObj.totalNoOfData;
            }, error: () => { this.loading = false; }
          });
      }, 500);
    }
  }
  private leaveClickedNotificationsUnread() {
    if (this.appSvc.notificationId) {
      const notificationId = this.appSvc.notificationId;
      const notification = this.appSvc.notifications.find(x => x.notificationIds.includes(notificationId));
      if (notification) {
        for (const item of notification.assetIds)
          item.isRead = false;
        for (const item of notification.substationIds)
          item.isRead = false;
      }
      this.appSvc.notificationId = null;
    }
  }

  private reloadOnQueryParamChange() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  // update filter
  updateSubstationFilter(e) {
    this.substationSearch = e.target.value;
    this.paginateData();
  }


  update(e) {
    this.substationSearch = e.target.value;
    this.searchsub();
  }

  searchsub() {
    let items = _.cloneDeep(this.substation1);
    items = _.orderBy(items, [substation => substation.name.toLowerCase()], ['desc']);
    // items = _.sortBy(items, this.substationSortBy);
    if (!this.substationSortDirection) {
      items = _.reverse(items);
    }
    items = _.filter(items, this.filterSearch.bind(this));
    this.filteredSubstations = _.cloneDeep(items);
  }


  seeMoreProject() {
    this.seeMoreclickProject = true;
  }
  // update filter
  updateAssetFilter(e) {
    this.assetAssets = e.target.value;
    this.paginateAssetData();
  }
  updateProjectFilter(e) {
    if (e !== null) { this.projectSearchText = _.isString(e)? e: e.target.value;
    } else { this.projectSearchText = '';}
    this.getAllProjectsForOperationCenter();
  }
  updateInstallationFilter(e) {
    if (e !== null) { this.installationSearchText = _.isString(e)? e: e.target.value;
    }else { this.installationSearchText = '';}
    this.getAllInstallationForOperationCenter();
  }

  // page data
  paginateData() {
    let items = _.sortBy(this.filteredSubstations, this.substationSortBy);
    // items = _.sortBy(items, this.substationSortBy);
    if (!this.substationSortDirection) {
      items = _.reverse(items);
    }
    items = _.filter(items, this.filterSearch.bind(this));
    this.filteredSubstations = _.cloneDeep(items);
  }

  filterSearch(item) {
    if (this.substationSearch.trim() === '') {
      return true;
    }
    let matched = false;

    const toLowerCase = this.substationSearch.toLowerCase();
    _.each(this.filterColumns, (column) => {
      if (item[column.prop] && item[column.prop].toString().toLowerCase().indexOf(toLowerCase) > -1) {
        matched = true;
      }
    });

    return matched;
  }

  filterAssetsSearch(item) {
    if (this.assetAssets.trim() === '') {
      return true;
    }
    let matched = false;

    const toLowerCase = this.assetAssets.toLowerCase();
    _.each(this.assetFilterColumns, (column) => {
      if (item[column.prop] && item[column.prop].toString().toLowerCase().indexOf(toLowerCase) > -1) {
        matched = true;
      }
    });

    return matched;
  }

  paginateAssetData() {
    let items = _.cloneDeep(this.data?.assets);
    items = _.sortBy(items, this.assetSortBy);
    if (!this.assetSortDirection) {
      items = _.reverse(items);
    }
    items = _.filter(items, this.filterAssetsSearch.bind(this));
    this.assets = _.cloneDeep(items);
  }


  hoverOnSubstation(id, asset = null) {
    if (this.substationHover) {
      this.substationHover.emit(id);
    }
    if (this.assetHover) {
      this.assetHover.emit(asset);
    }
    this.hover = id !== null;
  }
  InstallationHo(id) {
    this.InstallationHover.emit(id)
  }
  projectHighlight(projectId) {
    this.projectHover.emit(projectId);
  }
  // sort
  sort(field) {
    if (this.substationSortBy === field) {
      this.substationSortDirection = !this.substationSortDirection;
    } else {
      this.substationSortDirection = true;
    }
    this.substationSortBy = field;
    this.paginateData();
  }

  // sort
  sortAssert(field) {
    if (this.assetSortBy === field) {
      this.assetSortDirection = !this.assetSortDirection;
    } else {
      this.assetSortDirection = true;
    }
    this.assetSortBy = field;
    this.paginateAssetData();
  }
  sortProjects(field) {
    if (this.projectSortBy === field) {
      this.projectSortDirection = !this.projectSortDirection;
    } else {
      this.projectSortDirection = true;
    }
    this.projectSortBy = field;
    if (this.projectSortDirection) {
      this.direction = "ASC"
    } else {
      this.direction = "DESC"
    }
    this.getAllProjectsForOperationCenter();
  }
  sortInstallation(field) {
    if (this.installationSortBy === field) {
      this.installationSortDirection = !this.installationSortDirection;
    } else {
      this.installationSortDirection = true;
    }
    this.installationSortBy = field;
    if (this.installationSortDirection) {
      this.installationdirection = "ASC";
    } else {
      this.installationdirection = "DESC";
    }
    this.getAllInstallationForOperationCenter();
  }
  openProjectInfo(item) {
    console.log(item);
    this.showItems = true;
  }
  getAllProjectsForOperationCenter() {
    this.loading = true;
    // this.filterData = this.appSvc.getfilterData();
    let filterList  = this.filterData?.filterDataList == undefined ? [] :   (this.mainFilterTotalCount(this.filterData?.filterDataList) == 0 ? [] : [this.filterData?.filterDataList]) ; 
    const data = {
      "pageNumber": this.configProject.currentPage,
      "pageSize": this.configProject.itemsPerPage,
      "searchText": this.projectSearchText,
      "sortOrder": this.direction,
      "sortColumn": this.projectSortBy.charAt(0).toUpperCase() + this.projectSortBy.slice(1),
      "startDate": "",
      "endDate": "",
      "filtersdata": filterList
    }
    this.appSvc.GetAllProjectsforOperationCenter(data).pipe(first()).subscribe({
      next: (res) => {
        this.Projects = res.returnObj.totalProjects;
        this.configProject.totalItems = res.returnObj.totalNoOfProjects;
        this.loading = false;
      }, error: () => {
        this.loading = false;
      }
    });
  }
  getAllInstallationForOperationCenter() {
    this.loading = true;
    // // this.filterData = this.appSvc.getfilterData();
    let filterList  = this.filterData?.filterDataList == undefined ? [] :   (this.mainFilterTotalCount(this.filterData?.filterDataList) == 0 ? [] : [this.filterData?.filterDataList]) ; 
    const data1 = {
      "projectId": "00000000-0000-0000-0000-000000000000",
      "pageNumber": this.configInstallation.currentPage,
      "pageSize": this.configInstallation.itemsPerPage,
      "searchText": this.installationSearchText,
      "sortOrder": this.installationdirection,
      "sortColumn": this.installationSortBy.charAt(0).toUpperCase() + this.installationSortBy.slice(1),
      "startDate": "",
      "endDate": "",
      "filtersdata": filterList
    }
    this.appSvc.GetAllInstallationsforInstalledBase(data1).pipe(first()).subscribe({
      next: (res) => {
        this.Installations = res.returnObj.totalInstallations;
        this.configInstallation.totalItems = res.returnObj.totalNoOfSubstations;
        this.loading = false;
      }, error: () => {
        this.loading = false;
      }
    });
  }
  expandModal() {
    this.expand = !this.expand;
    if (this.expand) {
      this.projectFilterColumns = [
        { prop: 'projectName', name: 'Project', selected: true },
        { prop: 'customerName', name: 'Customer', selected: true },
        { prop: 'country', name: 'OU', selected: true },
        { prop: 'startDate', name: 'Start date', selected: true },
        { prop: 'endDate', name: 'End date', selected: true },
      ];
      this.InstallationfilterColumns = [
        { prop: 'installation', name: 'Installation', selected: true },
        { prop: 'project', name: 'Project', selected: true },
        { prop: 'substationOwner', name: 'Owner', selected: true },
        { prop: 'substationOperator', name: 'Operator', selected: true },
        { prop: 'assetCount', name: 'N. of assets', selected: true },
        { prop: 'technology', name: 'Technology', selected: true }
      ];
      this.assetFilterColumns = [
        { prop: 'name', name: 'SLD reference', selected: true },
        { prop: 'region', name: 'Region', selected: true },
        { prop: 'substationName', name: 'Substation', selected: true },
        { prop: 'bay', name: 'Bay/branch', selected: true },
        { prop: 'type', name: 'Equipment type', selected: true },
        { prop: 'tech', name: 'Equipment category', selected: true },
        { prop: 'manufacturer', name: 'Manufacturer', selected: true },
        { prop: 'riskValue', name: 'Risk', selected: true },
      ];
    } else {
      this.projectFilterColumns = [
        { prop: 'projectName', name: 'Project', selected: true },
        { prop: 'customerName', name: 'Customer', selected: true }
      ];
      this.assetFilterColumns = [
        { prop: 'name', name: 'SLD reference', selected: true },
        { prop: 'region', name: 'Region', selected: true },
        { prop: 'riskValue', name: 'Risk', selected: true },
      ];
      this.InstallationfilterColumns = [
        { prop: 'installation', name: 'Installation', selected: true },
        { prop: 'project', name: 'Project', selected: true },
      ];
    }
  }

  shouldDisplaySubstationNotificationSignal(substationId) {
    return this.appSvc.notifications.some(x => x.substationIds.some(t => t.id == substationId && !t.isRead))
  }

  clearSubstationNotificationSignal(substationId) {
    let notification = [].concat.apply([], this.appSvc.notifications.map(x => x.substationIds)).filter(x => x.id == substationId && !x.isRead)
    for (let not of notification)
      not.isRead = true;
    // if(notification.length > 0)
    // this.updateMap.emit();
  }
  shouldDisplayAssetNotificationSignal(assetId) {
    return this.appSvc.notifications.some(x => x.assetIds.some(t => t.id == assetId && !t.isRead))
  }

  clearAssetNotificationSignal(assetId) {
    let notification = [].concat.apply([], this.appSvc.notifications.map(x => x.assetIds)).filter(x => x.id == assetId && !x.isRead)
    for (let not of notification)
      not.isRead = true;
  }

  goToAssetInfo(id) {
    sessionStorage.setItem('assetId', id);
    sessionStorage.setItem('fromRel', 'false');
    this.router.navigateByUrl(RouteHelper.addProjectPrefix(`/assets/2/true`));
  }
  setTotalProjects(totalProjects) {    
    this.Projects = totalProjects.totalProjects;
    this.configProject.totalItems = totalProjects.totalNoOfProjects;
  }
  setTotalInstallations(totalInstallations) {
    this.Installations = totalInstallations.totalInstallations;
    this.configInstallation.totalItems = totalInstallations.totalNoOfSubstations;
  }
  applyFilterDataSpareGroup(event) {
    if(this.tab == 'Projects')
    this.configProject.currentPage = 1;
    else if(this.tab == 'Installations')
    this.configInstallation.currentPage = 1;
    // this.updateMap.emit();
    this.updateFilters.emit(this.tab);
  }
  mainFilterTotalCount(req){
    let flag = 0;
    Object.keys(req).forEach(key =>{
      flag = flag + req[key].length;
    });
return flag;
} 
  expandFilterView(event) {
    this.expandModal();
  }
  filterItemEmit(event) {
    this.filterData = event;
    this.filterItemPassEmit.emit(event);

  }
}
