import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { SuccesspopupComponent } from '../app/containers/dashboard/modals/successpopup/successpopup.component';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
// const API_GET = environment.apiURL;
import { SettingsService } from './settings.service';
import tokens from '../assets/token.json';
import { KeycloakService } from 'keycloak-angular';
const acesstoken = 'Bearer ' + tokens.token;
import * as RouteHelper from 'src/app/utility/routing.helper';

@Injectable()
export class ManageProjectService {
  response: any;
  count = 0;
  mpurl: any;
  APIurl = 'from direct assignment';
  constructor(
    private http: HttpClient, private activatedRoute: ActivatedRoute,
    private router: Router, private modalService: NgbModal, config: NgbModalConfig,
    mysettingsService: SettingsService,private keycloak:KeycloakService
  ) {
    config.backdrop = 'static';
    this.mpurl = mysettingsService.settings.manageProjectApiURL;
    this.APIurl = mysettingsService.settings.managesystemApiReportURL;
    const token = sessionStorage.getItem('RefershToken');
    if (this.router.url === '/' && token === null) {
     // sessionStorage.setItem('AccessToken', acesstoken);
    }

  }

  /**
  * Performs a request with `get` http method.
  * @param url the url
  * @param options the request options
  * @returns Observable<any>
  */
  get(url: string, options?: any): Observable<any> {
    return this.http
      .get(this.mpurl + url, this.requestOptions(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }

  getLogIn(url: string, options?: any): Observable<any> {
    return this.http
      .get(this.mpurl + url, this.requestOptionsLogIn(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }
  // getUrl(url: string, options?: any): Observable<any> {
  //   const API = this.APIurl;
  //   return this.http
  //     .get(API + url, this.requestOptionsReport(options))
  //     .pipe(catchError(err => this.catchAuthError(err)));
  // }
  /**
    * Performs a request with `post` http method.
    * @param url the url
    * @param data the Post request Payload
    * @param options the request options
    * @returns {Observable<any>}
    **/
   getFile(url: string, options?: any): Observable<any> {
    const API = this.mpurl;
    // if (url.split("/")[1] === "assets") {
    //   API = API_URL1;
    // }
    return this.http
      .get(API + url, this.requestOptionGet(options, '',true))
      .pipe(catchError(err => this.catchAuthError(err)));
  }
  post(url: string, data: any, options?: any): Observable<any> {
    // return this.http.post(API_URL + url, data, this.requestOptions(options));
    return this.http.post(this.mpurl + url, data, this.requestOptions(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }
  /**
  * Performs a request with `post` http method.
  * @param url the url
  * @param PutData the Post request Payload
  * @param options the request options
  * @returns {Observable<any>}
  **/

  put(url: string, PutData: any, options?: any): Observable<any> {
    return this.http.put(this.mpurl + url, PutData, this.requestOptions(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }

  /**
  * Performs a request with `patch` http method.
  * @param url the url
  * @param PatchData the Patch request Payload
  * @param options the request options
  * @returns {Observable<any>}
  **/

  patch(url: string, PatchData: any, options?: any): Observable<any> {
    // return this.http.patch(API_URL + url, PatchData, this.requestOptions(options));
    return this.http.patch(this.mpurl + url, PatchData, this.requestOptions(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }

  delete(url: string, options?: any): Observable<any> {
    return this.http.delete(this.mpurl + url, this.requestOptions(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }

  // getCurrentRole() {
  //   const roleselected = sessionStorage.getItem('currentRoleSelected');
  //   const currentRole = sessionStorage.getItem('currentRole');
  //   if(roleselected === null) {
  //     return currentRole;
  //   } else {
  //     return roleselected;
  //   }
  // }
  /**
   * Request options.
   * @param method the method
   * @returns RequestOptionsArgs
   */
  private requestOptions(options?: any, method?: string): any {
    if (!options) {
      options = {};
    }
    const roleselected = sessionStorage.getItem('currentRoleSelected');
    const currentRole = sessionStorage.getItem('currentRole');
    const headers = new HttpHeaders(Object.assign({
      'Authorization': sessionStorage.getItem('AccessToken'),
      'ProjectId' : RouteHelper.getProjectIdForAPIHeader(),
       'RoleName' : roleselected || currentRole || ''
    }, options.headers));
    options.headers = headers;
    return options;
  }

  private requestOptionsLogIn(options?: any, method?: string): any {
    if (!options) {
      options = {};
    }
    const headers = new HttpHeaders(Object.assign({
      'Authorization': sessionStorage.getItem('AccessToken'),
      'ProjectId' : RouteHelper.getProjectIdForAPIHeader()
    }, options.headers));
    options.headers = headers;
    return options;
  }

  private requestOptionsGet(options?: any, method?: string): any {
    if (!options) {
      options = {};
    }
    // if (options.headers || method === 'put' || method === 'post') {
    const headers = new HttpHeaders({
      // 'Content-Type': 'application/json'
      'Authorization': sessionStorage.getItem('AccessToken'),
    });
    options.headers = headers;
    // }
    return options;
  }
  // private requestOptionsReport(options?: any, method?: string): any {
  //   if (!options) {
  //     options = {};
  //   }
  //   const headers = new HttpHeaders({
  //     'Authorization': acesstoken,
  //   });
  //   options.headers = headers;
  //   return options;
  // }
  private requestOption(options?: any, method?: string, isFile?: boolean): any {
    if (!options) {
      options = {};
    }
    const roleselected = sessionStorage.getItem('currentRoleSelected');
    const currentRole = sessionStorage.getItem('currentRole');
    // if (options.headers || method === 'put' || method === 'post') {
    const headers = new HttpHeaders({
      'Authorization': sessionStorage.getItem('AccessToken'),
      'ProjectId' : RouteHelper.getProjectIdForAPIHeader(),
      'RoleName' : roleselected || currentRole || ''
    });
    options.headers = headers;
    if (isFile) {
      options.responseType = 'arraybuffer';
    }
    // }
    return options;
  }

  private requestOptionGet(options?: any, method?: string, isFile?: boolean): any {
    if (!options) {
      options = {};
    }
    // if (options.headers || method === 'put' || method === 'post') {
    const headers = new HttpHeaders({
      'Authorization': sessionStorage.getItem('AccessToken'),
      'ProjectId' : RouteHelper.getProjectIdForAPIHeader(),
    });
    options.headers = headers;
    if (isFile) {
      options.responseType = 'arraybuffer';
    }
    // }
    return options;
  }
  /**
   * catches the auth error
   * @param error the error response
   */
  catchAuthError(error): Observable<Response> {
    // this.spinnerService.hide(); // hide the spinner if success
    if (error.status === 409) {
      const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
      if (error.error.errorMessage) {
        modalRef.componentInstance.title = 'Warning';
        modalRef.componentInstance.message = error.error.errorMessage;
      }
      return throwError(() => new Error(error));

    } else if (error.status === 400) {
      const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
      if (error.error.errorMessage) {
        modalRef.componentInstance.title = 'Warning';
        modalRef.componentInstance.message = error.error.errorMessage;
      } else if (error.error.message) {
        if (error.error.message === 'Validation Failed') {
          modalRef.componentInstance.title = 'Warning';
          modalRef.componentInstance.message = error.error.errors[0].message;
        }
      } else if ( error.error.errors){
        Object.keys(error.error.errors).forEach(key => {
          const value = error.error.errors[key];
          modalRef.componentInstance.title = 'Warning';
          modalRef.componentInstance.message = value;
        });
      } else {
        if (typeof (error.error) === 'object') {
          Object.keys(error.error).forEach(key => {
            const value = error.error[key];
            modalRef.componentInstance.title = 'Warning';
            modalRef.componentInstance.message = value;
          });
        } else {
          modalRef.componentInstance.title = 'Warning';
          modalRef.componentInstance.message = error.error;
        }
      }
      return throwError(() => new Error(error));

    } else if (error.status === 404) {
      if (error.error && error.error.errorMessage) {
        const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
        modalRef.componentInstance.title = 'Message';
        modalRef.componentInstance.message = error.error.errorMessage;
      }
      return throwError(() => new Error(error));

    } else if (error.status === 422) {
      const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
      if (error.error.errorMessage) {
        modalRef.componentInstance.title = 'Warning';
        modalRef.componentInstance.message = error.error.errorMessage;
      } else {
        modalRef.componentInstance.title = 'Warning';
        modalRef.componentInstance.message = 'Unprocessable Entity';
      }
      return throwError(() => new Error(error));
    }
    if (error.status === 401) {

      sessionStorage.clear();
      localStorage.clear();
      // this.modalService.dismissAll();
      // this.router.navigate(['/login'], { queryParams: { order: 'popular' } });
      if (this.router.url.includes('/project-settings')) {
        this.keycloak.logout(window.location.origin+'/login').then(() => this.keycloak.clearToken());

      } else if (this.router.url.includes('/task-criterias')) {
        this.keycloak.logout(window.location.origin+'/login').then(() => this.keycloak.clearToken());
      } else {
        this.modalService.dismissAll();
        this.keycloak.logout(window.location.origin+'/login').then(() => this.keycloak.clearToken());

      }
      return throwError(() => new Error(error));



    } else if (error.status === 403) {
      return throwError(() => new Error(error));
    } else {
      if (error.status !== undefined && error.status !== null) {
      const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
      modalRef.componentInstance.title = 'Message';
      modalRef.componentInstance.message = 'An error occurred please try again later';
      return throwError(() => new Error(error));
      }
    }
    // return throwError(error);
  }
}
