import { Pipe, PipeTransform } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
 
@Pipe({
  name: 'DecimalDelimiter'
})
export class DecimalDelimiterPipe implements PipeTransform {
 
  constructor(private appSvc: ApplicationService) {
  }
 
  public transform(value: any, decimal: any): any {
    return value.toFixed(2).toString().replace('.', decimal);
  }
}