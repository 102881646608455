<div class="modal-container">
  <div class="modal-header">
    <span class="modal-title" id="modal-basic-title">RISE to RelCare import - unmappable assets</span>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('back')">
      <span aria-hidden="false">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="message" i18n>It looks like you are importing a substation from RISE application.</div>
    <div class="message" i18n>Some assets will not be imported as they could not be mapped to an equipment in the RelCare library.</div>

    <div class="main-section">
      <div class="image-container" #imageContainerRef>
        <img [src]="sldImage" #sldImageRef (load)="onImageLoaded()" alt="SLD Image">
        <div class="red-overlay" [class.selected]="asset === selectedAsset"  *ngFor="let asset of unmappedAssets"
          [style.left]="asset.overlayLeft + 'px'" [style.top]="asset.overlayTop + 'px'"
          [style.width]="asset.overlayWidth + 'px'" [style.height]="asset.overlayHeight + 'px'">
        </div>
      </div>

      <div class="asset-list">
        <div class="asset" *ngFor="let asset of unmappedAssets"
          [class.active]="selectedAsset === asset" (click)="onAssetSelected(asset)">
          <div class="asset-reference">{{ asset.goJSNodeData.sldReference }}</div>
          <div><span class="label">Name: </span> <span>{{ asset.equipmentDetails?.equipmentName }}</span></div>
          <div><span class="label">Category: </span> <span>{{ asset.equipmentDetails?.equipmentCategoryName }}</span></div>
          <div><span class="label">Type: </span> <span>{{ asset.equipmentDetails?.equipmentTypeName }}</span></div>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <a class="btn btn-cancel" (click)="activeModal.close(false)" i18n>Cancel import</a>
    <a class="btn btn-sure" (click)="activeModal.close(true)" i18n>Ok</a>
  </div>

  <canvas #bitmapCheckerCanvas></canvas>
</div>
