<div class="loading-box" *ngIf="loading">
    <div class="img" alt="loading"></div>
  </div>
  <div class="modal-container" style="width: 155%;
  background-color: white; margin-left: -91px;">
    <div class="modal-header">
      <span class="modal-title" id="modal-basic-title" i18n>{{title}}</span>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('back')">
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="form">
        <div class="back col-sm-4">
          <i class="icon icon-back" *ngIf="!isNext" (click)="previous()" aria-hidden="true"></i>
          <h5>Tenant details</h5>
        </div>
         <div class="row" *ngIf="!isNext">
          <div class="col-sm-6">
            <div class="form-wrapper">
              <label class="label-element" i18n>Secret key</label>
              <div class="select-wrapper" style="position: relative; cursor: default;">
                <span>Secret key</span>
                <input #input [class.invalid]="validation('secretKey')" type="password" formControlName="secretKey"
                 class="form-input" maxlength="50" />
              </div>
              <div [class.show]="validation('secretKey')" class="error-message" i18n>Secret key is required.</div>
              <div [class.show]="validation('secretKey', 'pattern')" class="error-message" i18n>Secret key must contain at least 10 characters, 1 numeric, 1 upper case letter, and 1 special character($@$!%*?&).</div>
            </div>
          </div>
         </div>

        <div class="row" *ngIf="isNext">
          <div class="col-sm-6">
            <div class="form-wrapper">
              <label class="label-element" i18n>Tenant name</label>
              <div class="select-wrapper" style="position: relative; cursor: default;">
                <span>Tenant name</span>
                <input [class.disabled]="title === 'Edit tenant'" #input [class.invalid]="validation('tenantName')" type="text" formControlName="tenantName"
                (keypress)="alphaNumericOnly($event)" class="form-input" maxlength="50" (focusout)="focusOutFunction()"/>
              </div>
              <div [class.show]="validation('tenantName')" class="error-message" i18n>Tenant name is required.</div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-wrapper">
              <label class="label-element" i18n>RelCare report client secret</label>
              <div class="select-wrapper" style="position: relative; cursor: default;">
                <span>RelCare report client secret</span>
                <input #input (focus)="isInputFieldTouched($event,'clientIdandSecret')" 
                  (blur)="isInputFieldTouched($event,'clientIdandSecret')" 
                  (change)="changeInput($event,'clientIdandSecret')"
                  [placeholder]="(title === 'Edit tenant' && !isClientIdandSecret) ? '●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●' : ''"
                  [class.edit]="title === 'Edit tenant'"
                  [class.invalid]="validation('clientIdandSecret')" type="text" formControlName="clientIdandSecret"
                  class="form-input" maxlength="50" (focusout)="focusOutFunction()"/>
              </div>
              <div [class.show]="validation('clientIdandSecret')" class="error-message" i18n>RelCare report client secret is required.</div>
            </div>
          </div>
         </div>
        <div class="row" *ngIf="isNext">        
          <div class="col-sm-6">
            <div class="form-wrapper">
              <label class="label-element" i18n>DB connection string</label>
              <div class="select-wrapper" style="position: relative; cursor: default;">
                <span>DB Connection String</span>
                <textarea (focus)="isInputFieldTouched($event, 'dBconnectionString')" 
                 (blur)="isInputFieldTouched($event, 'dBconnectionString')"
                 (change)="changeInput($event, 'dBconnectionString')"
                 [placeholder]="(title === 'Edit tenant' && !isDBconnectionString) ? '●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●' : ''"
                 [class.edit]="title === 'Edit tenant'"
                 [class.invalid]="validation('dBconnectionString')" formControlName="dBconnectionString" class="areaHeight input-ctrl form-input"
                 maxlength="2000"></textarea>
              </div>
              <div [class.show]="validation('dBconnectionString')" class="error-message" i18n>DB connection string is required.</div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-wrapper">
              <label class="label-element" i18n>Azure storage connection string </label>
              <div class="select-wrapper" style="position: relative; cursor: default;">
                <span>Azure Storage Connection String </span>
                <textarea (focus)="isInputFieldTouched($event, 'azureStorageConnectionString')" 
                 (blur)="isInputFieldTouched($event, 'azureStorageConnectionString')"
                 (change)="changeInput($event, 'azureStorageConnectionString')"
                 [placeholder]="(title === 'Edit tenant' && !isAzureStorageConnectionString ) ? '●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●' : ''"
                 [class.edit]="title === 'Edit tenant'"
                 [class.invalid]="validation('azureStorageConnectionString')" formControlName="azureStorageConnectionString" class="areaHeight input-ctrl form-input"
                 maxlength="2000"></textarea>
              </div>
              <div [class.show]="validation('azureStorageConnectionString')" class="error-message" i18n>Azure storage connection string is required.</div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="isNext">        
          <div class="col-sm-6">
            <div class="form-wrapper">
              <div class="select-wrapper" style="position: relative; cursor: default;">
                <span>Is System environment?</span>
                <input [class.disabled]="title === 'Edit tenant'" type="checkbox" formControlName="isSystemEnvironment">    
                <label style="padding-left:7px;" class="label-element" i18n>Is System environment?</label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
 
    <div class="modal-footer">
      <button *ngIf="isNext" (click)="next()" type="submit" class="btn btn-primary" i18n>Next</button>
      <button *ngIf="!isNext" (click)="onSubmit()" type="submit" class="btn btn-primary" i18n>Save</button>
    </div>
  </div>
  