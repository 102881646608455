<div class="loading-box"  *ngIf="loading">
  <div class="img"  alt="loading"></div>
</div>
<div class="container-fluid" id="pdfTable" #pdfTable>
  <div class="row">
    <div class="col-md-3 pr-md-0">
      <button class="status-button">
        <span>Tenant Details</span></button>
    </div>

    <div class="col-md-9">
      <div class="table-wrapper">
        <ng-container [ngTemplateOutlet]="planTable"></ng-container>
        <ng-template #planTable>
          <form style="display: flex;
            margin-bottom: 12px;
            float: right;" (submit)="changeSearch(search)">
            <div class="col-sm-4">
              <input [(ngModel)]="search" name="search" type="search" (keyup)="noSearch()" class="input-ctrl"
                style="width: 200px;" placeholder="Search" />
              <div style="margin-left: 170px;
                    margin-top: -23px;">
                <a type="submit"><img class="image" src="../../../assets/i/search.svg" alt="Search"
                    (click)="changeSearch()" width="18" height="18">
                </a>
              </div>
            </div>
          </form>
          <table class="base-table" aria-hidden="true">
            <perfect-scrollbar style="height: calc(100vh - 310px);">
            <tr>
              <th id="id25" (click)="sort('tenantName')" *ngIf="filterColumns[0].selected">Tenant name
                <i class="default-sort-btn" aria-hidden="true"
                  [class.sort-asc]="this.sortDirection && (this.sortColumn === 'tenantName')"
                  [class.sort-desc]="!this.sortDirection && (this.sortColumn === 'tenantName')"></i>
              </th>
              <th id="id26" (click)="sort('tenantLink')" *ngIf="filterColumns[1].selected">Tenant url
                <i class="default-sort-btn" aria-hidden="true"
                  [class.sort-asc]="this.sortDirection && (this.sortColumn === 'tenantLink')"
                  [class.sort-desc]="!this.sortDirection && (this.sortColumn === 'tenantLink')"></i>
              </th>
              <th id="id29" (click)="sort('jobStatus')" *ngIf="filterColumns[2].selected">Job status
                <i class="default-sort-btn" aria-hidden="true"
                  [class.sort-asc]="this.sortDirection && (this.sortColumn === 'jobStatus')"
                  [class.sort-desc]="!this.sortDirection && (this.sortColumn === 'jobStatus')"></i>
              </th>
              <th id="id30">
                <div style="width:215px;" class="filter-container" [ngClass]="{'filter-hide':!filterShow}">
                  <div class="header">
                    <i class="icon icon-filter" aria-hidden="true" (click)="filterShow=!filterShow"
                      [ngClass]="{'checked':!filterShow}"></i>
                    <span i18n>Column filter</span>
                  </div>
                  <div class="filter-option" *ngFor="let item of filterColumns;let i = index"
                    (click)="item.selected=!item.selected;">
                    <i class="icon icon-checkbox" aria-hidden="true" [ngClass]="{'checked':item.selected}"></i>
                    <span>{{item.name}}</span>
                  </div>
                </div>
              </th>
            </tr>
            <tr *ngFor="let item of tenantDetails | paginate: configPage;">
              
              <td *ngIf="filterColumns[0].selected">{{item.tenantName}}</td>
              <td *ngIf="filterColumns[1].selected">{{item.tenantLink}}</td>
              <td *ngIf="filterColumns[2].selected">{{item.jobStatus}}</td>
              <td>
                <label [for]="'all-user-' + item.tenantId" class="checkbox-wrap">
                  <input [id]="'all-user-' + item.tenantId" type="checkbox" [checked]="item.isSelected"
                    (change)="onCheckboxChangeFn($event, item)">
                  <i class="icon icon-checkbox" aria-hidden="true" [ngClass]="{'checked': item.isSelected}"></i>
                </label>
              </td>
            </tr>
          </perfect-scrollbar>
            <tr>
              <td class="centerAlign" *ngIf="tenantDetails?.length == 0" colspan="9">No Records found</td>
            </tr>
          </table>
        </ng-template>
        <app-add-new type="light" (click)="add()">Add new</app-add-new>

        <pagination-controls id="pagination1" (pageChange)="pageChangePlan($event)" class="pagination-template-1">
        </pagination-controls>

        <div class="button-wrapper">
          <div class="row">
            <div class="col-sm-12">
              <div class="buttons">
                <button *ngIf="selected.length == 1" (click)="editTenant()" class="btn btn-secondary">Edit
                </button>
                <button *ngIf="selected.length == 1" (click)="deleteTenant(selected)" class="btn btn-primary">Delete
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>