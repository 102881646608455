import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SettingsService } from "./settings.service";
import { Settings } from "src/app/settings";


@Injectable({ providedIn: 'root' })
export class SettingsHttpService {

    constructor(private http: HttpClient, private settingsService: SettingsService) {
    }

    async initializeApp(): Promise<any> {
        this.http.get('assets/settings.json').subscribe(response => {
             let tenantUrl = JSON.parse(JSON.stringify(response));
             if(tenantUrl.IsFromSettings === "false") {
              tenantUrl.managesystemApiURL = 'https://'+ sessionStorage.getItem('realmName') + '.'+ tenantUrl.domain +'/ManageSystem';
              tenantUrl.manageProjectApiURL = 'https://'+ sessionStorage.getItem('realmName') + '.'+ tenantUrl.domain +'/ManageProject';
              tenantUrl.SLDApiURL = 'https://'+ sessionStorage.getItem('realmName') + '.'+ tenantUrl.domain +'/SldService';
              tenantUrl.loadDataApiURL = 'https://'+ sessionStorage.getItem('realmName') + '.'+ tenantUrl.domain +'/LoadData';
              tenantUrl.tenantAdminAPIUrl ='https://' +sessionStorage.getItem('realmName') + '.'+ tenantUrl.domain +'/TenantAdmin';
              tenantUrl.auditApiURL = 'https://'+ sessionStorage.getItem('realmName') + '.'+ tenantUrl.domain +'/AuditApi';
              tenantUrl.operationCenter = 'https://'+sessionStorage.getItem('realmName')+'.'+tenantUrl.domain+'/InstBase';
              tenantUrl.exaURL = tenantUrl.manageProjectApiURL+'/SWMS'; 
              this.settingsService.settings = <Settings>tenantUrl;
             }else {
                this.settingsService.settings = <Settings>tenantUrl;
             }
          });
          return await this.http.get('assets/settings.json').toPromise();
              }
                          }