<div class="loading-box" *ngIf="loading">
  <div class="img" alt="loading"></div>
</div>

<div class="page-container">
  <header class="header-container">
    <div class="header-item header-mid">
      <h3 i18n> Installed base </h3>
    </div>
  </header>
</div>

<div class="nav-container">
  <ul ngbNav #nav="ngbNav" (navChange)="tabChanged($event)" [(activeId)]="active" class="nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink (click)="filterShow = false">Assets</a>
      <ng-template ngbNavContent>
        <div class="assets-listing">       
          <div class="main_div">       
            <ng-container ng-diable="disable">         
              <div class="search-wrapper">           
                <input type='text' class="input-ctrl search-ctrl" placeholder='Type to search' [(ngModel)]="filterStrAssets"
                  (keyup)="search($event.target.value)" />   
                <csp-common-filter class="filter2" [filterOptions]="assetMainFilterList"
                  [multipleFilterOptions]="assetCompFilterList" [isFullScreen]="true"
                  [totalOptionLength]="assetAllFilterListCount" [isPopup]="filterAPICall"
                  (filterItemEmit)="filterItemEmit($event)" [filterStyles]="filterStyles"
                  [filterAPICallType]="'Assets'"
                  [projectId]="projectId">
                </csp-common-filter>     
              </div>       
            </ng-container> 

            <div class="filter-container" [ngClass]="{'filter-hide':!filterShow}">
              <div class="header">
                <i class="icon icon-filter" aria-hidden="true" (click)="filterShow=!filterShow"
                  [ngClass]="{'checked':!filterShow}"></i>
                <span i18n>Column filter</span>
              </div>
              <div class="filter-option" *ngFor="let item of filterColumnsAssets;let i = index"
                (click)="item.selected=!item.selected;">
                <i class="icon icon-checkbox" aria-hidden="true" [ngClass]="{'checked':item.selected}"></i>
                <span>{{item.name}}</span>
              </div>
            </div>
            <perfect-scrollbar fxFlex="auto" [scrollIndicators]="true" class="scrollbar">
              <ngx-datatable #assetsTable [rows]="assetsData | paginate: configPlan1" [count]="configPlan1.totalItems"
                 [offset]="configPlan1.currentPage"  [limit]="configPlan1.itemsPerPage"
                 (page)="pageChangeAssets($event)"             [columnMode]="'force'"  [rowHeight]="52"
                (sort)="onSortAssets($event)" (activate)="onRowClick($event)">
               <ngx-datatable-column *ngIf="filterColumnsAssets[0].selected" name="SLD reference" prop='SLDReference'
                  [draggable]="false">       
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index"> 
                    <div class="country-header">
                      <span class="td_assets" [ngbTooltip]="row.sldReference">{{row.sldReference}}</span>
                    </div>                
                  </ng-template>     
                </ngx-datatable-column>     

                <ngx-datatable-column *ngIf="filterColumnsAssets[1].selected" name="Serial number" prop='SerialNumber'
                  [draggable]="false">       
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index"> 
                    <div class="country-header">
                      <span class="td_assets" [ngbTooltip]="row.serialNumber">{{row.serialNumber}}</span>
                    </div>                
                  </ng-template>     
                </ngx-datatable-column>   

                <ngx-datatable-column *ngIf="filterColumnsAssets[2].selected" name="Installation" prop='Installation'
                  [draggable]="false">       
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">    
                    <div class="country-header">
                      <span class="td_assets" [ngbTooltip]="row.installation">{{row.installation}}</span>
                    </div>             
                  </ng-template>     
                </ngx-datatable-column>    

                <ngx-datatable-column *ngIf="filterColumnsAssets[3].selected" name="Technology" prop='Technology'
                  [draggable]="false" [resizeable]="false">       
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">    
                    <div class="country-header">
                      <span class="td_assets" [ngbTooltip]="row.technology">{{row.technology}}</span>
                    </div>             
                  </ng-template>     
                </ngx-datatable-column>   

                <ngx-datatable-column *ngIf="filterColumnsAssets[4].selected" name="Equipment type" prop='EqptType'
                  [draggable]="false" style="width : 100px !important">       
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
                    <div class="country-header">
                      <span class="td_assets" [ngbTooltip]="row.eqptType">{{row.eqptType}}</span>
                    </div>
                  </ng-template>       
                </ngx-datatable-column>

                <ngx-datatable-column *ngIf="filterColumnsAssets[5].selected" name="Bay /Branch" prop='BayBranch'
                  [draggable]="false">       
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
                    <div class="country-header">
                      <span class="td_assets" [ngbTooltip]="row.bayBranch">{{row.bayBranch}}</span>
                    </div>                 
                  </ng-template>     
                </ngx-datatable-column>

                <ngx-datatable-column *ngIf="filterColumnsAssets[6].selected" name="Voltage [kV]" prop='Voltage'
                  [draggable]="false">       
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
                    <div class="country-header">
                      <span class="td_assets" [ngbTooltip]="row.voltage">{{row.voltage}}</span>
                    </div>                 
                  </ng-template>     
                </ngx-datatable-column>     

                <ngx-datatable-column *ngIf="filterColumnsAssets[7].selected" name="Manufacturer" prop='Manufacturer'
                  [draggable]="false">       
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">  
                    <div class="country-header">
                      <span class="td_assets" [ngbTooltip]="row.manufacturer">{{row.manufacturer}}</span>
                    </div>              
                  </ng-template>     
                </ngx-datatable-column>     

                <ngx-datatable-column *ngIf="filterColumnsAssets[8].selected" name="Model" prop='Model'
                  [draggable]="false">       
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">  
                    <div class="country-header">
                      <span class="td_assets" [ngbTooltip]="row.model">{{row.model}}</span>
                    </div>               
                  </ng-template>     
                </ngx-datatable-column>     

                <ngx-datatable-column *ngIf="filterColumnsAssets[9].selected" name="Year" prop='Year'
                  [draggable]="false">       
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">   
                    <div class="country-header">
                      <span class="td_assets" [ngbTooltip]="row.year">{{row.years}}</span>
                    </div>              
                  </ng-template>     
                </ngx-datatable-column>     

                <ngx-datatable-column *ngIf="filterColumnsAssets[10].selected" name="Owner" prop='Owner'
                  [draggable]="false">       
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">  
                    <div class="country-header">
                      <span class="td_assets" [ngbTooltip]="row.owner">{{row.owner}}</span>
                    </div>               
                  </ng-template>     
                </ngx-datatable-column>     

                <ngx-datatable-column *ngIf="projectId == constService.emptyGuid && filterColumnsAssets[11].selected" 
                name="Under SLA" prop='UnderSLA' [draggable]="false">
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">  
                    <div class="country-header">
                      <span class="td_assets" [ngbTooltip]="row.underSLA">{{row.underSLA}}</span>
                    </div>               
                  </ng-template>     
                </ngx-datatable-column>

                <ngx-datatable-column [sortable]="false" *ngIf="!filterColumnsAssets[0].selected && !filterColumnsAssets[1].selected &&      
                  !filterColumnsAssets[2].selected &&!filterColumnsAssets[3].selected &&      
                  !filterColumnsAssets[4].selected && !filterColumnsAssets[5].selected && !filterColumnsAssets[6].selected &&       
                  !filterColumnsAssets[7].selected && !filterColumnsAssets[8].selected && !filterColumnsAssets[9].selected &&      
                  !filterColumnsAssets[10].selected && !filterColumnsAssets[11 - filterRemovedCount].selected">     
                </ngx-datatable-column>
                  
                <ngx-datatable-footer>         
                  <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"           
                    let-selectedCount="selectedCount"  let-curPage="curPage"  let-offset="offset"
                     let-isVisible="isVisible">         
                    <div class="page-count"></div>         
                  </ng-template>     
                </ngx-datatable-footer>   
              </ngx-datatable> 
            </perfect-scrollbar>

            <div class="pagination">
              <pagination-controls id="pagination1" (pageChange)="pageChangeAssets($event)" previousLabel="Previous"
                nextLabel="Next">
              </pagination-controls>
              <div>
                <select (change)="pageSizeChangedAssets($event)" [(ngModel)]="configPlan1.itemsPerPage">
                  <option *ngFor="let currentPageSize of pageSizeOption; let i = index" [value]="currentPageSize"
                    [selected]="i == 0">{{currentPageSize}}</option>
                </select>
              </div>
            </div>

          </div>
        </div>

      </ng-template>
    </li>

    <li [ngbNavItem]="2">
      <a ngbNavLink (click)="filterShow = false">Installations</a>
      <ng-template ngbNavContent>
        <div class="inst-listing">       
          <div class="main_div">       
            <ng-container ng-diable="disable">         
              <div class="search-wrapper">           
                <input type='text' class="input-ctrl search-ctrl" placeholder='Type to search' [(ngModel)]="filterStrInst"
                  (keyup)="search($event.target.value)" />   
                <csp-common-filter class="filter2" [filterOptions]="installMainFilterList"
                  [multipleFilterOptions]="installCompFilterList" [totalOptionLength]="installAllFilterListCount"
                  [isFullScreen]="true" [isPopup]="filterAPICall" (filterItemEmit)="filterItemEmit($event)"
                  [filterStyles]="filterStyles" [filterAPICallType]="'Installations'"
                  [projectId]="projectId">
                </csp-common-filter>      
              </div>       
            </ng-container> 

            <div class="filter-container" style="padding-top: 16px;" [ngClass]="{'filter-hide':!filterShow}">
              <div class="header">
                <i class="icon icon-filter" aria-hidden="true" (click)="filterShow=!filterShow"
                  [ngClass]="{'checked':!filterShow}"></i>
                <span i18n>Column filter</span>
              </div>
              <div class="filter-option" *ngFor="let item of filterColumnsInst;let i = index"
                (click)="item.selected=!item.selected;">
                <i class="icon icon-checkbox" aria-hidden="true" [ngClass]="{'checked':item.selected}"></i>
                <span>{{item.name}}</span>
              </div>
            </div>
            <perfect-scrollbar fxFlex="auto" [scrollIndicators]="true" class="scrollbar">      
              <ngx-datatable #assetsTable [rows]="installationsData | paginate: configPlan2"
                [count]="configPlan2.totalItems"  [offset]="configPlan2.currentPage"  [limit]="configPlan2.itemsPerPage"
                 (page)="pageChangeInst($event)"             [columnMode]="'force'"  [rowHeight]="52"
                (sort)="onSortInst($event)" (activate)="onRowClick($event)">     
                  
                <ngx-datatable-column *ngIf="filterColumnsInst[0].selected" name="Installation" prop='Installation'
                  [draggable]="false">       
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
                    <div class="country-header">
                      <span class="td_inst" [ngbTooltip]="row.installation">{{row.installation}}</span>
                    </div>
                  </ng-template>       
                </ngx-datatable-column>       

                <ngx-datatable-column *ngIf="filterColumnsInst[1].selected" name="Owner" prop='substationOwner'
                  [draggable]="false">       
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">    
                    <div class="country-header">
                      <span class="td_inst" [ngbTooltip]="row.substationOwner">{{row.substationOwner}}</span>
                    </div>             
                  </ng-template>     
                </ngx-datatable-column>     

                <ngx-datatable-column *ngIf="filterColumnsInst[2].selected" name="Operator" prop='SubstationOperator'
                  [draggable]="false">       
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
                    <div class="country-header">
                      <span class="td_inst" [ngbTooltip]="row.operator">{{row.substationOperator}}</span>
                    </div>                 
                  </ng-template>     
                </ngx-datatable-column>     

                <ngx-datatable-column *ngIf="filterColumnsInst[3].selected" name="Country" prop='Country'
                  [draggable]="false">       
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">  
                    <div class="country-header">
                      <span class="td_inst" [ngbTooltip]="row.country">{{row.country}}</span>
                    </div>              
                  </ng-template>     
                </ngx-datatable-column>     

                <ngx-datatable-column *ngIf="filterColumnsInst[4].selected" name="Location" prop='location'
                  [draggable]="false">       
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">  
                    <div class="country-header">
                      <span class="td_inst"
                        [ngbTooltip]="row.location">{{row.location}}</span>
                    </div>               
                  </ng-template>     
                </ngx-datatable-column>     

                <ngx-datatable-column *ngIf="filterColumnsInst[5].selected" name="Year of
                  installation" prop='YearOFInstallation' [draggable]="false">       
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">   
                    <div class="country-header">
                      <span class="td_inst" [ngbTooltip]="row.yearOFInstallation">{{row.yearOFInstallation}}</span>
                    </div>              
                  </ng-template>     
                </ngx-datatable-column>     

                <ngx-datatable-column *ngIf="filterColumnsInst[6].selected" name="Year of
                  commissioning" prop='YearOfComissioning' [draggable]="false">       
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">  
                    <div class="country-header">
                      <span class="td_inst" [ngbTooltip]="row.yearOfComissioning">{{row.yearOfComissioning}}</span>
                    </div>               
                  </ng-template>     
                </ngx-datatable-column>     

                <ngx-datatable-column *ngIf="filterColumnsInst[7].selected" name="Technology" prop='Technology'
                  [draggable]="false">       
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">    
                    <div class="country-header">
                      <span class="td_inst" [ngbTooltip]="row.technology">{{row.technology}}</span>
                    </div>             
                  </ng-template>     
                </ngx-datatable-column>     

                <ngx-datatable-column *ngIf="projectId == constService.emptyGuid && filterColumnsInst[8].selected" name="Under SLA" prop='UnderSLA'
                  [draggable]="false">       
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
                    <div class="country-header">
                      <span class="td_inst" [ngbTooltip]="row.underSLA">{{row.underSLA}}</span>
                    </div>                 
                  </ng-template>     
                </ngx-datatable-column>     

                <ngx-datatable-column [sortable]="false" *ngIf="!filterColumnsInst[0].selected && !filterColumnsInst[1].selected &&      
                  !filterColumnsInst[2].selected &&!filterColumnsInst[3].selected &&      
                  !filterColumnsInst[4].selected && !filterColumnsInst[5].selected && !filterColumnsInst[6].selected &&       
                  !filterColumnsInst[7].selected && !filterColumnsInst[8].selected">     
                </ngx-datatable-column>   
                  
                <ngx-datatable-footer>         
                  <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"           
                    let-selectedCount="selectedCount"  let-curPage="curPage"  let-offset="offset"
                     let-isVisible="isVisible">         
                    <div class="page-count"></div>         
                  </ng-template>     
                </ngx-datatable-footer>   
              </ngx-datatable> 
            </perfect-scrollbar>

            <div class="pagination">
              <pagination-controls id="pagination2" (pageChange)="pageChangeInst($event)" previousLabel="Previous"
                nextLabel="Next">
              </pagination-controls>
              <div>
                <select (change)="pageSizeChangedInst($event)" [(ngModel)]="configPlan2.itemsPerPage">
                  <option *ngFor="let currentPageSize of pageSizeOption; let i = index" [value]="currentPageSize"
                    [selected]="i == 0">{{currentPageSize}}</option>
                </select>
              </div>
            </div>

          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>