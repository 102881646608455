import { Component, OnInit } from '@angular/core';
import { AddNewTenantComponent } from '../add-new-tenant/add-new-tenant.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { ApplicationService } from 'src/services/application.service';
import { SuccesspopupComponent } from '../dashboard/modals/successpopup/successpopup.component';
import { DeleteAlertModalComponent } from '../../containers/dashboard/modals/delete-alert-modal/delete-alert-modal.component';
import { EncrDecrService } from 'src/services/EncrDecrService';
@Component({
  selector: 'app-tenant-admin',
  templateUrl: './tenant-admin.component.html',
  styleUrls: ['./tenant-admin.component.scss']
})
export class TenantAdminComponent implements OnInit {
  loading = false;
  tenantDetails =[];
  configPage;
  selected=[];
  searchClick = 1;
  filterColumns = [
    { prop: 'tenantName', name: 'Tenant name', selected: true },
    { prop: 'tenantUrl', name: 'Tenant url', selected: true },
    { prop: 'jobStatus', name: 'Job status', selected: true }
  ];
search="";
sortBy="ascending";
sortColumn="";
sortDirection= true;
  constructor(private modalService: NgbModal,private appSvc:ApplicationService,
    private encrDecrService: EncrDecrService) { }

  ngOnInit(): void {
    this.configPage = {
      id: "pagination1",
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 10
        }
    this.getTenants();
  }
  add() {
  let modalRef = this.modalService.open(AddNewTenantComponent, { centered: true });
  modalRef.componentInstance.title = 'Add new tenant';
  modalRef.componentInstance.data = {};
  modalRef.result.then(ret => {
    if (ret.type === 'save') {
      const formData =
        {
          "tenantId": "00000000-0000-0000-0000-000000000000",
          "tenantName":  ret.data.tenantName,
          "tenantLink": '',
          "tenantDetail": {
            "connectionString": this.encrDecrService.encrypt(ret.data.dBconnectionString, ret.data.secretKey),
            "azureStorageConnectionString": this.encrDecrService.encrypt(ret.data.azureStorageConnectionString, ret.data.secretKey),
            "clientIdandSecret": this.encrDecrService.encrypt(ret.data.clientIdandSecret, ret.data.secretKey)
          },
          "isSystemEnvironment":ret.data.isSystemEnvironment,
          "JobStatus":"",
          "createdAt": ""
        };
        this.loading = true;
      this.appSvc.CreateTenant(formData).subscribe({next:  (res) => {
        this.loading = false;
        this.getTenants();
        if(res.statusCode === 200){
          const modalRefpop = this.modalService.open(SuccesspopupComponent, { centered: true });
          modalRefpop.componentInstance.title = 'Message';
          modalRefpop.componentInstance.message = 'Tenant created successfully!';
        } else if (res.statusCode === 400){
          const modalRefpop = this.modalService.open(SuccesspopupComponent, { centered: true });
          modalRefpop.componentInstance.title = 'Warning';
          modalRefpop.componentInstance.message = res.errorMessage;
        }
      }, error : () => {
        this.loading = false;
      }});
    }
    });
}
getTenants(){
  if (this.sortDirection === true) {
    this.sortBy = 'ascending';
  } else {
    this.sortBy = 'descending';
  }
  this.selected = [];
  this.loading = true;
  this.appSvc.GetAllTenants(this.configPage.currentPage,this.configPage.itemsPerPage,this.search,this.sortBy,this.sortColumn).subscribe({next:  (res) => {
    this.tenantDetails = res.returnObj.tenantDetails;
    this.configPage.totalItems = res.returnObj.totalNoTenants;
    this.loading = false;
  }, error : (err) => {
    this.loading = false;
  }}); 
}
sort(field) {
  if (this.sortColumn === field) {
    this.sortDirection = !this.sortDirection;
  } else {
    this.sortDirection = true;
  }
  this.sortColumn = field;
  this.getTenants();
}
pageChangePlan(newPage: number) {
  this.configPage.currentPage = newPage;
  this.getTenants();
}
onCheckboxChangeFn(event, item) {
  this.tenantDetails.forEach(ele =>{
    ele.isSelected  = false;
  })
  item.isSelected = event.target.checked;
  if (event.target.checked) {
    this.selected = [];
    this.selected.push(item);
    sessionStorage.setItem("tenantId",this.selected[0].tenantId);
  } else {
    this.selected.splice(this.selected.indexOf(item), 1);
  }
 console.log(this.selected)
}
changeSearch() {
  this.searchClick = 1;
  this.configPage.currentPage = 1;
  this.getTenants();
}
noSearch() {
  if (this.searchClick === 1) {
    this.configPage.currentPage = 1;
    this.getTenants();
  }
}
editTenant() {
  this.loading = true;
  this.appSvc.getTenantById(this.selected[0].tenantId).subscribe({next:  (res) => {
    this.loading = false;
    let modalRef = this.modalService.open(AddNewTenantComponent, { centered: true });
    modalRef.componentInstance.title = 'Edit tenant';
    modalRef.componentInstance.data = res.returnObj;


     
  modalRef.result.then(ret => {
    const unchanged = ret.unchangedValue;
    let tenantDetail = {};
    if(!unchanged.includes('dBconnectionString')) {
      tenantDetail['connectionString'] = this.encrDecrService.encrypt(ret.data.dBconnectionString, ret.data.secretKey);
    }
    if(!unchanged.includes('azureStorageConnectionString')) {
      tenantDetail['azureStorageConnectionString'] = this.encrDecrService.encrypt(ret.data.azureStorageConnectionString, ret.data.secretKey);
    } 
    if(!unchanged.includes('clientIdandSecret')) {
      tenantDetail['clientIdandSecret'] = this.encrDecrService.encrypt(ret.data.clientIdandSecret, ret.data.secretKey);
    }
    if (ret.type === 'save') {
      const formData =
        {
          "tenantId": sessionStorage.getItem("tenantId"),
          "tenantName":  ret.data.tenantName,
          "tenantDetail": tenantDetail,
        };
        this.loading = true;
      this.appSvc.UpdateTenant(sessionStorage.getItem("tenantId"),formData).subscribe({next:  (res) => {
        this.loading = false;
        this.getTenants();
        if(res.statusCode === 200){
          const modalRefpop = this.modalService.open(SuccesspopupComponent, { centered: true });
          modalRefpop.componentInstance.title = 'Message';
          modalRefpop.componentInstance.message = 'Tenant updated successfully!';
        } else if (res.statusCode === 400){
          const modalRefpop = this.modalService.open(SuccesspopupComponent, { centered: true });
          modalRefpop.componentInstance.title = 'Warning';
          modalRefpop.componentInstance.message = res.errorMessage;
        }
      }, error : () => {
        this.loading = false;
      }});
    }
    });
  }, error : () => {
    this.loading = false;
  }});
}
deleteTenant(id) {
  const modalRef = this.modalService.open(DeleteAlertModalComponent, { centered: true });
  modalRef.componentInstance.deleteItemName = 'Tenant';
  modalRef.result.then(ret => {
    if(ret === 'delete') {
  this.loading= true;
  this.appSvc.deleteTenant(id[0].tenantId).subscribe({next:  (res) => {
    this.loading = false;
    this.getTenants();
    this.selected = [];
    if(res){
      const modalRefpop = this.modalService.open(SuccesspopupComponent, { centered: true });
      modalRefpop.componentInstance.title = 'Message';
      modalRefpop.componentInstance.message = 'Tenant deleted successfully!';
    }
  }, error : (err) => {
    this.loading = false;
  }});
}
});

}
}
