<div class="page-container">
  <header class="header-container row">
    <div class="row">
      <div class="col-lg-5">
        <div *ngIf="!viewMode">
          <a class="goBack" href="javascript:;" (click)="backWithoutSave()"></a>
          <span class="back-title">Projects</span>
        </div>
        <div *ngIf="viewMode">
          <a class="goBack" href="javascript:;" (click)="goToPreviousPage()"></a>
          <span class="back-title">{{fromPage}}</span>
        </div>
      </div>
      <div class="col-lg-7">
        <h3 class="main-heading">{{ title }}</h3>
      </div>
    </div>
  </header>
  <div class="main-container">
    <div class="panel">
      <div class="tabs-member-container">
        <ul ngbNav #nav="ngbNav" [activeId]="active" class="nav-tabs">
          <div *ngIf="!viewMode" class="panelborder">
            <div class="row">
              <div style="width: 41%;" class="col-lg-5">
              </div>
              <div class="col-lg-4 headingline">{{subTitle}}
              </div>
              <div class="col-lg-3">
                <span class="headlinestatus">Status: {{status}}</span>
                <span *ngIf="showicon"> <img *ngIf="id && showButton" (click)="resetChanges()" class="imgalign"
                    src="../../assets/sld-assets/icons/play.svg" alt="Cube">
                  <img *ngIf="id && !showButton" (click)="editChanges()" class="imgalign"
                    src="../../assets/sld-assets/icons/edit.svg" alt="Edit">
                </span>
              </div>
            </div>
          </div>
          <li [ngbNavItem]="1">
            <a *ngIf="viewMode" class="headingline" ngbNavLink>{{ subTitle }} </a>
            <ng-template ngbNavContent>
              <form [formGroup]="form">
                <div class="scroll-panel" style="height: 100%; position: relative">
                  <perfect-scrollbar fxFlex="auto" class="scrollbar">
                    <div class="panel-content container-fluid">
                      <div class="row">
                        <div class="col-lg-7">
                          <h5 class="h2-title" i18n>Client details</h5>
                          <div class="row">
                            <div class="col-lg-6">
                              <div class="form-wrapper">
                                <label class="label-element" i18n>*Name{{ enableFields ? "" : null }}</label>
                                <input [class.invalid]="validation('name')" maxlength="25" type="text"
                                  formControlName="name" class="form-input"
                                  [attr.disabled]="enableFields ? '' : null" />
                                <div [class.show]="validation('name')" class="error-message" i18n>
                                  Name is required.
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <div class="form-wrapper">
                                <label class="label-element" i18n>*Continent</label>

                                <select [attr.disabled]="enableFields ? '' : null" [class.invalid]="validation('area')"
                                  (change)="onChangeArea($event.target.value)" formControlName="area"
                                  class="form-input">
                                  <option value="">Select</option>
                                  <option *ngFor="let item of getAllregions" [value]="item.areaId">
                                    {{ item.areaName }}
                                  </option>
                                </select>
                                <div [class.show]="validation('area')" class="error-message" i18n>
                                  Continent is required.
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-4">
                              <div class="form-wrapper">
                                <label class="label-element" i18n>*Country</label>
                                <select [attr.disabled]="enableFields ? '' : null"
                                  [class.invalid]="validation('country')" (change)="onChange($event.target.value)"
                                  formControlName="country" class="form-input">
                                  <option value="">Select</option>
                                  <option *ngFor="let item of getAllCountries" [value]="item.countryId">
                                    {{ item.countryName }}
                                  </option>
                                </select>
                                <div [class.show]="validation('country')" class="error-message" i18n>
                                  Country is required.
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="form-wrapper">
                                <label class="label-element" i18n>*State</label>

                                <select [class.invalid]="validation('state')" [attr.disabled]="
                                  showState
                                    ? ''
                                    : null && enableFields
                                    ? ''
                                    : null
                                " (change)="onChangestate($event.target.value)" formControlName="state"
                                  class="form-input">
                                  <option value="">Select</option>
                                  <option *ngFor="let item of getAllStates" [value]="item.stateId">
                                    {{ item.stateName }}
                                  </option>
                                </select>
                                <div [class.show]="validation('state')" class="error-message" i18n>
                                  State is required.
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="form-wrapper">
                                <label class="label-element" i18n>*City</label>
                                <select [class.invalid]="validation('city')" [attr.disabled]="
                                  showCity
                                    ? ''
                                    : null && enableFields
                                    ? ''
                                    : null
                                " formControlName="city" class="form-input">
                                  <option value="">Select</option>
                                  <option *ngFor="let item of getAllCities" [value]="item.cityId">
                                    {{ item.cityName }}
                                  </option>
                                </select>
                                <div [class.show]="validation('city')" class="error-message" i18n>
                                  City is required.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-5">
                          <h5 class="h3-title" i18n>Members</h5>
                          <div class="members-block">
                            <label i18n>*Project manager</label>
                            <div *ngIf="projectHasUserDtoListnew && !isDiscardCont">
                              <div *ngFor="
                                let projectManager of projectHasUserDtoListnew
                              " class="member">
                                <img *ngIf="
                                  projectManager.userProfilePhoto;
                                  else templateName
                                " [src]="projectManager.userProfilePhoto" class="icon icon-avatar" alt="Avatar" />
                                <ng-template #templateName>
                                  <i class="icon icon-avatar" aria-hidden="true"></i>
                                </ng-template>
                                <p>{{ projectManager.firstName }}</p>
                              </div>
                            </div>

                            <div (click)="assignProjectManger()" [ngClass]="{ disabledNoOfCasesDiv: enableFields }"
                              class="member-add">
                              <i class="icon icon-add-new" aria-hidden="true"></i>
                              <p i18n>Assign role to user</p>
                            </div>
                            <span class="error" *ngIf="showforNotManager">Project Manager is required</span>
                          </div>
                        </div>
                      </div>
                      <h5 class="h2-title" i18n>Project details</h5>
                      <div class="row">
                        <div class="col-lg-3">
                          <div class="form-wrapper">
                            <label class="label-element" i18n>*Name</label>
                            <input [attr.disabled]="enableFields ? '' : null" (keyup)="addDirectLink()"
                              [class.invalid]="validation('projectName')" maxlength="25" type="text"
                              formControlName="projectName" class="form-input" />
                            <div [class.show]="validation('projectName')" class="error-message" i18n>
                              Project Name is required.
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="form-wrapper">
                            <label class="label-element" i18n>*Type</label>
                            <select [attr.disabled]="enableFields ? '' : null"
                              [class.invalid]="validation('projectType')" formControlName="projectType"
                              class="form-input">
                              <option value="">Select</option>
                              <option *ngFor="let item of getAllProjectTypes" [value]="item.projectTypeId">
                                {{ item.projectTypeName }}
                              </option>
                            </select>
                            <div [class.show]="validation('projectType')" class="error-message" i18n>
                              Project Type is required.
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="form-wrapper">
                            <label class="label-element" i18n>*Currency</label>
                            <select [attr.disabled]="enableFields ? '' : null"
                              [class.invalid]="validation('projectCurrency')" formControlName="projectCurrency"
                              class="form-input" (change)="selectCurrency($event.target.value)">
                              <option value="">Select</option>
                              <option *ngFor="let item of getAllCurrencies" [value]="item.currencyId">
                                {{ item.currencyName }}
                              </option>
                            </select>
                            <div [class.show]="validation('projectCurrency')" class="error-message" i18n>
                              Project Currency is required.
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="form-wrapper">
                            <label class="label-element" i18n>*Decimal delimiter</label>

                            <select [attr.disabled]="enableFields ? '' : null"
                              [class.invalid]="validation('decimalDelimiter')" formControlName="decimalDelimiter"
                              class="form-input">
                              <option value="">Select</option>
                              <option *ngFor="let item of decimalDelimitter" [value]="item">
                                {{ item }}
                              </option>
                            </select>
                            <div [class.show]="validation('decimalDelimiter')" class="error-message" i18n>
                              Decimal delimiter is required.
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="form-wrapper">
                            <label class="label-element" i18n>*Diagram symbols</label>
                            <select [attr.disabled]="enableFields ? '' : null"
                              [class.invalid]="validation('diagramSymbols')" formControlName="diagramSymbols"
                              class="form-input">
                              <option value="">Select</option>
                              <option *ngFor="let item of getAllDiagramSymbols" [value]="item.diagramSymbolId">
                                {{ item.diagramSymbolName }}
                              </option>
                            </select>
                            <div [class.show]="validation('diagramSymbols')" class="error-message" i18n>
                              Diagram symbols is required.
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="form-wrapper">
                            <label class="label-element" i18n>*Opening SWMS</label>
                            <select [attr.disabled]="enableFields ? '' : null"
                              [class.invalid]="validation('openingSWMS')" formControlName="openingSWMS"
                              class="form-input">
                              <option value="">Select</option>
                              <option *ngFor="let item of openingSWMSOption" [value]="item.ContentValue">
                                {{ item.DisplayValue }}
                              </option>
                            </select>
                            <div [class.show]="validation('openingSWMS')" class="error-message" i18n>
                              Opening SWMS is required.
                            </div>
                          </div>
                        </div>
                        <div  class="col-lg-3">
                          <div class="form-wrapper">
                            <label class="label-element" i18n>*Operating unit / Local Service Center</label>
                            <select [attr.disabled]="enableFields ? '' : null"
                              [class.invalid]="validation('operatingUnit')" formControlName="operatingUnit"
                              class="form-input">
                              <option value="">Select</option>
                              <option *ngFor="let item of getAllOperatingUnit" [value]="item.countryId">
                                {{ item.countryName }}
                              </option>
                            </select>
                            <div [class.show]="validation('operatingUnit')" class="error-message" i18n>
                              Operating unit / Local Service Center is required.
                            </div>
                          </div>
                        </div>
                        <div  class="col-lg-3">
                          <div class="form-wrapper">
                            <label class="label-element" i18n>*Time zone</label>
                            <select [attr.disabled]="enableFields ? '' : null" [class.invalid]="validation('timeZone')"
                              formControlName="timeZone" class="form-input">
                              <option value="">Select</option>
                              <option *ngFor="let tZ of timeZoneList" [value]="tZ.tzCode">
                                (UTC{{ tZ.utc }}) {{ tZ.tzCode }}
                              </option>
                            </select>
                            <div [class.show]="validation('timeZone')" class="error-message" i18n>
                              Time zone is required.
                            </div>
                          </div>
                        </div>
                        <div  class="col-lg-3">
                          <div class="form-wrapper">
                            <label class="label-element" i18n>*Description</label>
                            <textarea [attr.disabled]="enableFields ? '' : null"
                              [class.invalid]="validation('description')" maxlength="500" type="text"
                              formControlName="description" class="form-input"></textarea>
                            <div [class.show]="validation('description')" class="error-message" i18n>
                              Description is required.
                            </div>
                          </div>
                        </div>
                      </div>
                      <h5 class="h2-title" i18n>Project duration</h5>
                      <div class="row">
                        <div class="col-lg-3">
                          <div class="form-wrapper">
                            <label class="label-element" i18n>*Start</label>
                            <mat-form-field  class="calendarForm" [ngClass]="{ disabledNoOfCasesDiv: enableFields }"
                              appearance="fill">
                              <mat-label style="font-size: 12px">Choose a date</mat-label>
                              <mat-datepicker-toggle matIconSuffix [for]="picker1" [ngClass]="{ disabledNoOfCasesDiv: enableFields }"
                                style="color: #28679e; float: right"></mat-datepicker-toggle>
                              <mat-datepicker #picker1></mat-datepicker>
                              <br />
                              <input [ngClass]="{ disabledNoOfCasesDiv: enableFields }" placeholder="mm-dd-yyyy" style="
                                width: 50%;
                                font-size: 14px;
                                font-weight: 500;
                              " matInput [matDatepicker]="picker1" formControlName="startDate" [disabled]="enableFields" />
                            </mat-form-field>
                            <div [class.show]="validation('startDate')" class="error-message" i18n>
                              Start date is required.
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="form-wrapper">
                            <label class="label-element" i18n>*End</label>
                            <mat-form-field [ngClass]="{
                              disabledNoOfCasesDiv: enableEditableFields
                            }" class="calendarForm" appearance="fill">
                              <mat-label style="font-size: 12px">Choose a date</mat-label>
                              <mat-datepicker-toggle matIconSuffix [for]="picker2" [ngClass]="{
                                disabledNoOfCasesDiv: enableEditableFields
                              }" style="color: #28679e; float: right"></mat-datepicker-toggle>
                              <mat-datepicker #picker2></mat-datepicker>
                              <br />
                              <input [ngClass]="{
                                disabledNoOfCasesDiv: enableEditableFields
                              }" placeholder="mm-dd-yyyy" style="
                                width: 50%;
                                font-size: 14px;
                                font-weight: 500;
                              " matInput [matDatepicker]="picker2" formControlName="endDate" [disabled]="enableEditableFields" />
                            </mat-form-field>
                            <div [class.show]="validation('endDate')" class="error-message" i18n>
                              End date is required.
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-2">&nbsp;</div>
                      </div>
                      <h5 class="h2-title" i18n>Deployment</h5>
                      <div class="row">
                        <div class="col-lg-3">
                          <div class="form-wrapper">
                            <label class="label-element" i18n>*Project type</label>
                            <select [attr.disabled]="enableFields ? '' : null" [class.invalid]="
                              validation('projectDeploymentType')
                            " (change)="onChangeProjectType($event.target.value)"
                              formControlName="projectDeploymentType" class="form-input">
                              <option value="">Select</option>
                              <option *ngFor="let item of getAllProjectTypeDto" [value]="item.projectDeploymentTypeId">
                                {{ item.projectDeploymentTypeName }}
                              </option>
                            </select>
                            <div [class.show]="validation('projectDeploymentType')" class="error-message" i18n>
                              Project Type is required.
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="form-wrapper">
                            <label class="label-element" i18n>*Hosting</label>
                            <select [attr.disabled]="
                              enableFields
                                ? ''
                                : null || !isExternalLink
                                ? ''
                                : null
                            " [class.invalid]="validation('hosting')" [class.invalid]="validation('hosting')"
                              formControlName="hosting" class="form-input">
                              <option value="">Select</option>
                              <option *ngFor="let item of getHostingDto" [value]="item.projectHostingTypeId">
                                {{ item.projectHostingTypeName }}
                              </option>
                            </select>
                            <div [class.show]="validation('hosting')" class="error-message" i18n>
                              Hosting is required.
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="form-wrapper">
                            <label class="label-element" i18n>*Direct link</label>
                            <input [attr.disabled]="!isExternalLink ? '' : null" maxlength="50" type="text"
                              formControlName="directlink" class="form-input" />
                            <div [class.show]="validation('directlink')" class="error-message" i18n>
                              Direct link is required.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </perfect-scrollbar>
                </div>
              </form>
              <!-- </div> -->
            </ng-template>
          </li>
          <li [ngbNavItem]="2" *ngIf="viewMode">
            <a ngbNavLink class="headingline">Installations</a>
            <ng-template ngbNavContent>
              <app-installation-table [projectId]="id" [type]="type" [from]="fromPage"></app-installation-table>
            </ng-template>
          </li>
          <div *ngIf="viewMode" class="col-md-8" [ngClass]="showButton? 'draft-play': 'draft-edit'"
           style="text-align: right;">
            <span>Status: {{ status }}</span>
            <span *ngIf="showicon && (status === 'Draft' || status === 'Active')">
              <img *ngIf="id && showButton" (click)="resetChanges()" class="imgalign"
                src="../../assets/sld-assets/icons/play.svg" alt="Cube" />
              <img *ngIf="id && !showButton && (status === 'Draft' || status === 'Active')" (click)="editChanges()" class="imgalign"
                src="../../assets/sld-assets/icons/edit.svg" alt="Edit" />
            </span>
          </div>
        </ul>

        <div [ngbNavOutlet]="nav"></div>
        <div class="panel-footer">
          <div class="save" *ngIf="isPolicyManager">
            <button *ngIf="status == 'Draft' && !id" type="button" (click)="discard()"
            [disabled]="!form.dirty" class="btn btn-primary" i18n>
              Discard
            </button>
            <button *ngIf="status == 'Draft' && id" type="button"
              [disabled]="enableFields" (click)="discard()" class="btn btn-primary" i18n>
              Discard
            </button>
            <button *ngIf="status == 'Draft' " type="button" (click)="save()"
              [disabled]="isname()" class="btn btn-primary" i18n>
              Save
            </button>
            <button *ngIf="status == 'Draft' && type === 'Internal' " type="button"
              (click)="create()" [disabled]="form.invalid" class="btn btn-primary" i18n>
              Create
            </button>
            <div class="right">
              <button *ngIf="
            id &&
            isProjectRole &&
            status == 'Active' &&
            type === 'Internal'
          " type="button" (click)="goToProject()" class="btn btn-primary" i18n>
                Go to project
              </button>
            </div>
            <button *ngIf="id && status == 'Active' && !isProjectRole" type="button"
              (click)="closeProject()" class="btn btn-primary" i18n>
              Close project
            </button>
            <button *ngIf="id && status == 'Closed' && !isProjectRole" type="button"
              (click)="reopenProject()" class="btn btn-primary" i18n>
              Re-open project
            </button>
            <button *ngIf="
            id &&
            (status == 'Active' || status == 'Closed') &&
            !isProjectRole
          " type="button" (click)="deleteProject()" class="btn btn-primary" i18n>
              Delete project
            </button>
            <button *ngIf="
            id &&
            status == 'Active' &&
            !isProjectRole &&
            type !== 'External'
          " type="button" (click)="saveChanges()" [disabled]="!form.dirty && !changeMember" class="btn btn-primary"
              i18n>
              Save changes
            </button>
            <button type="button" *ngIf="
            type === 'External' &&
            status !== 'Provisioning' &&
            !isProjectRole
          " (click)="create()" [disabled]="form.invalid" class="btn btn-primary deploy" i18n>
              Deploy
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="loading-box" *ngIf="loading">
      <div class="img" alt="loading"></div>
    </div>

  </div>
</div>

<!-- </div> -->