import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import modalConstant from '../../../../assets/modal-constants.json';
import { ApplicationService } from '../../../../services/application.service';
import { EditUserComponent } from '../edit-user/edit-user.component';
import { DeleteAlertModalComponent } from '../modals/delete-alert-modal/delete-alert-modal.component';
import { SuccesspopupComponent } from '../modals/successpopup/successpopup.component';

const PAGE_SIZE = 10;
@Component({
  selector: 'app-all-users',
  templateUrl: './all-users.component.html',
  styleUrls: ['./all-users.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AllUserComponent implements OnInit, AfterViewInit {
  @Input() teamUser;
  @Input() teamId;
  @Input() role;
  @Input() userIdExist: any = [];
  loading;
  allUsers = [];
  allUsersList = [];
  selected = [];
  filterShow = false;
  filterStr = '';
  private searchTerms = new Subject<any>();
  page = { totalElements: 0, pageNumber: 0, size: PAGE_SIZE };
  @Input() asModal = false;
  @Output() userData = new EventEmitter();
  tableVisible = false;
  userType: string;
  disableEdit = true;
  useUserList = [];
  filterColumns = [
    { prop: 'userName', name: 'Member', selected: true },
    { prop: 'role', name: 'Role', selected: true },
    { prop: 'employer', name: 'Employer', selected: true },
    { prop: 'executionTeams', name: 'Execution team', selected: true },
    { prop: 'email', name: 'Email', selected: true },
    { prop: 'phone', name: 'Phone', selected: true }
  ];
  ff = [];
  userCount: any = [];
  temp = [];
  isSystemAdmin = false;
  userSystemroleRoles: any = {};
  showButton = false;
  hide_icon = false;
  search = false;
  userId;
  isExecutionView = false;
  currentTimeout: any = null;
  userRolesObject: any;
  isControlRoom : boolean;
  constructor(private route: Router, private appSvc: ApplicationService,
    private modalService: NgbModal, private activatedRoute: ActivatedRoute, config: NgbModalConfig) {
    config.backdrop = 'static';
    // this.appSvc.getAllUsers(this.page.size, this.page.pageNumber).subscribe(res => {
    //   // this.loading = false;
    //   this.page.totalElements = res.totalElements;
    //   this.allUsersList = res.data;
    //   this.allUsers = res.data;
    //   setTimeout(() => {
    //     this.tableVisible = true;
    //   }, 0);
    // });
    // if(this.activatedRoute.snapshot.url[0].path === 'members-privileges'){
    //   this.userSystemroleRoles = true;
    // }
  }

  ngOnInit() {
    if (!this.teamId) {
      this.teamId = '00000000-0000-0000-0000-000000000000';
    }
    this.userId = sessionStorage.getItem('UserId');
    this.isSystemAdmin = this.appSvc.isSyatemAdmin();
    this.userSystemroleRoles = this.appSvc.getUserPrivilages();
    this.userRolesObject = this.appSvc.getUserPrivilages();
    this.isControlRoom = this.appSvc.isControlRoom() === true ? true : false; 
    if (this.userRolesObject.roles.toLowerCase() === 'system manager' || this.userRolesObject.roles.toLowerCase() === 'system support'
      || this.userRolesObject.roles.toLowerCase() === 'admin') {
      this.isExecutionView = false;
    } else {
      this.isExecutionView = true;
    }
    // const roles = userRolesObject.roles.toLowerCase();
    // this.isSystemAdmin = roles === 'admin' ? true : false;
    // this.appSvc.getAllUsers(this.page.size, this.page.pageNumber).subscribe(res => {
    //   this.loading = false;
    //   this.page.totalElements = res.totalElements;
    //   this.allUsersList = res.data;
    //   this.allUsers = res.data;
    //   // make the table relayout, otherwize sometimes its layout is wrong
    //   setTimeout(() => {
    //     this.tableVisible = true;
    //   }, 0);
    // });
    // this.userType = this.activatedRoute.snapshot.data.userType;

    if (this.teamUser === true) {
      this.loading = true;
      this.appSvc.getAllUsersInTeam(this.teamId, this.page.size, this.page.pageNumber).subscribe({next:res => {
        this.userCount = res;
        if (this.userCount.length > 0) {
          this.userData.emit(true);
        } else {
          this.userData.emit(false);
        }
        this.loading = false;
        this.page.totalElements = res.totalElements;
        this.allUsersList = res.data;
        this.allUsers = res.data;
        const arr2 = this.allUsers.map(x => ({ ...x, key: false }));
        this.allUsers = arr2;
        this.allUsers.forEach(element => {
          this.userIdExist.forEach(element2 => {
            if (element.userId === element2.userId) {
              element.key = true;
            }
          });
        });
        this.useUserList = res.data;
        this.selected = [];
        setTimeout(() => {
          this.tableVisible = true;
        }, 0);
      }, error: () => {
        this.loading = false;
      }});
    } else if (this.role === 'ABBManager') {
      this.loading = true;
      this.appSvc.getAllProjectUsers(this.teamId, this.page.size, this.page.pageNumber).subscribe({next:res => {
        if (res.data.length > 0) {
          this.userData.emit(true);
        } else {
          this.userData.emit(false);
        }
        this.loading = false;
        this.page.totalElements = res.totalElements;
        this.allUsersList = res.data;
        this.allUsers = res.data;
        this.useUserList = res.data;
        this.selected = [];
        setTimeout(() => {
          this.tableVisible = true;
        }, 0);
      }, error: () => {
        this.loading = false;
      }});
    } else if (this.role === 'projectManagers') {
      this.loading = true;
      this.appSvc.getAllProjectManagers(this.teamId, this.page.size, this.page.pageNumber).subscribe({next:res => {
        this.userCount = res;
        if (res.data.length > 0) {
          this.userData.emit(true);
        } else {
          this.userData.emit(false);
        }
        this.loading = false;
        this.page.totalElements = res.totalElements;
        this.allUsersList = res.data;
        this.allUsers = res.data;
      //   this.allUsers.forEach(element => {
      //     element.teamUsersDtoList.forEach(ele1 => {
      //     element.executionTeams =ele1.teamName
	    // });});
        this.useUserList = res.data;
        this.selected = [];
        setTimeout(() => {
          this.tableVisible = true;
        }, 0);
      }, error:() => {
        this.loading = false;
      }});
    } else if (this.role === 'projectSupports') {
      this.loading = true;
      this.appSvc.getAllProjectSupports(this.teamId, this.page.size, this.page.pageNumber).subscribe({next:res => {
        this.userCount = res;
        if (res.data.length > 0) {
          this.userData.emit(true);
        } else {
          this.userData.emit(false);
        }
        this.loading = false;
        this.page.totalElements = res.totalElements;
        this.allUsersList = res.data;
        this.allUsers = res.data;
    //     this.allUsers.forEach(element => {
    //       element.teamUsersDtoList.forEach(ele1 => {
    //       element.executionTeams = ele1.teamName
	  //        });                                      
    // });
        this.useUserList = res.data;
        this.selected = [];
        setTimeout(() => {
          this.tableVisible = true;
        }, 0);
      }, error:() => {
        this.loading = false;
      }});
    } else {
      this.loading = true;
      this.appSvc.getAllUsers(this.page.size, this.page.pageNumber).subscribe({next:res => {
        this.loading = false;
        this.page.totalElements = res.totalElements;
        this.allUsersList = res.data;
        this.allUsers = res.data;
        if(sessionStorage.getItem('currentRole') === 'Project Support'){
        const arr2 = this.allUsers.map(x => ({ ...x, isPS: false }));
        this.allUsers = arr2;
        this.allUsers.forEach(eleRole => {
          if(eleRole.userRolesDtoList.length > 0){
            eleRole.userRolesDtoList.forEach(eleRolelist => {
              if(eleRolelist.roleName === 'Project Manager') {
                eleRole.isPS = true;
              }
            });
          }

        });
      }
      if(sessionStorage.getItem('currentRole') === 'System Support'){
        const arr2 = this.allUsers.map(x => ({ ...x, isPS: false }));
        this.allUsers = arr2;
        this.allUsers.forEach(eleRole => {
          if(eleRole.userRolesDtoList.length > 0){
            eleRole.userRolesDtoList.forEach(eleRolelist => {
              if(eleRolelist.roleName === 'System Manager') {
                eleRole.isPS = true;
              }
            });
          }

        });
      }
        this.allUsers.forEach(element => {
          element.teamUsersDtoList.forEach(ele1 => {
          element.executionTeams = ele1.teamName
	    });});
        this.useUserList = res.data;
        this.selected = [];
        setTimeout(() => {
          this.tableVisible = true;
        }, 0);
      }, error: () => {
        this.loading = false;
      }});
    }
    if (this.route.url.includes('/dashboard/members-privileges')) {
      this.showButton = true;
    } else {
      this.showButton = false;
    }
    if (this.teamUser === true) {
      this.showButton = false;
    }
    // else {
    //   this.showButton = true;
    // }

  }
  ngAfterViewInit() {
    //this.filterColumns = _.remove(this.filterColumns, function(filter) { return filter.prop !== 'executionTeams'; });
    if (this.userRolesObject.roles.toLowerCase() === 'system manager' || this.userRolesObject.roles.toLowerCase() === 'system support' || this.userRolesObject.roles.toLowerCase() === 'admin') {
      const element: HTMLElement = document.getElementById('executionTeams');
      element.hidden = true;
    }
  }
  // ngDoCheck()	{
  //       this.appSvc.getAllUsers(this.page.size, this.page.pageNumber).subscribe(res => {
  //     this.loading = false;
  //     this.page.totalElements = res.totalElements;
  //     this.allUsersList = res.data;
  //     this.allUsers = res.data;

  //     // for (let i = 0; i < this.allUsers.length; i++) {
  //     //   for (let j = 0; j < this.allUsers[i].userRolesDtoList.length; j++) {
  //     //     this.ff.push(this.allUsers[i].userRolesDtoList[j].roleName);
  //     //   }
  //     // }
  //     // make the table relayout, otherwize sometimes its layout is wrong
  //     setTimeout(() => {
  //       this.tableVisible = true;
  //     }, 0);
  //   });
  // }

  updateFilter() {
    const strFilter = this.filterStr.trim().toLowerCase();
    let filterItems = this.filterColumns.filter(filterItem => filterItem.selected);
    if (filterItems.length === 0) {
      filterItems = this.filterColumns;
    }
    // filter our data
    this.allUsers = this.allUsersList.filter(user => {
      if (!strFilter) {
        return true;
      }
      return filterItems.some(filterItem => {
        let searchTxt = user[filterItem.prop] || '';
        if (!(user[filterItem.prop] instanceof String)) {
          searchTxt = JSON.stringify(user[filterItem.prop]);
        }
        return searchTxt.toLowerCase().indexOf(strFilter) !== -1;
      });
    });
  }

  onKey(term: string): void {
    this.cancelTimeout();
    this.currentTimeout = setTimeout(() => {
      if (this.filterStr.trim().length !== 0) {


        if (this.teamUser === true) {
          this.loading = true;
          this.appSvc.searchGetAllUsersInTeam(this.teamId, this.page.size, this.filterStr).subscribe({next:res => {
            this.userCount = res;
            if (this.userCount.length > 0) {
              this.userData.emit(true);
            } else {
              this.userData.emit(false);
            }
            this.loading = false;
            this.page.totalElements = res.totalElements;
            this.allUsersList = res.data;
            this.allUsers = res.data;
            const arr2 = this.allUsers.map(x => ({ ...x, key: false }));
            this.allUsers = arr2;
            this.allUsers.forEach(element => {
              this.userIdExist.forEach(element2 => {
                if (element.userId === element2.userId) {
                  element.key = true;
                }
              });
            });
            this.useUserList = res.data;
            this.selected = [];
            setTimeout(() => {
              this.tableVisible = true;
            }, 0);
          }, error: () => {
            this.loading = false;
          }});
        } else if (this.role === 'ABBManager') {
          this.loading = true;
          this.appSvc.searchGetAllProjectUsers(this.teamId, 0, this.filterStr).subscribe({next:res => {
            if (res.data.length > 0) {
              this.userData.emit(true);
            } else {
              this.userData.emit(false);
            }
            this.loading = false;
            this.page.totalElements = res.totalElements;
            this.allUsersList = res.data;
            this.allUsers = res.data;
            this.useUserList = res.data;
            this.selected = [];
            setTimeout(() => {
              this.tableVisible = true;
            }, 0);
          }, error:() => {
            this.loading = false;
          }});
        } else if (this.role === 'projectManagers') {
          this.loading = true;
          this.appSvc.searchGetAllProjectManagers(this.teamId, this.page.size, this.filterStr).subscribe(res => {
            this.userCount = res;
            if (res.data.length > 0) {
              this.userData.emit(true);
            } else {
              this.userData.emit(false);
            }
            this.loading = false;
            this.page.totalElements = res.totalElements;
            this.allUsersList = res.data;
            this.allUsers = res.data;
            this.useUserList = res.data;
            this.selected = [];
            setTimeout(() => {
              this.tableVisible = true;
            }, 0);
          });
        } else if (this.role === 'projectSupports') {
          this.loading = true;
          this.appSvc.searchGetAllProjectSupports(this.teamId, this.page.size, this.filterStr).subscribe({next:res => {
            this.userCount = res;
            if (res.data.length > 0) {
              this.userData.emit(true);
            } else {
              this.userData.emit(false);
            }
            this.loading = false;
            this.page.totalElements = res.totalElements;
            this.allUsersList = res.data;
            this.allUsers = res.data;
            this.useUserList = res.data;
            this.selected = [];
            setTimeout(() => {
              this.tableVisible = true;
            }, 0);
          }, error:() => {
            this.loading = false;
          }});
        } else {
          this.loading = true;
          this.appSvc.searchGetAllUsers(this.teamId, 0, this.filterStr).subscribe({next:res => {
            this.loading = false;
            this.page.totalElements = res.totalElements;
            this.allUsersList = res.data;
            this.allUsers = res.data;
            this.useUserList = res.data;
            if(sessionStorage.getItem('currentRole') === 'Project Support'){
              const arr2 = this.allUsers.map(x => ({ ...x, isPS: false }));
              this.allUsers = arr2;
              this.allUsers.forEach(eleRole => {
                if(eleRole.userRolesDtoList.length > 0){
                  eleRole.userRolesDtoList.forEach(eleRolelist => {
                    if(eleRolelist.roleName === 'Project Manager') {
                      eleRole.isPS = true;
                    }
                  });
                }
      
              });
            }
            if(sessionStorage.getItem('currentRole') === 'System Support'){
              const arr2 = this.allUsers.map(x => ({ ...x, isPS: false }));
              this.allUsers = arr2;
              this.allUsers.forEach(eleRole => {
                if(eleRole.userRolesDtoList.length > 0){
                  eleRole.userRolesDtoList.forEach(eleRolelist => {
                    if(eleRolelist.roleName === 'System Manager') {
                      eleRole.isPS = true;
                    }
                  });
                }
      
              });
            }
            this.selected = [];
            setTimeout(() => {
              this.tableVisible = true;
            }, 0);
          }, error:() => {
            this.loading = false;
          }});
        }
      } else {
        this.ngOnInit();
      }
      // call api from here
    }, 700);
    // const filterStr = event.target.value.trim().toLowerCase();
    // if (filterStr.length !== 0) {
    //   this.search = true;
    // } else if (filterStr.length === 0) {
    //   this.search = false;
    // }
    // let result = [];
    // if (filterStr.length === 0) {
    //   result = [];
    //   this.allUsers = this.useUserList;
    // }
    // result = this.useUserList.filter(function (row) {
    //   if (row.hasOwnProperty('firstName')) {
    //     if (row.firstName.toLowerCase().indexOf(filterStr) >= 0) {
    //       return row;
    //     }
    //   }

    //   if (row.hasOwnProperty('userName')) {
    //     if (row.userName.toLowerCase().indexOf(filterStr) >= 0) {
    //       return row;
    //     }
    //   }
    // });
    // this.allUsers = result;
    // result = [];
  }
  cancelTimeout(): void {
    clearTimeout(this.currentTimeout);
    this.currentTimeout = undefined;
  }
  addOrEditMember(member) {
    const modalRef = this.modalService.open(EditUserComponent, member ? {
      centered: true,
      backdrop: 'static',
      backdropClass: 'full-screen-modal-backdrop',
      windowClass: 'full-screen-modal-window'
    } : {
        centered: true,
        size: 'lg',
        windowClass: 'medium-screen-modal-window'
      });
    if (member) {
      modalRef.componentInstance.memberInput = member.userId;
    }
    modalRef.componentInstance.mode = member ? 'full-screen' : 'as-modal';
    modalRef.result.then(ret => {
      // add new member
      this.loading = true;
      this.appSvc.getAllUsers(this.page.size, this.page.pageNumber).subscribe({next:res => {
        this.loading = false;
        this.page.totalElements = res.totalElements;
        this.allUsersList = res.data;
        this.allUsers = res.data;
        setTimeout(() => {
          this.tableVisible = true;
        }, 0);
      }, error:() => {
        this.modalService.dismissAll();
        this.loading = false;
      }});
      // if (!member) {
      //   if (this.allUsersList.length >= this.page.size) {
      //     this.page.size++;
      //   }
      //   this.allUsersList.push(ret);
      // } else {
      //   let index = this.allUsersList.indexOf(member);
      //   if (index >= 0) {
      //     this.allUsersList.splice(index, 1, ret);
      //   }
      //   index = this.selected.indexOf(member);
      //   if (index >= 0) {
      //     this.selected.splice(index, 1, ret);
      //   }
      // }
      this.selected = [];
      this.updateFilter();
    }, () => { });
  }
  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    selected.forEach((item) => {
      if (item.userId !== this.userId) {
        this.selected.push(item);
      }
    });
  }
  // onSelect({ selected }) {
  //   if (this.isSingleSelect) {
  //     this.selected = [selected[selected.length - 1]];
  //   } else {
  //     this.selected.splice(0, this.selected.length);
  //     this.selected.push(...selected);
  //   }
  // }

  delete() {
    const modalRef = this.modalService.open(DeleteAlertModalComponent, { centered: true });
    modalRef.componentInstance.deleteItemName = 'member(s)';
    modalRef.result.then(ret => {
      if (ret === 'delete') {
        const a = [];
        for (let i = 0; i < this.selected.length; i++) {
          a.push(this.selected[i].userId);
        }
        this.loading = true;
        this.appSvc.deleteUser(a).subscribe({next:res => {
          this.allUsersList = _.difference(this.allUsersList, this.selected);
          const modalRef1 = this.modalService.open(SuccesspopupComponent, { centered: true });
          modalRef1.componentInstance.title = 'Message';
          modalRef1.componentInstance.message = modalConstant.Member.deleted;
          this.appSvc.getAllUsers(this.page.size, this.page.pageNumber).subscribe({next:resp => {
            this.loading = false;
            this.page.totalElements = resp.totalElements;
            this.allUsersList = resp.data;
            this.allUsers = resp.data;
            setTimeout(() => {
              this.tableVisible = true;
            }, 0);
          }, error:() => {
            this.modalService.dismissAll();
            this.loading = false;
          }});
        }, error: (err) => {
          //const errorMessage = err.statusText;
          // this.modalService.dismissAll();
          this.loading = false;
        }});
        this.selected = [];
        this.updateFilter();
      }
    }, () => { });
  }
  // delete() {
  //   const modalRef = this.modalService.open(DeleteAlertModalComponent, { centered: true });
  //   modalRef.componentInstance.deleteItemName = 'users';
  //   modalRef.result.then(ret => {
  //     if (ret === 'delete') {
  //       this.allUsersList = _.difference(this.allUsersList, this.selected);
  //       this.selected = [];
  //       this.updateFilter();
  //     }
  //   }, () => { });
  // }

  setPage($event) {
    this.page.pageNumber = $event.offset;
    // this.loading = true;
    // this.spinnerService.show();
    // this.appSvc.getAllUsers(this.page.size, this.page.pageNumber).subscribe(res => {
    //   // this.loading = false;
    //   this.spinnerService.hide();
    //   this.page.totalElements = res.totalElements;
    //   this.allUsersList = res.data;
    //   this.allUsers = res.data;
    //   this.selected = [];
    //   this.updateFilter();
    // });
    this.ngOnInit();
  }

}
