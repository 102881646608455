import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})

export class EncrDecrService {

  constructor() {
  }

  encrypt(value, secretKey) {
    const iv = CryptoJS.lib.WordArray.random(16); // 128 bits (16 bytes) IV
    // Encrypt data using AES-CBC
    const ciphertext = CryptoJS.AES.encrypt(value, secretKey, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
    // Compute the HMAC using encrypted value and secret key
    const hmac = CryptoJS.HmacSHA256(ciphertext, secretKey);
    const hexHmac = hmac.toString(CryptoJS.enc.Hex);
    return ciphertext + ',' + hexHmac;
  }
}