import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from './application.service';
import { MainHeaderService } from 'src/app/components/main-header/main-header.service';
import * as RouteHelper from 'src/app/utility/routing.helper';
import { ToastrService } from 'ngx-toastr';
import { ConstService } from 'src/services/const.service';

@Injectable({ providedIn: 'root' })
export class GoToProjectService {
    constructor(private router: Router, private appSvc: ApplicationService, 
        private toastr: ToastrService, private constService: ConstService,
        private mainHeaderService: MainHeaderService) {
    }
    loading = false;

    goToProject() {
    this.loading = true;
    let role : string = sessionStorage.getItem('currentRole');
    let selected : string = sessionStorage.getItem('currentRoleSelected');
    let currentRole = role || selected;
    let previleges: string = sessionStorage.getItem('previleges');
    
        this.appSvc.loginUserKeycloak().subscribe(res => {
        let projectRoles = res.roles;
        if(role === 'Control Room' || selected === 'Control Room') {
            sessionStorage.setItem('currentRole', 'Control Room');
            sessionStorage.setItem('currentRoleSelected', 'Control Room');
            
        } else {
            sessionStorage.setItem('currentRole', projectRoles[0]);
            sessionStorage.setItem('currentRoleSelected', projectRoles[0]);   
        }
        currentRole = currentRole === 'Control Room' ? currentRole: projectRoles[0];
        sessionStorage.setItem('UserId', res.userId);
        sessionStorage.setItem('UserRoles', res.roles);
        sessionStorage.setItem('previleges', JSON.stringify(res));
        sessionStorage.setItem('isDeputyTeamLead', res.isDeputyTeamLead);
        this.mainHeaderService.onRolesUpdated();
        const projectName = sessionStorage.getItem('projectName');
        this.loading = false;
        if (currentRole === 'Quality SME') {
            window.open(RouteHelper.addProjectPrefix(`/execution/landing/qualitySME`), '_blank');
        } else if (currentRole === 'Execution Team Lead' || currentRole === 'Execution Lead') {
            window.open(RouteHelper.addProjectPrefix(`/execution/landing/teamLead`), '_blank')
        } else if (currentRole === 'Execution Owner') {
            window.open(RouteHelper.addProjectPrefix(`/execution/landing/ExecutionOwner`), '_blank'); 
        } else if (currentRole === 'Project Manager' || currentRole === 'Project Support' || currentRole === 'Project Manager (C)' || currentRole === 'Control Room') {
            window.open(RouteHelper.addProjectPrefix(`/dashboard/reliability-center`), '_blank'); 
        } else if (currentRole === 'Policy SME') {
            window.open(RouteHelper.addProjectPrefix(`/policy`), '_blank'); 
        } else if (currentRole === 'Maintenance Manager') {
            window.open(RouteHelper.addProjectPrefix(`/planning/manager`), '_blank'); 
        } else if (currentRole === 'Operation SME' || currentRole === 'Remote Operator') {
            window.open(RouteHelper.addProjectPrefix(`/operations/view`), '_blank');
        } else if (currentRole === 'Planner') {
            window.open(RouteHelper.addProjectPrefix(`/planning/planner`), '_blank');
        } else {
            window.open(RouteHelper.addProjectPrefix('/projectdashboard'), '_blank'); 
        }
        sessionStorage.setItem('projectId', this.constService.emptyGuid);
        sessionStorage.setItem('projectName', '');
        this.appSvc.loginUserKeycloak().subscribe(res => {
            let projectRoles = res.roles;
            sessionStorage.setItem('UserId', res.userId);
            sessionStorage.setItem('UserRoles', res.roles);
            sessionStorage.setItem('previleges', JSON.stringify(res));
            sessionStorage.setItem('isDeputyTeamLead', res.isDeputyTeamLead);
            
            if(this.appSvc.isControlRoomProjEnv()) {
                sessionStorage.setItem('currentRole', 'Control Room');
                sessionStorage.setItem('currentRoleSelected', 'Control Room');
            } else {
                sessionStorage.setItem('currentRole', role);
                sessionStorage.setItem('currentRoleSelected', role);
                //sessionStorage.setItem('isProjectEnv', 'false');
            }
            this.mainHeaderService.onRolesUpdated();
        });
        });
    
    }
}
