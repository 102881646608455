import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApplicationService } from '../../../services/application.service';
import { Router } from '@angular/router';
import each from 'lodash/each';
import camelCase from 'lodash/camelCase';
import isEmpty from 'lodash/isEmpty';
import { SuccesspopupComponent } from '../../containers/dashboard/modals/successpopup/successpopup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import modalConstant from '../../../assets/modal-constants.json';
import * as RouteHelper from 'src/app/utility/routing.helper';

@Component({
  selector: 'app-asset-info',
  templateUrl: './asset-info.component.html',
  styleUrls: ['./asset-info.component.scss']
})
export class AssetInfoComponent implements OnInit {
  form: FormGroup;
  @Input() data: any = {};
  @Input() viewMode = false;
  @Input() nodeType = '';
  @Input() showDelete = false;
  @Output() close = new EventEmitter();
  @Output() deleteMarker = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() sld = new EventEmitter();
  classId;
  selected = 'General information';
  equipment: any = {};
  equipmentTypes: any = {};
  loading = false;
  hide = false;
  @Input() queryParam = '';
  submitted = false;
  projectId;
  regionId;
  custId;
  countryShortName;
  priviledges: any = {};
  isRole: boolean;
  currentRole: any;
  roleselected: any;
  @Input() forceViewModeActive: boolean;
  active = 1;

  constructor(
    private formBuilder: FormBuilder, private router: Router, private modalService: NgbModal,
    private dataService: ApplicationService) {
    this.priviledges = this.dataService.getUserPrivilages();
    this.registerFields({});
    this.roleselected = sessionStorage.getItem('currentRoleSelected');
    this.currentRole = sessionStorage.getItem('currentRole');
    this.isRole = this.roleselected === 'Project Manager (C)' || 
    this.roleselected === 'Control Room' ? true : false;
      if (this.isRole === false && this.roleselected === null) {
        this.isRole = this.currentRole === 'Project Manager (C)' ||
        this.currentRole === 'Control Room' ? true : false;
      }
  }

  getFieldName(name) {
    return camelCase(name);
  }

  ngOnInit() {
    this.dataService.GetDefaultProjectId().subscribe(resp => {
      this.projectId = resp.item2.projectid;
      this.regionId = resp.item2.custareaid;
      this.custId = resp.item2.custid;
      this.countryShortName = resp.item2.countryshortname;
      // this.loadMapWithData();
    });
    if (this.nodeType === 'Source') {
      this.classId = '269b3ae8-5726-4645-8bf2-67dc2f957d89';
    }
    if (this.nodeType === 'Load') {
      this.classId = '9d08daa4-f5f9-4694-a32e-efc4f0430ceb';
    }
    if (this.nodeType === 'Joint') {
      this.classId = 'cf1d187f-6cc7-49cf-9f1e-69d83e4af406';
    }
    if (this.nodeType === 'SubStation') {
      this.classId = 'fd5d27ff-244c-48c7-9fd4-7381143a2b73';
    }
    this.loading = true;
    this.loadProperties();
  }

  loadProperties() {
    if (this.nodeType === '') {
      return false;
    }
    this.loading = true;
    this.dataService.getAssetInfoForNodes(this.nodeType)
      .subscribe({
        next: (fields) => {
        this.equipment = fields;
        this.registerFields(this.equipment);
        this.loading = false;
      }, error : () => {}});
  }

  only_numbers(value: string, event: any) {
    let k = event.keyCode;
    //  return ((k > 47 && k < 58) || k === 46);
    // const input = (document.getElementById('myInput') as HTMLInputElement).value;
    if (k === 8 || k === 37 || k === 45) {
        return true;
    }
     else if (k === 46 && value.indexOf('.') !== -1) {
        return false;
    }
    else if (k > 31 && k !== 46 && (k < 48 || k > 57)) {
        return false;
    }
    return true;
}

  registerFields(equipment) {
    const fieldObject = {};
    let data: any = this.data || {};
    if (!isEmpty(data)) {
      data = { ...data };
    }
    const propertiesByCategory = equipment.propertiesByCategory;
    if (propertiesByCategory && propertiesByCategory.length > 0) {
      this.selected = propertiesByCategory[0].category;
    }
    const fields = {};
    if (propertiesByCategory) {
      each(propertiesByCategory, (category) => {
        each(category.properties, (property) => {
          const fieldName = this.getFieldName(property.propertyLabel);
          fields[fieldName] = [data[fieldName] || property.value, Validators.required];
          if (property.propDataTypeName === 'Customizable') {
            if (property.options && data[fieldName] && property.options.indexOf(data[fieldName]) === -1 && data[fieldName] !== '') {
              fields[fieldName] = ['Others', Validators.required];
            }
            fields[fieldName + 'Etc'] = [data[fieldName] || property.value, Validators.required];
          }

        });
      });
    }
    this.form = this.formBuilder.group(fields);

    if (isEmpty(equipment)) {
      this.loadProperties();
    }
  }

  /**
   * Validate sub station
   * @param name - field name
   * @returns - valid or not
   */
  validation(name): any {
    return this.submitted && this.form.controls[name].errors && this.form.controls[name].errors.required;
  }
  reloadPage() {
    this.router.navigateByUrl(RouteHelper.addProjectPrefix(`/dashboard/project-modelling`), { skipLocationChange: true }).then(() =>
      this.router.navigate([RouteHelper.addProjectPrefix(`/project-modelling/1`)]));
    // this.activeModal.dismiss('close');

  }
  saveData(test) {
    if (test === 'save' || test === 'test') {
      const id = this.data.id;
      const nodeType = this.nodeType;
      this.submitted = true;
      // this.save.emit({
      //   isEdit: false,
      //   id,
      //   nodeType,
      //   data: {
      //     ...this.form.value,
      //   }
      // });

      const commentData = {
        'projectId': this.projectId,
        'regionId': this.regionId,
        'custId': this.custId,
        'eqptMfgId': '1f111df2-21a5-5fda-8dd2-7439d45cee01',
        'longitude': this.form.value.longitude,
        'latitude': this.form.value.latitude
      };

      if (nodeType === 'external source' || nodeType === 'Source') {
        const formData = {
          'nodeType': 'external source',
          'externalSourceId': id,
          'sourceReference': this.form.value.sourceReference,
          'sldBayReference': this.form.value.sldBayReference,
          'substationReference': this.form.value.substationReference,
          'typeOfSource': this.form.value.typeOfSource !== 'Others' ? this.form.value.typeOfSource : this.form.value.typeOfSourceEtc,
          'ratedPower': this.form.value.ratedPower,
          'operatingVoltage': this.form.value.operatingVoltage,
          ...commentData,
          'altitude': this.form.value.altitude,
        };
        this.dataService.addAndUpdateSource(formData)
        .subscribe({
          next: resp => {
          if (test !== 'test') {
            const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
            modalRef.componentInstance.title = 'Message';
            modalRef.componentInstance.message = 'External source' + ' ' + modalConstant.assertDiagnosis.update;

            this.reloadPage();
          }
        },
        error: () => {this.reloadPage();}
      });
      }
      if (nodeType === 'external load' || nodeType === 'Load') {
        const formData = {
          'nodeType': 'external load',
          'externalLoadId': id,
          'sldBayReference': this.form.value.sldBayReference,
          'loadReference': this.form.value.loadReference,
          'substationReference': this.form.value.substationReference,
          'typeOfLoad': this.form.value.typeOfLoad !== 'Others' ? this.form.value.typeOfLoad : this.form.value.typeOfLoadEtc,
          'ratedPower': this.form.value.ratedPower,
          'ratedPowerFactor': this.form.value.ratedPowerFactor,
          ...commentData,
          'altitude': 0
        };
        this.dataService.addAndUpdateLoad(formData).subscribe({next:resp => {
          if (test !== 'test') {
            const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
            modalRef.componentInstance.title = 'Message';
            // modalRef.componentInstance.message='External Load' + ' ' + modalConstant.assertDiagnosis.update;
            modalRef.componentInstance.message = resp.errorMessage;
            this.reloadPage();
          }
        }, error:() => {
          this.reloadPage();
        }});
      }
      if (nodeType === 'joint' || nodeType === 'Joint') {
        const formData = {
          'nodeType': 'joint',
          'externalJointId': id,
          'nodeReference': this.form.value.nodeReference,
          'locationReference': this.form.value.locationReference,
          ...commentData,
          'altitude': this.form.value.altitude
        };

        if(formData.altitude!="" && formData.altitude % 1 !=0 ){
          const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
          modalRef.componentInstance.title = 'Message';
          modalRef.componentInstance.message = 'Invalid input Altitude allow only type of number';
          this.reloadPage();
          return false;
        }


        this.dataService.addAndUpdateJoint(formData)
        .subscribe({
          next: (resp) => {
          if (test !== 'test') {
            const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
            modalRef.componentInstance.title = 'Message';
            modalRef.componentInstance.message = nodeType + ' ' + modalConstant.assertDiagnosis.update;

            this.reloadPage();
          }
        }, error: () => { this.reloadPage();}});
      }

      if (nodeType === 'substation' || nodeType === 'Substation' || nodeType === 'SubStation') {
        const formData = {
          ...commentData,
          'nodeType': nodeType,
          'altitude': this.form.value.altitude,
          'substationId': id,
          'substationName': this.form.value.name,
          'ssClassId': this.classId,
          'ssClassName': nodeType,
          'reference': this.form.value.name,
          'owner': this.form.value.owner,
          'administrator': this.form.value.administer,
          'constructionArea': this.form.value.constructionArea,
          'totalArea': this.form.value.totalArea,
          'freeSpace': this.form.value.freeSpace,
          'controlRooms': this.form.value.controlRooms,
          'installationYear': this.form.value.installationYear,
          'commisioningYear': this.form.value.commisioningYear,
          'name': this.form.value.contactName,
          'position': this.form.value.position,
          'email': this.form.value.email,
          // 'substationReference': this.form.value.substationReference,
          'substationOperator': this.form.value.substationOperator,
          'voltageClass': this.form.value.voltageClass !== 'Others' ? this.form.value.voltageClass : this.form.value.voltageClassEtc,
          'typeOfSubstation': this.form.value.substationType !== 'Others' ? this.form.value.substationType : this.form.value.substationTypeEtc,
          'switchgearType': this.form.value.switchgearType,
          'city': this.form.value.city,
          'country': this.form.value.country,
          'industrialSector': this.form.value.industrialSector !== 'Others' ? this.form.value.industrialSector : this.form.value.industrialSectorEtc,
          'installationOfAssets': this.form.value.installationOfAssets
        };



 if(formData.altitude!="" && formData.altitude % 1 !=0 ){
  const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
  modalRef.componentInstance.title = 'Message';
  modalRef.componentInstance.message = 'Invalid input Altitude allow only type of number';
  this.reloadPage();
  return false;
}

          this.dataService.addAndUpdateSubstation(formData).subscribe({
            next:  (resp) => {
            if (test !== 'test') {
              const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
              modalRef.componentInstance.title = 'Message';
              modalRef.componentInstance.message = 'Substation' + ' ' + modalConstant.assertDiagnosis.update;
              this.reloadPage();
            }

          },
          error: () => {this.reloadPage();}
        });
      }
    }
      this.close.emit();

  }

  messagePopup(){

        const modalRefr = this.modalService.open(SuccesspopupComponent, { centered:true });
        modalRefr.componentInstance.title = 'Warning';
        modalRefr.componentInstance.message = 'Commissioning year should be greater than installation year!';

  }

  deleteData() {
    if (this.nodeType === 'Source') {
      this.nodeType = 'External Source';
    }
    if (this.nodeType === 'Joint') {
      this.nodeType = 'Joint';
    }
    if (this.nodeType === 'SubStation') {
      this.nodeType = 'Substation';
    }
    if (this.nodeType === 'Load') {
      this.nodeType = 'External Load';
    }
    const id = this.data.id;
    const nodeType = this.nodeType;
    this.submitted = true;
    this.deleteMarker.emit({
      id,
      nodeType
    });
    this.close.emit();
  }
  hide_option() {
    if (this.hide === true) {
        this.hide = false;
    } else {
        this.hide = true;
    }
  }
  getAssetInfoTab() {
    if (this.priviledges.GISSLDEditorEdit) {
      this.saveData('test');
    }
    let data;
    if (this.data.nodeReference) {
      data = this.data.nodeReference;
    }
    if (this.data.name) {
      data = this.data.name;
    }
    if (this.data.sourceReference) {
      data = this.data.sourceReference;
    }
    if (this.data.loadReference) {
      data = this.data.jointReference;
    }
    if (this.data.loadReference) {
      data = this.data.loadReference;
    }
    this.router.navigate([RouteHelper.addProjectPrefix(`/substationinfo`)], { queryParams: { substationId: this.data.id, classId: this.classId, equipmentName: this.nodeType, name: data } });
    // /substationinfo/{{equipment.equipmentName === 'Substation' ? '1' : '2'}}
  }
}
