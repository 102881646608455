<div *ngIf="!(online$ | async) && isRefreshToken" class="system-notifications">
  <span class="warn">You are Offline. Please check your internet.</span>
</div>

<div class="loading-box" *ngIf="loading">
  <div class="img" alt="loading"></div>
</div>
<header *ngIf="!ishide" class="main-header" style="z-index: 100001;" [ngStyle]="{'display':display}">
  <div class="left-content">
    <div class="logo-container">
      <a #hamburger class="hamburger" [class.active]="openSide" (click)="openSlideMenu()" href="javascript:;"><i
          class="icon icon-hamburger-icon" aria-hidden="true"
          *ngIf="roleType !== 'Admin' && roleType !== 'Tenant Admin'"></i></a> 
    </div>
    <a class="hitachi-logo">
        <i class="icon icon-hitachi-logo" aria-hidden="true"></i>
      </a> 
    <div class="left-item">
    <a class="logo" style="opacity:unset;display: inline-grid;">
        <span i18n>{{metadataInfo?.title}}</span>
      </a>
    </div>
    <div class="left-item" *ngIf="this.isSystemEnv">
      <span i18n>{{metadataInfo?.systemDeploymentName}}</span>
    </div>
    <div class="left-item" *ngIf="this.isSystemEnv">
    <span i18n>Version {{buildVersionNumber}}</span>
    </div>
    <div class="left-item" *ngIf="!hideClientProjectDetail && !this.isSystemEnv">
      <span i18n>{{custName}}</span>
    </div>
    <div class="left-item" *ngIf="!hideClientProjectDetail && !this.isSystemEnv">
      <span i18n>{{projName}}</span>
    </div>
    <div class="left-item kernel-name" *ngIf="!this.isSystemEnv">
      <span i18n *ngIf="roleType !== 'Admin'">{{selectedVersion.name}}</span>
    </div>
    <div i18n *ngIf="userType==='PM' && project && !this.isSystemEnv">{{project.company}}</div>
    <div i18n *ngIf="userType==='PM' && project && !this.isSystemEnv">{{project.location}}</div>
    <div class="light-txt" i18n *ngIf="userType==='PM' && project">Kernel v{{project.kernelVersion}}</div>

    <!-- <div *ngIf="roleType === 'System Manager'" class="version-switch" (click)="clickedInside($event)" #version>
      <div class="selected" (click)="versionPopup = !versionPopup;">
        <span [class.active]="selectedVersion.active"></span>
        <p>{{selectedVersion.name}}</p><i class="icon icon-dropdown" aria-hidden="true" [class.up]="rolePopup"></i>
      </div>
      <div class="role-popup" *ngIf="versionPopup">
        <a href="javascript:;" *ngFor="let item of versionList" (click)="changeVersion(item)"
          [class.hidden]="selectedVersion.name === item.name">
          <span class="status" [class.active]="item.active"></span>
          {{item.name}}
          <span class="highlight" [class.active]="checkHighlightRole(item)"></span>
        </a>
      </div>
    </div> -->

    <div class="left-item" *ngIf="!hideClientProjectDetail && !this.isSystemEnv">

      <span i18n *ngIf="buildVersionNumberError">{{buildVersionNumber}}</span>
      <span i18n *ngIf="!buildVersionNumberError">Version {{buildVersionNumber}}</span>
    </div>


  </div>
  <div class="header-opts">

    <div class="alarms" (click)="clickAlarmIcon()" *ngIf="showAlarms()">
      <span class="status"  [class.unread]="!isAllAcknowledged()" [ngClass]=getClass()></span>
      <i header-icon-severity aria-hidden="true" [alarms]="this.appSvc.allAlarms"></i>
    </div>
    <div class="alarm-container" *ngIf="showAlarms()" [class.active]="isAlarmOpen" [class.show-more]="alarmPage > 0">
      <div class="header">
        <div class="title">Alarms & Events</div>
        <div class="actions">
          <span (click)="clickAlarmSettings()">Settings</span>
        </div>
      </div>
      <div class="list-container" [class.show-more]="alarmPage > 0">
        <app-alarm-item *ngFor="let item of appSvc.alarms" [alarm]="item" (clickedAlarm)="clickedAlarm($event)" (acknowledgedAlarm)="acknowledgedAlarm($event)">
        </app-alarm-item>
      </div>
      <div class="more"
        [class.active]="this.appSvc.allAlarms?.length > 6 && this.appSvc.alarms?.length < this.appSvc.allAlarms?.length"
        (click)="clickLoadMoreAlarms($event)">Load more</div>
    </div>

    <div class="notifications" (click)="clickBell()" *ngIf="roleType !== 'Admin' && roleType !== 'Tenant Admin'">
      <span class="status" [class.unread]="!isMarkAllRead()"></span>
      <i class="icon icon-bell new" aria-hidden="true"></i>
    </div>
    <div class="notification-container" [class.active]="isOpen" [class.show-more]="page > 0">
      <div class="header">
        <div class="title">Notifications</div>
        <div class="actions">
          <span (click)="clickMarkAllRead()">Mark all as read</span>
          <span (click)="clickSettings()">Settings</span>
        </div>
      </div>
      <div class="list-container" [class.show-more]="page > 0">
        <app-notification-item *ngFor="let item of appSvc.notifications" [notification]="item"
          (clickNotification)="clickNotification($event)" [isMarkAllRead]="isMarkAllRead()"></app-notification-item>
      </div>
      <div class="more"
        [class.active]="this.appSvc.allNotifications?.length > 7 && this.appSvc.notifications?.length < this.appSvc.allNotifications?.length"
        (click)="clickLoadMore($event)">Load more</div>
    </div>

    <div *ngIf="userType !== 'Admin'" class="role-switch" (click)="clickedInside($event)" #role>
      <div class="selected" (click)="rolePopup = !rolePopup;">
        <span [class.active]="selectedRole.active"></span>
        <p>{{selectedRole.name}}</p><i class="icon icon-down" aria-hidden="true" [class.up]="rolePopup"></i>
      </div>
      <div class="role-popup" *ngIf="rolePopup">
        <a href="javascript:;" *ngFor="let item of displayRoles" (click)="changeRole(item)"
          [class.hidden]="selectedRole.name === item.name">
          <span class="status" [class.active]="item.active"></span>
          {{item.name}}
          <span class="highlight" [class.active]="checkHighlightRole(item)"></span>
        </a>
      </div>
    </div>

    <div class="right_part">


      <a class="lnk-logger">{{userName}}
        <span class="logger-thumb">
          <img *ngIf="userImage; else templateName" [src]="userImage" alt="Avatar" class="icon icon-avatar" />
          <ng-template #templateName>
            <i style="width: 30px; height: 30px;" aria-hidden="true" class="icon icon-avatar"></i>
          </ng-template>
        </span>
      </a>
      <div class="dropdown" (click)="logOut()">Logout</div>
    </div>
  </div>
</header>
<aside #navbar [class.hide]="!openSide">
  <nav class="top">
    <ul>
        <li *ngFor="let item of topMenu;" [class.active]="item.active || this.router.isActive(item.url, false)"
        [class.disabled]="item.disabled"><a href="javascript:;" (click)="goTo(item)">
          <i class="icon {{item.icon}}" aria-hidden="true"></i>{{item.name}}</a></li>
    </ul>
  </nav>
  <nav class="bottom" *ngIf="bottomMenu?.length > 0">
    <ul>
      <li *ngFor="let item of bottomMenu;"><a href="javascript:;" (click)="goTo(item.url)"><i
            class="icon {{item.icon}}" aria-hidden="true"></i>{{item.name}}</a></li>
    </ul>
  </nav>
</aside>
