import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal,NgbModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { ApplicationService } from '../../../../../services/application.service';
import { SuccesspopupComponent } from 'src/app/containers/dashboard/modals/successpopup/successpopup.component';

@Component({
  selector: 'app-create-substation-modal',
  templateUrl: './create-substation-modal.component.html',
  styleUrls: ['./create-substation-modal.component.scss']
})
export class CreateSubstationModalComponent implements OnInit {
  form: FormGroup;
  submitted: boolean;
  @Input() edit: boolean;
  @Input() masterData: any;
  @Input() marker: any;
  subStationTypes: any = [];
  switchgearTypes: any = [];
  userRolesObject: any = [];
  years: any = _.range(1900, 2051);
  constructor(
    private modalService: NgbModal,private dataService: ApplicationService, private formBuilder: FormBuilder, public activeModal: NgbActiveModal, private router: Router) {
  }

  ngOnInit() {

    this.userRolesObject = this.dataService.getUserPrivilages();
    this.dataService.getMasterSubstationData()
      .subscribe({next:resp => {
        this.subStationTypes = resp.returnObj.enums[0];
        this.switchgearTypes = resp.returnObj.enums[1];
      },error: () => {
      }});
    const properties = this.marker ? this.marker.getProperties() : {};
    this.form = this.formBuilder.group({
      name: [properties.name, Validators.required],
      // region: [properties.region, Validators.required],
      reference: [properties.reference],
      type: [properties.type],
      owner: [properties.owner],
      administer: [properties.administer],
      longitude: [properties.longitude, Validators.required],
      latitude: [properties.latitude, Validators.required],
      altitude: [properties.altitude],
      constructionArea: [properties.constructionArea],
      totalArea: [properties.totalArea],
      freeSpace: [properties.freeSpace],
      controlRooms: [properties.controlRooms],
      switchgear: [{ value: properties.switchgear, disabled: false }],
      // voltageLevel: [{ value: properties.voltageLevel, disabled: false }],
      installationYear: [{ value: properties.installationYear, disabled: false }, Validators.required],
      commisioningYear: [{ value: properties.commisioningYear, disabled: false }, Validators.required],
      // possibilityToExtend: [{ value: properties.possibilityToExtend, disabled: false }],
    });
  }

  /**
   * get form controllers
   */
  get f(): any {
    return this.form.controls;
  }
  /**
   * Validate sub station
   * @param name - field name
   * @returns - valid or not
   */
  validation(name): any {
    //debugger;
    // if (name !== 'installationYear' && name !== 'commisioningYear') 
    
    return this.submitted && this.form.controls[name].errors && this.form.controls[name].errors.required;
    
    // else {
    //   return this.submitted && !this.form.controls[name].value;
    // }
  }

  validate(){ 
      if(this.f.commisioningYear.value<this.f.installationYear.value){ 
          const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
          modalRef.componentInstance.title = 'Warning';     
          modalRef.componentInstance.message = 'Commissioning year should be greater than installation year!';
          return true;
         } else if(this.specialCharValidation('name')) {
          const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
          modalRef.componentInstance.title = 'Warning';     
          modalRef.componentInstance.message = 'Substation reference should not include any special character, except _ and - symbols.';
          return true;
         } 
      return false;
  }
  specialCharValidation(substationRef){    
    const specailCharRegexp: RegExp = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
    const noWhiteSpaceRegexp: RegExp = /^\s+|\s+$/g; 
    const value = this.form.controls[substationRef].value;
        if(value && specailCharRegexp.test(value)){          
          return true;
        }
        else if(noWhiteSpaceRegexp.test(value)){          
          return true;
        }
        else{
          return false;
        }
  }
  /**
   * Submit form
   */
  onSubmit() {
    // debugger
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    if(this.validate()===false){
          this.activeModal.close({
            nodeType: 'substation',
            type: 'save', data: {
              id: this.marker.getId(),
              name: this.f.name.value,
              // region: this.f.region.value,
              type: this.f.type.value,
              owner: this.f.owner.value,
              administer: this.f.administer.value,
              longitude: this.f.longitude.value,
              latitude: this.f.latitude.value,
              altitude: this.f.altitude.value,
              constructionArea: this.f.constructionArea.value,
              totalArea: this.f.totalArea.value,
              freeSpace: this.f.freeSpace.value,
              controlRooms: this.f.controlRooms.value,
              switchgear: this.f.switchgear.value,
              // voltageLevel: this.f.voltageLevel.value,
              installationYear: this.f.installationYear.value,
              commisioningYear: this.f.commisioningYear.value,       
        // possibilityToExtend: this.f.possibilityToExtend.value,
          }
        });
    }
  }

  goToSLD() {
    if (this.form.invalid) {
      return;
    }
    if(this.validate()===false){
      this.activeModal.close({
        nodeType: 'substation',
        type: 'sld', data: {
          id: this.marker.getId(),
          name: this.f.name.value,
          type: this.f.type.value,
          owner: this.f.owner.value,
          administer: this.f.administer.value,
          longitude: this.f.longitude.value,
          latitude: this.f.latitude.value,
          altitude: this.f.altitude.value,
          constructionArea: this.f.constructionArea.value,
          totalArea: this.f.totalArea.value,
          freeSpace: this.f.freeSpace.value,
          controlRooms: this.f.controlRooms.value,
          switchgear: this.f.switchgear.value,
          installationYear: this.f.installationYear.value,
          commisioningYear: this.f.commisioningYear.value,
        }
      });
    }  
  }
  /**
   * Delete marker
   */
  deleteMarker() {
    this.activeModal.close({ type: 'delete', data: this.marker.getId() });
  }  
}
