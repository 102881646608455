import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SuccesspopupComponent } from '../../dashboard/modals/successpopup/successpopup.component';
import { SettingsService } from 'src/services/settings.service';
import { KeycloakService } from 'keycloak-angular';
import * as RouteHelper from 'src/app/utility/routing.helper';
// const API_URL1 = environment.apiURL1;

@Injectable()
export class HelperService {
  sldurl: any;
  constructor(
    private http: HttpClient,
    private router: Router, private modalService: NgbModal, config: NgbModalConfig,
    mysettingsService: SettingsService,private keycloak:KeycloakService
  ) { config.backdrop = 'static';
  this.sldurl = mysettingsService.settings.SLDApiURL;}

  /**
  * Performs a request with `get` http method.
  * @param url the url
  * @param options the request options
  * @returns {Observable<any>}
  */
  get(url: string, options?: any): Observable<any> {
    const API = this.sldurl;
    // if (url.split("/")[1] === "assets") {
    //   API = API_URL1;
    // }
    return this.http
      .get(API + url, this.requestOptions(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }
  getData(url: string, options?: any): Observable<any> {
    const API = this.sldurl;
    // if (url.split("/")[1] === "assets") {
    //   API = API_URL1;
    // }
    return this.http
      .get(API + url, this.requestOptionsData(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }

  getFile(url: string, options?: any): Observable<any> {
    const API = this.sldurl;
    // if (url.split("/")[1] === "assets") {
    //   API = API_URL1;
    // }
    return this.http
      .get(API + url, this.requestOptions(options, '', true))
      .pipe(catchError(err => this.catchAuthError(err)));
  }

  /**
  * Performs a request with `post` http method.
  * @param url the url
  * @param data the Post request Payload
  * @param options the request options
  * @returns {Observable<any>}
  **/

  post(url: string, data: any, options?: any): Observable<any> {
    // return this.http.post(API_URL + url, data, this.requestOptions(options));
    return this.http.post(this.sldurl + url, data, this.requestOptions(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }
  // createContact(contact){

  // }
  /**
  * Performs a request with `post` http method.
  * @param url the url
  * @param PutData the Post request Payload
  * @param options the request options
  * @returns {Observable<any>}
  **/

  put(url: string, PutData: any, options?: any): Observable<any> {
    return this.http.put(this.sldurl + url, PutData, this.requestOptions(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }

  /**
 * Performs a request with `patch` http method.
 * @param url the url
 * @param PatchData the Patch request Payload
 * @param options the request options
 * @returns {Observable<any>}
 **/

  patch(url: string, PatchData: any, options?: any): Observable<any> {
    // return this.http.patch(API_URL + url, PatchData, this.requestOptions(options));
    //
    return this.http.patch(this.sldurl + url, PatchData, this.requestOptions(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }

  delete(url: string, options?: any): Observable<any> {
    return this.http.delete(this.sldurl + url, this.requestOptions(options))
      .pipe(catchError(err => this.catchAuthError(err)));
  }

  // getCurrentRole() {
  //   const roleselected = sessionStorage.getItem('currentRoleSelected');
  //   const currentRole = sessionStorage.getItem('currentRole');
  //   if(roleselected === null) {
  //     return currentRole;
  //   } else {
  //     return roleselected;
  //   }
  // }
  /**
   * Request options.
   * @param options
   * @param method the method
   * @returns {RequestOptionsArgs}
   */
  private requestOptionsData(options?: any, method?: string, isFile?: boolean): any {
    if (!options) {
      options = {};
    }
    const headers = new HttpHeaders({
      'ProjectId' : RouteHelper.getProjectIdForAPIHeader(),
    });
    if (isFile) {
      options.responseType = 'arraybuffer';
    }
    options.headers = headers;
    // }
    return options;
  }
  private requestOptions(options?: any, method?: string, isFile?: boolean): any {
    if (!options) {
      options = {};
    }
    const roleselected = sessionStorage.getItem('currentRoleSelected');
    const currentRole = sessionStorage.getItem('currentRole');
    const headers = new HttpHeaders({
      'Authorization': sessionStorage.getItem('AccessToken'),
      'ProjectId' : RouteHelper.getProjectIdForAPIHeader(),
      'RoleName' : roleselected || currentRole || ''
    });
    options.headers = headers;
    if (isFile) {
      options.responseType = 'arraybuffer';
    }
    return options;
  }
  /**
   * catches the auth error
   * @param error the error response
   */
  catchAuthError(error): Observable<Response> {
    if (error.status === 409) {
      const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
      modalRef.componentInstance.title = 'Warning';
      modalRef.componentInstance.message = 'Conflict occur due to duplicate data provided';
    }
    if (error.status === 401) {
      const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
      modalRef.componentInstance.title = 'Warning';
      modalRef.componentInstance.message = 'User unauthorized. Please logout and login again.';

      sessionStorage.clear();
      //localStorage.clear();
      this.keycloak.logout(window.location.origin+'/login').then(() => this.keycloak.clearToken());
      return throwError(() => new Error(error));
    } else if (error.status === 403) {
      // sessionStorage.clear();
      // this.router.navigate(['/']);
      return throwError(() => new Error(error));
    }
    else if (error.status === 422) {
       const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
       modalRef.componentInstance.title = 'Warning';
      modalRef.componentInstance.message = error.error.errorMessage;
      return throwError(() => new Error(error));
    } else if (error.status === 400 && error.error) {
      const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
      modalRef.componentInstance.title = 'Warning';
      modalRef.componentInstance.message = error.error.errorMessage || error.error;
    } else {
      if (error.error.errorMessage !== 'Substation is not yet commissioned.The risk matrix will show once the substation is commissioned') {
        const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
        modalRef.componentInstance.title = 'Error';
        modalRef.componentInstance.message = 'An error occurred please try again later';
        // sessionStorage.clear();
        // this.router.navigate(['/']);
        return throwError(() => new Error(error));
      }
    }
    return throwError(() => new Error(error));
  }
}
