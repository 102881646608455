<div class="loading-box" *ngIf="loading">
    <div class="img" alt="loading"></div>
</div>
<div class="risk-container" [class.chart-only]="chartOnly">
    <div class="risk-matrix-wrap flexible" [class.chart-false-only]="chartOnly===false" #riskMatrix>
        
        <svg viewBox="0 0 126 126" version="1.1">
      <g transform="translate(15,11)" viewBox="0 0 100 100" width="100" height="80"
        class="svg-graph drag-area flexible">
        <g class="bg" [class.absolute]='isAbsoluteActive'>
          <rect width="100" height="102" />
        </g>
        <g class="data" *ngIf="!isAbsoluteActive">
          <ng-container *ngFor="let row of riskMatrixValue.flexible; let rowIndex = index;">
            <ng-container *ngFor="let column of row; let colIndex = index;">
              <polygon [class.low-risk]="column.split(',')[0] === 'low'"
                [class.medium-risk]="column.split(',')[0] === 'medium'"
                [class.high-risk]="column.split(',')[0] === 'high'" 
                [class.no-cursor]="userRole === 'Project Manager (C)' || userRole === 'Control Room'" [attr.points]="(colIndex * 10) +',' +(rowIndex * 10) +' ' +  ((colIndex + 1) * 10)
                       + ',' + ((rowIndex + 1) * 10) + ' '+(colIndex * 10)+','+ ((rowIndex + 1) * 10)
                       +' ' + (colIndex * 10) +',' +(rowIndex * 10)"/>
              <polygon [class.low-risk]="column.split(',')[1] === 'low'"
                [class.medium-risk]="column.split(',')[1] === 'medium'"
                [class.high-risk]="column.split(',')[1] === 'high'" 
                [class.no-cursor]="userRole === 'Project Manager (C)' || userRole === 'Control Room'" [attr.points]="(colIndex * 10) +',' +(rowIndex * 10) +' ' + ((colIndex + 1) * 10)
                       + ',' + ((rowIndex + 1) * 10) + ' '+ ((colIndex + 1) * 10) +','+(rowIndex * 10)
                       +' ' + (colIndex * 10) +',' +(rowIndex * 10)"/>
            </ng-container>
          </ng-container>
        </g>
        <g class="coord" *ngIf="!isAbsoluteActive">
          <line x1="10" y1="0" x2="10" y2="100" />
          <line x1="20" y1="0" x2="20" y2="100" />
          <line x1="30" y1="0" x2="30" y2="100" />
          <line x1="40" y1="0" x2="40" y2="100" />
          <line x1="50" y1="0" x2="50" y2="100" />
          <line x1="60" y1="0" x2="60" y2="100" />
          <line x1="70" y1="0" x2="70" y2="100" />
          <line x1="80" y1="0" x2="80" y2="100" />
          <line x1="90" y1="0" x2="90" y2="100" />

          <line y1="10" x1="0" y2="10" x2="100" />
          <line y1="20" x1="0" y2="20" x2="100" />
          <line y1="30" x1="0" y2="30" x2="100" />
          <line y1="40" x1="0" y2="40" x2="100" />
          <line y1="50" x1="0" y2="50" x2="100" />
          <line y1="60" x1="0" y2="60" x2="100" />
          <line y1="70" x1="0" y2="70" x2="100" />
          <line y1="80" x1="0" y2="80" x2="100" />
          <line y1="90" x1="0" y2="90" x2="100" />

          <line x1="10" y1="0" x2="100" y2="90" />
          <line x1="20" y1="0" x2="100" y2="80" />
          <line x1="30" y1="0" x2="100" y2="70" />
          <line x1="40" y1="0" x2="100" y2="60" />
          <line x1="50" y1="0" x2="100" y2="50" />
          <line x1="60" y1="0" x2="100" y2="40" />
          <line x1="70" y1="0" x2="100" y2="30" />
          <line x1="80" y1="0" x2="100" y2="20" />
          <line x1="90" y1="0" x2="100" y2="10" />

          <line x1="0" y1="0" x2="100" y2="100" />

          <line y1="10" x1="0" y2="100" x2="90" />
          <line y1="20" x1="0" y2="100" x2="80" />
          <line y1="30" x1="0" y2="100" x2="70" />
          <line y1="40" x1="0" y2="100" x2="60" />
          <line y1="50" x1="0" y2="100" x2="50" />
          <line y1="60" x1="0" y2="100" x2="40" />
          <line y1="70" x1="0" y2="100" x2="30" />
          <line y1="80" x1="0" y2="100" x2="20" />
          <line y1="90" x1="0" y2="100" x2="10" />
        </g>

        <g class="absolute" transform="translate(0,40)" *ngIf='isAbsoluteActive'>
          <circle class="medium-risk" cx="0" cy="60" [attr.r]="riskMatrixValue.absolute.highRisk" />
          <circle class="low-risk" cx="0" cy="60" [attr.r]="riskMatrixValue.absolute.lowRisk" />
        </g>


        <g class="data">
          <ng-container *ngIf="resizeOnHover && fromReliabilityCenter">
            <ng-container *ngFor="let val of riskValues;">
              <circle (mouseover)="setTooltipData(val); hoverOnSubstation(val.substationId)"
              [ngbTooltip]="substationTip" container="body" tooltipClass="planner-tooltip" placement="left"
              (mouseleave)="hoverOnSubstation(null)" *ngIf="viewTab === 'Substation'" [attr.cx]="val.risk1" [attr.cy]="100 - val.risk"
                [attr.r]="(hoverOverMarker && hoverOverMarker.getId() === val.substation) || (selectedRegion && selectedRegion.get('region') === val.region) ? 3: 2"
                fill="black" />
              <polygon *ngIf="viewTab !== 'Substation'" [contextMenu]="contextMenu ? infoContextMenu : null"
                class="point" container="body" (mouseover)="resizeOnHover ? val.scale = 3 : null"
                (mouseleave)="val.scale = 1" [attr.fill]="'black'"
                [attr.points]="val.risk +',' +(100-val.risk1 - pointSize/2) +' '+ (val.risk - pointSize/2 ) +',' +(100-val.risk1 + pointSize/2) +' '+(val.risk + pointSize/2) +',' +(100-val.risk1 + pointSize/2)" />

            </ng-container>
          </ng-container>
          <ng-container *ngIf="resizeOnHover && !fromReliabilityCenter">
            <ng-container *ngFor="let val of riskValues;let colIndex = index;">
              <polygon [contextMenu]="contextMenu ? infoContextMenu : null" class="point" triggers="mouseover" #myTip="ngbTooltip" (click)="myTip.close()" 
              [autoClose]="true" [ngbTooltip]="riskTip"
                container="body" tooltipClass="risk-tooltip" placement="bottom"
                (mouseover)="setTooltipData(val); resizeOnHover ? val.scale = 3 : null"
                (mouseleave)="resizeOnHover ? val.scale = 1 : null" [attr.fill]="'black'"
                [attr.points]="val.risk +',' +(100-val.risk1 - pointSize/2 * val.scale) +' '+ (val.risk - pointSize/2 * val.scale ) +',' +(100-val.risk1 + pointSize/2 * val.scale) +' '+ (val.risk + pointSize/2 * val.scale) +',' +(100-val.risk1 + pointSize/2 * val.scale)" />
              <polygon *ngIf="viewTab !== 'Substations'" class="point" triggers="mouseover" [autoClose]="true"  #myTip="ngbTooltip" (click)="myTip.close()" [ngbTooltip]="riskTip" container="body"
                tooltipClass="risk-tooltip" placement="bottom"
                (mouseover)="setTooltipData(val); tooltip(); hoverOnSubstation(val.substation); hoverOnAsset(val)"
                (mouseleave)="hoverOnSubstation(null); hoverOnAsset(null)" [attr.fill]="'black'"
                [attr.points]="val.risk +',' +(100-val.risk1 - pointSize/2 * ((hoverOverAsset && val.id === hoverOverAsset.id) || (hoverOverAsset === null && hoverOverMarker && hoverOverMarker.getId() === val.substation) || (selectedRegion && selectedRegion.get('region') === val.region) ? 3: 1))
                                      +' '+ (val.risk - pointSize/2 * ((hoverOverAsset && val.id === hoverOverAsset.id) || (hoverOverAsset === null && hoverOverMarker && hoverOverMarker.getId() === val.substation) || (selectedRegion && selectedRegion.get('region') === val.region) ? 3: 1))
                                      +',' +(100-val.risk1 + pointSize/2 * ((hoverOverAsset && val.id === hoverOverAsset.id) || (hoverOverAsset === null && hoverOverMarker && hoverOverMarker.getId() === val.substation) || (selectedRegion && selectedRegion.get('region') === val.region) ? 3: 1))
                                      +' '+ (val.risk + pointSize/2 * ((hoverOverAsset && val.id === hoverOverAsset.id) || (hoverOverAsset === null && hoverOverMarker && hoverOverMarker.getId() === val.substation) || (selectedRegion && selectedRegion.get('region') === val.region) ? 3: 1))
                                      +',' +(100-val.risk1 + pointSize/2 * ((hoverOverAsset && val.id === hoverOverAsset.id) || (hoverOverAsset === null && hoverOverMarker && hoverOverMarker.getId() === val.substation) || (selectedRegion && selectedRegion.get('region') === val.region) ? 3: 1))" />
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!resizeOnHover && !fromReliabilityCenter">
            <ng-container *ngFor="let val of riskValues;">

              <circle [ngbTooltip]="substationTip" container="body" tooltipClass="planner-tooltip" 
                (mouseover)="setTooltipData(val); hoverOnSubstation(val.substation)" placement="bottom"
                (mouseleave)="hoverOnSubstation(null); ; hoverOnAsset(null)" *ngIf="viewTab === 'Substations'" [attr.cx]="val.risk1"
                [attr.cy]="100 - val.risk"
                [attr.r]="(hoverOverMarker && hoverOverMarker.getId() === val.substation) || (selectedRegion && selectedRegion.get('region') === val.region) ? 3: 2"
                fill="black" />
              <polygon *ngIf="viewTab !== 'Substations'" class="point" triggers="mouseover" [autoClose]="true" 
                [ngbTooltip]="riskTip" #myTip="ngbTooltip" (click)="myTip.close()" container="body"
                tooltipClass="planner-tooltip" placement="bottom"
                (mouseover)="setTooltipData(val); tooltip(); hoverOnSubstation(val.substation); hoverOnAsset(val)"
                (mouseleave)="hoverOnSubstation(null); hoverOnAsset(null); myTip.close()" [attr.fill]="'black'"
                [attr.points]="val.risk +',' +(100-val.risk1 - pointSize/2 * ((hoverOverAsset && val.id ===(hoverOverAsset.includes(val.id)?val.id:false) ) || (hoverOverAsset === null && hoverOverMarker && hoverOverMarker.getId() === val.substation) || (selectedRegion && selectedRegion.get('region') === val.region) ? 3: 1))
                +' '+ (val.risk - pointSize/2 * ((hoverOverAsset && val.id === (hoverOverAsset.includes(val.id)?val.id:false)) || (hoverOverAsset === null && hoverOverMarker && hoverOverMarker.getId() === val.substation) || (selectedRegion && selectedRegion.get('region') === val.region) ? 3: 1))
                +',' +(100-val.risk1 + pointSize/2 * ((hoverOverAsset && val.id ===(hoverOverAsset.includes(val.id)?val.id:false)) || (hoverOverAsset === null && hoverOverMarker && hoverOverMarker.getId() === val.substation) || (selectedRegion && selectedRegion.get('region') === val.region) ? 3: 1))
                +' '+ (val.risk + pointSize/2 * ((hoverOverAsset && val.id === (hoverOverAsset.includes(val.id)?val.id:false)) || (hoverOverAsset === null && hoverOverMarker && hoverOverMarker.getId() === val.substation) || (selectedRegion && selectedRegion.get('region') === val.region) ? 3: 1))
                +',' +(100-val.risk1 + pointSize/2 * ((hoverOverAsset && val.id ===(hoverOverAsset.includes(val.id)?val.id:false)) || (hoverOverAsset === null && hoverOverMarker && hoverOverMarker.getId() === val.substation) || (selectedRegion && selectedRegion.get('region') === val.region) ? 3: 1))"
                />
              </ng-container>
          </ng-container>
        </g>
      </g>
      <g class="mask">
        <rect x="0" y="-1000" width="126" height="1009"></rect>
        <rect x="0" y="112" width="126" height="1015"></rect>
        <rect y="0" x="-1000" height="126" width="1013"></rect>
        <rect y="0" x="117" height="126" width="1011"></rect>
      </g>
      <g *ngIf="!chartOnly" class="axis y-axis" transform="translate(20,11)">
        <g class="tick" transform="translate(0,10)">
          <text dy="1.5" x="-8" y="0">90</text>
        </g>
        <g class="tick" transform="translate(0,30)">
          <text dy="1.5" x="-8" y="0">70</text>
        </g>
        <g class="tick" transform="translate(0,50)">
          <text dy="1.5" x="-8" y="0">50</text>
        </g>
        <g class="tick" transform="translate(0,70)">
          <text dy="1.5" x="-8" y="0">30</text>
        </g>
        <g class="tick" transform="translate(0,90)">
          <text dy="1.5" x="-8" y="0">10</text>
        </g>
      </g>
      <g *ngIf="!chartOnly" class="axis x-axis" transform="translate(15,106)">
        <g class="tick" transform="translate(10,0)">
          <text dx="2" x="0" y="11">10</text>
        </g>
        <g class="tick" transform="translate(30,0)">
          <text dx="2" x="0" y="11">30</text>
        </g>
        <g class="tick" transform="translate(50,0)">
          <text dx="2" x="0" y="11">50</text>
        </g>
        <g class="tick" transform="translate(70,0)">
          <text dx="2" x="0" y="11">70</text>
        </g>
        <g class="tick" transform="translate(90,0)">
          <text dx="3" x="0" y="11">90</text>
        </g>
      </g>
      <g *ngIf="!chartOnly" class="legend x-legend" transform="translate(15, 0)">
        <text class="legend-name" x="50" y="124" i18n>Importance</text>
      </g>
      <g *ngIf="!chartOnly" class="legend y-legend" transform="translate(5,50)">
        <text class="legend-name" transform="rotate(-90)" x="0" y="0">Condition</text>
      </g>
    </svg>
        <ng-template #riskTip>
          <div [ngClass]="riskBudgetInfo ? 'risk-budget-info' : 'no-budget-info'">
            <perfect-scrollbar fxFlex="auto">
            <div class="tooltip-text" *ngFor="let td of toolTipData;">
              <div>
                <h5>{{td.name}}</h5>
                <div class="tooltip-item" *ngFor="let item of td.chart">
                    <p><i class="bar-dot {{getClassName(item.name)}}" aria-hidden="true"></i>{{item?.name}}{{item?.key}}</p>
                    <p>{{item.value | DecimalDelimiter: currencyDelimiter}} {{currencyAbbreviation}}</p>
                </div>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
        </ng-template>
        <ng-template #substationTip>
            <ng-container *ngFor="let td of toolTipData;">
                <h5 class="border-bottom-0">{{td.name}}</h5>
            </ng-container>
        </ng-template>
    </div>
</div>
<button id="ele"></button>
<context-menu #infoContextMenu>
    <ng-template contextMenuItem (execute)="showInformation()">
        <span>Show information</span><i class="icon icon-info-white" aria-hidden="true"></i>
    </ng-template>
</context-menu>