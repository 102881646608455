import { Inject, NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { UserTypeResolverService } from '../services/user-type-resolver.service';

import { CreateUserProfileComponent } from './components/create-user-profile/create-user-profile.component';
// import {CreateSwmsComponent } from './containers/create-swms/create-swms.component';
// import { SldEditorComponent } from './containers/sld-editor/sld-editor.component';
// import { ProjectModellingComponent } from './containers/project-modelling/project-modelling.component';
// import { UserTypeResolverService } from '../services/user-type-resolver.service';
import { LoginComponent } from './containers/login/login.component';
import { UserConfirmationComponent } from './containers/user-confirmation/user-confirmation.component';
// import { PageNotFoundComponent } from '../app/page-not-found/page-not-found.component';
// import { PolicyComponent } from './containers/policy/policy.component';
// import { policyRoutes } from './containers/policy/policy-routing.module';
// import { assetRoutes } from './containers/assets/assets-routing.module';
// import { AssetsComponent } from './containers/assets/assets.component';
// import { SubStationInfoComponent } from './containers/sub-station-info/assets.component';
// import { PlanningComponent } from './containers/planning/planning.component';
// import { planningRoutes } from './containers/planning/planning-routing.module';
// import {ExecutionComponent} from './containers/execution/execution.component';
// import {executionRoutes} from './containers/execution/execution-routing.module';
// import {ReliabilityCenterComponent} from './containers/reliability-center/reliability-center.component';
// import {reliabilityCenterRoutes} from './containers/reliability-center/reliability-center-routing.module';
// import { WidgetsComponent } from './containers/widgets/widgets.component';
import { ExportSubstationDataComponent } from './containers/export-substation-data/export-substation-data.component';
import { AppAuthGuard } from './utility/app.guard';
import { TenantAdminComponent } from './containers/tenant-admin/tenant-admin.component';
import { AuditLogsComponent } from './containers/audit-logs/audit-logs.component';
import { AddEditProjectComponent } from './containers/add-edit-project/add-edit-project.component';
import { ProjectDashboardComponent } from './containers/project-dashboard/project-dashboard.component';
import { MainHeaderService } from './components/main-header/main-header.service';
import { MobileQrCodeComponent } from './containers/mobile-qr-code/mobile-qr-code.component';
import { OperationsCenterComponent } from './containers/operations-center/operations-center.component';
import { InstalledBaseComponent } from './containers/installed-base/installed-base.component';
import { UrlNotFoundComponent } from './containers/url-not-found/url-not-found.component';


// import { SldEditorComponent } from './containers/sld-editor/sld-editor.component';

let routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Login' },
    canActivate: [AppAuthGuard]
  },
  {
    path: 'operationscenter',
    component: OperationsCenterComponent,
    canActivate: [AppAuthGuard]
  },
  {
    path: ':projectName/importorexportComponentData',
    component: ExportSubstationDataComponent,
    canActivate: [AppAuthGuard],
  },
  {
    path: 'project',
    component: AddEditProjectComponent,
    canActivate: [AppAuthGuard],
  },
  {
    path: 'viewProject/:id',
    component: AddEditProjectComponent,
    canActivate: [AppAuthGuard],
  },
  {
    path: 'projectdashboard',
    component: ProjectDashboardComponent,
    canActivate: [AppAuthGuard],
  },
  {
    path: ':projectName/installedbase',
    component: InstalledBaseComponent,
    canActivate: [AppAuthGuard],
  },
  {
    path: 'project/:id',
    component: AddEditProjectComponent,
    canActivate: [AppAuthGuard],
  },
  {
    path: ':projectName/importorexportComponentData/:substationId/:isExport/:type',
    component: ExportSubstationDataComponent,
    pathMatch: 'full',
    resolve: {
      userType: UserTypeResolverService,
    },
    canActivate: [AppAuthGuard],
  },
  {
    path: 'tenantAdmin',
    component: TenantAdminComponent,
    canActivate: [AppAuthGuard]
  },
  {
    path: ':projectName/userConfirmation/:id',
    component: UserConfirmationComponent,
  },
  {
    path: ':projectName/audit-logs',
    component: AuditLogsComponent,
    canActivate: [AppAuthGuard],
  },
  {
    path: ':projectName/dashboard',
    // loadChildren: () => import('./containers/dashboard/dashboard.module').then(m => m.DashboardModule),
    loadChildren: () => import('./containers/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AppAuthGuard]
    // component: DashboardComponent,
    // children: dashboardRoutes,
    //canActivate: : [AuthGuard],
    // resolve: {
    //   userType: UserTypeResolverService,
    // }
  },
  {
    path: ':projectName/operations',
    loadChildren: () => import('./containers/operations/operations.module').then(m => m.OperationsModule),
    canActivate: [AppAuthGuard],
    // loadChildren: () => import('./containers/operations/operations.module').then(m => m.OperationsModule),
    data: { title: 'Operations' },
    //canActivate: : [AuthGuard]
  },

  {
    path: ':projectName/project-modelling/:id',
    // loadChildren: () => import('./containers/project-modelling/project-modelling.module').then(m => m.ProjectModellingModule),
    loadChildren: () => import('./containers/project-modelling/project-modelling.module').then(m => m.ProjectModellingModule),
	canActivate: [AppAuthGuard],
    // component: ProjectModellingComponent,
    data: { title: 'Login' },
    //canActivate: : [AuthGuard]
  },
  {
    path: ':projectName/sld-editor',
    // loadChildren: () => import('./containers/sld-editor/sld-editor.module').then(m => m.SldEditorModule),
    loadChildren: () => import('./containers/sld-editor/sld-editor.module').then(m => m.SldEditorModule),
	canActivate: [AppAuthGuard]
    // component: SldEditorComponent,
    //canActivate: : [AuthGuard]
  },
  {
    path: ':projectName/sld-editor/:view',
    // loadChildren: () => import('./containers/sld-editor/sld-editor.module').then(m => m.SldEditorModule),
    loadChildren: () => import('./containers/sld-editor/sld-editor.module').then(m => m.SldEditorModule),
	canActivate: [AppAuthGuard]
    // component: SldEditorComponent,
    //canActivate: : [AuthGuard]
  },
  {
    path: ':projectName/sld-editor/:view/:substationId',
    // loadChildren: () => import('./containers/sld-editor/sld-editor.module').then(m => m.SldEditorModule),
    loadChildren: () => import('./containers/sld-editor/sld-editor.module').then(m => m.SldEditorModule),
	canActivate: [AppAuthGuard]
    // component: SldEditorComponent,
    //canActivate: : [AuthGuard]
  },
  {
    path: ':projectName/policy',
    // loadChildren: () => import('./containers/policy/policy.module').then(m => m.PolicyModule),
    loadChildren: () => import('./containers/policy/policy.module').then(m => m.PolicyModule),
	  canActivate: [AppAuthGuard]
    // component: PolicyComponent,
    //canActivate: : [AuthGuard]
  },
  {
    path: ':projectName/policy/:id/:equip/:menu',
    // loadChildren: () => import('./containers/policy/policy.module').then(m => m.PolicyModule),
    loadChildren: () => import('./containers/policy/policy.module').then(m => m.PolicyModule),
	  canActivate: [AppAuthGuard]
    // children: policyRoutes,
    //canActivate: : [AuthGuard]
  },
  {
    path: ':projectName/assets/:id',
    // loadChildren: () => import('./containers/assets/assets.module').then(m => m.AssetsModule),
    loadChildren: () => import('./containers/assets/assets.module').then(m => m.AssetsModule),
	canActivate: [AppAuthGuard]
    // children: assetRoutes,
    //canActivate: : [AuthGuard]
  },
  {
    path: ':projectName/assets/:id/:view',
    // loadChildren: () => import('./containers/assets/assets.module').then(m => m.AssetsModule),
    loadChildren: () => import('./containers/assets/assets.module').then(m => m.AssetsModule),
	canActivate: [AppAuthGuard]
    // children: assetRoutes,
    //canActivate: : [AuthGuard]
  },
  {
    path: ':projectName/substationinfo',
    // loadChildren: () => import('./containers/sub-station-info/assets.module').then(m => m.SubStationInfoModule),
    loadChildren: () => import('./containers/sub-station-info/assets.module').then(m => m.SubStationInfoModule),
	canActivate: [AppAuthGuard]
    // children: assetRoutes,
    //canActivate: : [AuthGuard]
  },
  {
    path: ':projectName/planning',
    // loadChildren: () => import('./containers/planning/planning.module').then(m => m.PlanningModule),
    loadChildren: () => import('./containers/planning/planning.module').then(m => m.PlanningModule),
	canActivate: [AppAuthGuard],
    // children: planningRoutes,
    //canActivate: : [AuthGuard]
  },
  {
    path: ':projectName/execution',
    // loadChildren: () => import('./containers/execution/execution.module').then(m => m.ExecutionModule),
    loadChildren: () => import('./containers/execution/execution.module').then(m => m.ExecutionModule),
	canActivate: [AppAuthGuard],
    // children: executionRoutes,
    //canActivate: : [AuthGuard]
  },
  {
    path: ':projectName/reliability-center',
    // loadChildren: () => import('./containers/reliability-center/reliability-center.module').then(m => m.ReliabilityCenterModule),
    loadChildren: () => import('./containers/reliability-center/reliability-center.module').then(m => m.ReliabilityCenterModule),
	canActivate: [AppAuthGuard],
    // children: reliabilityCenterRoutes,
    //canActivate: : [AuthGuard]
  },
  {
    path: ':projectName/create-swms',
    // loadChildren: () => import('./containers/create-swms/create-swms.module').then(m => m.CreateSwmsModule),
    loadChildren: () => import('./containers/create-swms/create-swms.module').then(m => m.CreateSwmsModule),
	canActivate: [AppAuthGuard],
    data: { title: 'SWMS' },
    //canActivate: : [AuthGuard]
  },
  {
    path: ':projectName/widgets',
    // component: WidgetsComponent,
    // loadChildren: () => import('./containers/widgets/widgets.module').then(m => m.WidgetsModule),
    loadChildren: () => import('./containers/widgets/widgets.module').then(m => m.WidgetsModule),
	// canActivate: [AppAuthGuard],
    data: { title: 'widgets' },
    //canActivate: : [AuthGuard]
  },
  {
    path: ':projectName/swms/:param1/:param2',
    // loadChildren: () => import('./containers/create-swms/create-swms.module').then(m => m.CreateSwmsModule),
    loadChildren: () => import('./containers/create-swms/create-swms.module').then(m => m.CreateSwmsModule),
	canActivate: [AppAuthGuard],
    data: { title: 'SWMS' },
    //canActivate: : [AuthGuard]
  },
  {
    path: ':projectName/derivative-alarm',
    loadChildren: () => import('./containers/derivative-alarm/derivative-alarm.module').then(m => m.DerivativeAlarmModule),
    canActivate: [AppAuthGuard],
    data: { title: 'derivative-alarm' },
  },
  {
    path: ':projectName/qr-code',
    component: MobileQrCodeComponent,
    canActivate: [AppAuthGuard],
  },
  {
    path: ':projectName',
    pathMatch: 'full',
    canActivate: [AppAuthGuard],
  },
  {
    path: '',
    component: LoginComponent,
    pathMatch: 'full',
    canActivate: [AppAuthGuard]
  },
  {
    path: '**',
    component: UrlNotFoundComponent,
    pathMatch: 'full',
    canActivate: [AppAuthGuard],
  },

];

const externalProjectRoutes: Routes = [];
routes.forEach(route => {
  if (route.path.startsWith(':projectName/')) {
    const extProjectRoute = Object.assign({}, route);
    extProjectRoute.path = extProjectRoute.path.replace(/^:projectName\//, '');
    externalProjectRoutes.push(extProjectRoute);
  }
});

routes = externalProjectRoutes.concat(routes);

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  declarations: [CreateUserProfileComponent]
})
export class AppRoutingModule { }
