<div class="content-wrap all-users">
  <div class="title">
    <span i18n>{{asModal?'Members':'Manage members'}}</span>
    <input type="search" #search class="input-ctrl search-ctrl" placeholder="Search" [(ngModel)]="filterStr"
      (input)='onKey(search.value)' /> 
  </div>
  <!--end .title-->
  <div class="scroll-panel">
    <perfect-scrollbar fxFlex="auto" class="scrollbar">
    <div class="body-panel" [class.modal-body-panel]="asModal">
      <div style="padding: 20px 22px 0 22px;">
        <div class="loading-box" *ngIf="loading">
          <div class="img" alt="loading"></div>
        </div>
        <div class="all-user-table">
        <div class="filter-container" [ngClass]="{'filter-hide':!filterShow}">
        <div class="header">
          <i class="icon icon-filter" aria-hidden="true" (click)="filterShow=!filterShow" [ngClass]="{'checked':!filterShow}"></i>
          <span i18n>Column filter</span>
        </div>
        <div class="filter-option" *ngFor="let item of filterColumns;let i = index"
          (click)="item.selected=!item.selected;updateFilter()" id="{{ item.prop }}">
          <i class="icon icon-checkbox" aria-hidden="true" [ngClass]="{'checked':item.selected}"></i>
          <span>{{item.name}}</span>
        </div>
      </div>
          <ngx-datatable *ngIf="tableVisible" #usertable [rows]="allUsers" [columnMode]="'force'" rowHeight="40"
            footerHeight="56" [selected]="selected" [selectionType]="'checkbox'" (select)="onSelect($event)"
            [externalPaging]="true" [count]="page.totalElements" [offset]="page.pageNumber" [limit]="page.size"
            (page)='setPage($event)' [class.hide_footer]="search===true">
            <ngx-datatable-column prop="id" [width]="41" [sortable]="false" [canAutoResize]="false" [draggable]="false"
              [resizeable]="false">
              <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                let-selectFn="selectFn">

                <label [class.disable_add]="userSystemroleRoles.ManageusersEdit === false || isControlRoom"
                  [for]="'all-user-header' + (asModal?'-modal':'')" class="checkbox-wrap">
                  <input [id]="'all-user-header' + (asModal?'-modal':'')" type="checkbox" [checked]="allRowsSelected"
                    (change)="selectFn(!allRowsSelected)" />
                  <i class="icon icon-checkbox" aria-hidden="true" [ngClass]="{'checked':allRowsSelected}"></i>
                </label>
              </ng-template>
              <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-isSelected="isSelected"
                let-i="index" let-onCheckboxChangeFn="onCheckboxChangeFn">

                <label [class.disable_add]="userSystemroleRoles.ManageusersEdit === false || row.isPS || isControlRoom" *ngIf="!row.key"
                  [for]="'all-user-'+row.userId + (asModal?'-modal':'')" class="checkbox-wrap">
                  <input *ngIf="row.userId !== userId" [id]="'all-user-'+row.userId + (asModal?'-modal':'')"
                    type="checkbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event)">
                  <i *ngIf="row.userId !== userId" class="icon icon-checkbox" aria-hidden="true" [ngClass]="{'checked':isSelected}"></i>
                </label>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="filterColumns[0].selected" name="Member" prop='firstName' [draggable]="false">
              <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
                <div class="tcell-user">
                  <img *ngIf="row.userProfilePhoto; else templateName" [src]="row.userProfilePhoto"
                    class="icon icon-avatar" alt="Avatar" />
                  <ng-template #templateName>
                    <i class="icon icon-avatar" aria-hidden="true"></i>
                  </ng-template>
                  <span class="uname" [class.no-margin]="userType==='PM'">{{value}}</span>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="filterColumns[1].selected" name="Role" prop='role' [draggable]="false">
              <ng-template ngx-datatable-cell-template let-row="row" let-i="index">

                <span class="team-span" *ngFor="let team of row['userRolesDtoList']; let ti=index">
                  <i class="icon icon-role" aria-hidden="true" title="{{ team.roleName }}"></i>
                </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="filterColumns[2].selected" name="Employer" prop='employer' [draggable]="false">
            </ngx-datatable-column>

            <ngx-datatable-column name="Execution team" *ngIf="filterColumns[3].selected && isExecutionView" prop='executionTeams' [draggable]="false">
              <ng-template ngx-datatable-cell-template let-row="row">
                <span class="team-span" *ngFor="let team of row['teamUsersDtoList']; let ti=index">{{ team.teamName }}
                </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="filterColumns[4].selected" name="Email" prop='emailId' [draggable]="false">
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="filterColumns[5].selected" name="Phone" prop='phone'>
              <ng-template ngx-datatable-cell-template let-row="row">
                <span>+{{row['isdCode']+' - '+row['phone']}}</span>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>

          <!--end ngx-datatable-->

          <a [class.disable_add]="selected.length > 0 || isControlRoom" *ngIf="userSystemroleRoles.ManageusersEdit && showButton"
            class="add-new" (click)="addOrEditMember(null)">
            <i class="icon icon-add-new" aria-hidden="true" style="border-right: 1px solid #ababab;"></i>
            <span style="color:black" i18n>Add new member</span>
          </a>
        </div>
      </div>
    </div>
    <!-- end .view-all-user-table-section -->
    <div class="footer-panel" *ngIf="userSystemroleRoles.ManageusersEdit && showButton">
      <div class="footer-buttons">
        <a class="btn btn-secondary" [class.disabled]="!selected.length || isControlRoom" (click)="delete()" i18n>Delete</a>
        <a class="btn btn-secondary" [class.disabled]="selected.length > 1 || selected.length == 0 || isControlRoom"
          (click)="selected.length > 0 && addOrEditMember(selected[0])" i18n>Edit</a>
      </div>
    </div>
  </perfect-scrollbar>
  </div>
  <!-- end .footer -->
</div>