import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationService } from 'src/services/application.service';
import moment from 'moment';
import { SuccesspopupComponent } from '../../containers/dashboard/modals/successpopup/successpopup.component';
import { MainHeaderService } from 'src/app/components/main-header/main-header.service';
import { BackNavigationService } from 'src/services/back-navigation.service';
import { GoToProjectService } from 'src/services/go-to-project.service';

@Component({
  selector: 'app-project-dashboard',
  templateUrl: './project-dashboard.component.html',
  styleUrls: ['./project-dashboard.component.scss']
})
export class ProjectDashboardComponent implements OnInit {
  private setting = {
    element: {
      dynamicDownload: null as HTMLElement
    }
  }
  loading = false;
  allProjects: any;
  allKernelV: any;
  filteredData: any;
  filteredDataKernel: any;
  filterShow = false;
  filterColumns = [
    { prop: 'projectname', name: 'Project name', selected: true },
    { prop: 'customer', name: 'Customer', selected: true },
    { prop: 'country', name: 'Continent', selected: true },
    { prop: 'projectmanager', name: 'Project manager', selected: true },
    { prop: 'deployment', name: 'Deployment', selected: true },
    { prop: 'status', name: 'Status', selected: true },
    { prop: 'projectstartdate', name: 'Duration', selected: true }
  ];
  isSystemManagerSupportRole: boolean = false;
  userSystemroleRoles;
  selectedProjectId: any;
  selectedKernalId: any;
  SortOrder = 'ascending';
  SortColumn = 'projectname';
  SearchText: string = "";
  pagenumber;
  pageSize;
  configPlan;
  filterStr = '';
  roleName;
  constructor(private appSvc: ApplicationService,
    private modalService: NgbModal,
    private router: Router,
    private mainHeaderService: MainHeaderService,
    private backnavigation :BackNavigationService,
    private goToProjectSvc: GoToProjectService,
    config: NgbModalConfig
    ) {
    config.backdrop = 'static';
    this.configPlan = {
      id: "pagination1",
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0
    }
  }

  ngOnInit() {
    sessionStorage.removeItem('projectName');
    sessionStorage.setItem('projectId', '00000000-0000-0000-0000-000000000000');
    this.roleName = sessionStorage.getItem('currentRole');
    this.isSystemManagerSupportRole = this.roleName === 'System Manager' || this.roleName === 'System Support';
    this.getAllProjects();
  }

  getAllProjects() {
    this.loading = true;
    this.appSvc.getAllProjects(this.configPlan.currentPage, this.configPlan.itemsPerPage, this.filterStr, this.SortOrder, this.SortColumn).subscribe({next:res => {
      this.loading = false;
      res.returnObj.allProjects.forEach(row => {
        row.projStartDate = moment.utc(row.projStartDate).local().toDate();
        row.projStartDate = moment(row.projStartDate).format('YYYY-MM-DD');
        row.projEndDate = moment.utc(row.projEndDate).local().toDate();
        row.projEndDate = moment(row.projEndDate).format('YYYY-MM-DD');
      });
      this.allProjects = res.returnObj.allProjects;
      this.configPlan.totalItems = res.returnObj.totalNoOfProjects;
      this.filteredData = res.returnObj.allProjects;
    }, error: () => {
      this.loading = false;
    }});
  }
  updateFilter(event,searchKey) {
    this.loading = true;
    if(!this.isSpecialCharacter(searchKey) && searchKey.keyCode != 16 ){
      this.appSvc.getAllProjects(1, this.configPlan.itemsPerPage, event, this.SortOrder, this.SortColumn).subscribe({next:res => {
        res.returnObj.allProjects.forEach(row => {
          row.projStartDate = moment.utc(row.projStartDate).local().toDate();
          row.projStartDate = moment(row.projStartDate).format('YYYY-MM-DD');
          row.projEndDate = moment.utc(row.projEndDate).local().toDate();
          row.projEndDate = moment(row.projEndDate).format('YYYY-MM-DD');
        });
        this.allProjects = res.returnObj.allProjects;
        this.configPlan.totalItems = res.returnObj.totalNoOfProjects;
        this.filteredData = res.returnObj.allProjects;
        this.configPlan.currentPage = 1;
      }});
      this.loading = false;
    }
    this.loading = false;
  }

  isSpecialCharacter(event : any) {
    let char = event.key
    const specailCharRegexp: RegExp = /[!@#$%^&*()~`+\=\[\]{};':"\\|,.<>\/?]/;
    if(specailCharRegexp.test(char)) {
      return true;
    } else {
        return false;
      }
  }

  pageChange(event: any){
    this.configPlan.currentPage = event;
    this.getAllProjects();
  }

  onSort(event) {
    this.updateSortDirection(event);
    this.SortColumn = this.updateSortColumn(event.sorts[0].prop).toLowerCase();
    this.getAllProjects();
    this.loading = false;
  }

  updateSortDirection(event) {
    if (this.SortOrder == "ascending" && this.updateSortColumn(event.sorts[0].prop).toLowerCase() == this.SortColumn) {
      this.SortOrder = "descending";
      document.getElementsByClassName("sort-btn")[0].classList.add("sort-desc");
      document.getElementsByClassName("sort-btn")[0].classList.add("datatable-icon-up");
    }
    else {
      this.SortOrder = "ascending";
      document.getElementsByClassName("sort-btn")[0].classList.add("sort-asc");
      document.getElementsByClassName("sort-btn")[0].classList.add("datatable-icon-up");
    }
  }
  updateSortColumn(sortColumn): string {
    sortColumn = sortColumn.slice(sortColumn.indexOf(".") + 1, sortColumn.length);
    return sortColumn.charAt(0).toUpperCase() + sortColumn.slice(1);
  }


  onActivate(event) {
   if (event.cellIndex !== 7) {
    if (event.type === 'click' && (this.isSystemManagerSupportRole || this.appSvc.isControlRoom() || event.row.projectDeploymentTypeName === 'External') ) {
      this.router.navigate([`/viewProject/${event.row.projectId}`], {queryParams : {from:'Projects'}});
    } else if (event.type === 'click' && this.roleName === 'Project Member') {
      sessionStorage.setItem('projectId', event.row.projectId);
      sessionStorage.setItem('projectName', event.row.projName?.replace(/\s/g, "").toLowerCase());
      this.goToProjectSvc.goToProject();
    }
   }
  }
  openProjectInfo(event) {
    if(event.projectStatusName === "Closed" && this.roleName !== 'System Manager') {
      const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
      modalRef.componentInstance.title = 'Message';
      modalRef.componentInstance.message = 'Since project is closed, only project information file can be viewed and only the System manager can re-open it.';
    } else {
      if(this.router.url.includes('/projectdashboard')) 
      {
        this.backnavigation.path="projectdashboard";
      }
      this.router.navigate([`/viewProject/${event.projectId}`], {queryParams : {from:'Projects'}});
    }
  }
}



