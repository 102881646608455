import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { ApplicationService } from "src/services/application.service";
import { NgbModal, NgbModalConfig, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { ProjSelectionDialogComponent } from "./proj-selection-dialog/proj-selection-dialog.component";
import { Subscription } from 'rxjs';
import { ConstService } from 'src/services/const.service';
import * as RouteHelper from 'src/app/utility/routing.helper';
@Component({
  selector: 'app-installed-base',
  templateUrl: './installed-base.component.html',
  styleUrls: ['./installed-base.component.scss']
})
export class InstalledBaseComponent implements OnInit {
 loading = false;
 filterShow = false;
 filterColumnsAssets = [
  { prop: 'sldReference', name: 'SLD reference', selected: true },
  { prop: 'serialNumber', name: 'Serial number', selected: true },
  { prop: 'installation', name: 'Installation', selected: true },
  { prop: 'technology', name: 'Technology', selected: true },
  { prop: 'eqptType', name: 'Equipment type', selected: true },
  { prop: 'bayBranch', name: 'Bay/Branch', selected: true },
  { prop: 'voltage', name: 'Voltage [kV]', selected: true },
  { prop: 'manufacturer', name: 'Manufacturer', selected: true },
  { prop: 'model', name: 'Model', selected: true },
  { prop: 'year', name: 'Year', selected: true },
  { prop: 'owner', name: 'Owner', selected: true },
  { prop: 'underSLA', name: 'Under SLA', selected: true },
];
 filterColumnsInst = [
   { prop: 'Installation', name: 'Installation', selected: true },
   { prop: 'substationOwner', name: 'Owner', selected: true },
   { prop: 'substationOperator', name: 'Operator', selected: true },
   { prop: 'Country', name: 'Country', selected: true },
   { prop: 'location', name: 'Location', selected: true },
   { prop: 'YearOFInstallation', name: 'Year of installation', selected: true },
   { prop: 'YearOfComissioning', name: 'Year of commissioning', selected: true },
   { prop: 'Technology', name: 'Technology', selected: true },
   { prop: 'UnderSLA', name: 'Under SLA', selected: true }
 ];
 configPlan1;
 configPlan2;
 active = 1;
 assetsData?;
 installationsData?;
 sortOrderAssets = 'ascending';
 sortColumnAssets = 'eqptType';
 sortOrderInst = 'ASC';
 sortColumnInst = 'Installation';
 filterStrAssets = "";
 filterStrInst = "";
 filtersData? : any[];
 isAssets : boolean;
 ibaseInstallationId: string;
 ibaseAssetId: string;
 activeTabNumber: number;
 fromIIF: boolean;
 fromAIF: boolean;
 masterData2?: [] ;
 filterData:any;
 filterDataFetch: Subscription;
 installMainFilterList? = [];
 installCompFilterList? = [];
 installAllFilterListCount: number;
 assetMainFilterList? = [];
 assetCompFilterList? = [];
 assetAllFilterListCount: number;
 colWidAdj:number =0 ;
 pageSizeOption = [10,20,50,100];
 filterAPICall: string;
 isExternal = 'false';
 projectId = this.constService.emptyGuid;
 filterRemovedCount = 0; 
 filterStyles: any = { button: 30, space: 50, search: 20 };
 isProjectEnv: boolean;

constructor(private appSvc: ApplicationService, private modalService: NgbModal,
  private route: ActivatedRoute, private constService :ConstService, private router: Router) {
  this.projectId = sessionStorage.getItem('projectId');
  this.filterDataFetch = this.appSvc.getFilterList().subscribe((data) => {
    if(this.active == 1 && data.apiCall != "ProjectsMasterData"){
      this.getAllAssets();
    }
    else if(this.active == 2 && data.apiCall != "ProjectsMasterData"){
    this.getAllInstallations();
    }
  })
  if(this.route.snapshot.routeConfig.path == 'installedbase' ||  this.route.snapshot.routeConfig.path == ':projectName/installedbase')
  { 
    this.getAssetMasterData();
    this.getInstallationMasterData(); 
  }  
  
  this.configPlan1 = {
      id: "pagination1",
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0
    },
    this.configPlan2 = {
      id: "pagination2",
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0
    }
  }

  ngOnInit() {
    this.projectId = sessionStorage.getItem('projectId');
    if(this.projectId == this.constService.emptyGuid){
      this.colWidAdj = 1;
    }
    this.getAllAssets();
    this.getAllInstallations();
    this.activeTabNumber = Number(this.route.snapshot.queryParams['tab']);
    this.fromIIF = Boolean(this.route.snapshot.queryParams['fromIIF']);
    this.fromAIF = Boolean(this.route.snapshot.queryParams['fromAIF']);
    if(this.activeTabNumber){
      this.active = this.activeTabNumber;
      if(this.fromIIF || this.fromAIF){
        this.goToProjectList();
      }
    }
    // Removing Under SLA from column selector for Project Environment
    if(this.projectId != this.constService.emptyGuid){
      this.filterColumnsAssets.splice(11, 1);
      this.filterColumnsInst.splice(8, 1);
      this.filterRemovedCount = 1 ;
    }
    else{
      this.filterRemovedCount = 0 ;
    }
    this.isProjectEnv = RouteHelper.isInProjectMode();
  } 

//FUNCTIONS FOR ASSETS TABLE

  getAllAssets() {
    this.loading = true;
      let filterList  = this.filterData?.filterDataList == undefined ? [] :   (this.mainFilterTotalCount(this.filterData?.filterDataList) == 0 ? [] : [this.filterData?.filterDataList]) ; 
    const data = {           
      "projectId": this.projectId,
      "pageNumber": this.configPlan1.currentPage,      
      "pageSize": this.configPlan1.itemsPerPage,      
      "searchText": this.filterStrAssets,      
      "sortOrder": this.sortOrderAssets,      
      "sortColumn": this.sortColumnAssets,      
      "filtersdata":filterList
     }  
    this.appSvc.getAssetsDetails(data).subscribe({next:res => {
      this.filterAPICall = "AssetsDetails";
      this.loading = false;
      this.assetsData = res.returnObj.assets;
      this.configPlan1.totalItems = res.returnObj.totalNoOfData;
    }, error: () => {
      this.loading = false;
    }});
  }

onSortAssets(event) {
  this.updateSortDirectionAssets(event);
  this.sortColumnAssets= this.updateSortColumn(event.sorts[0].prop);
  this.getAllAssets();
  this.loading = false;
}

updateSortDirectionAssets(event) {
  if (this.sortOrderAssets == "ascending" && this.updateSortColumn(event.sorts[0].prop) == this.sortColumnAssets) {
    this.sortOrderAssets = "descending";
    document.getElementsByClassName("sort-btn")[0].classList.add("sort-desc");
    document.getElementsByClassName("sort-btn")[0].classList.add("datatable-icon-up");
  }
  else {
    this.sortOrderAssets = "ascending";
    document.getElementsByClassName("sort-btn")[0].classList.add("sort-asc");
    document.getElementsByClassName("sort-btn")[0].classList.add("datatable-icon-up");
  }
}

pageChangeAssets(event) {
  this.configPlan1.currentPage = event;
  this.getAllAssets();
}

pageSizeChangedAssets(event){
  this.configPlan1.itemsPerPage = event.target.value;
  this.configPlan1.currentPage = 1;
  this.getAllAssets();
}
 //FUNCTIONS FOR Installations TABLE

   getAllInstallations() {    
     this.loading = true;    
    //  this.filterData = this.appSvc.getfilterData();
      let filterList  = this.filterData?.filterDataList == undefined ? [] :   (this.mainFilterTotalCount(this.filterData?.filterDataList) == 0 ? [] : [this.filterData?.filterDataList]) ; 
     const data = {      
       "projectId": this.projectId,      
       "pageNumber": this.configPlan2.currentPage,      
       "pageSize": this.configPlan2.itemsPerPage,      
       "searchText": this.filterStrInst,      
       "sortOrder": this.sortOrderInst,      
       "sortColumn": this.sortColumnInst,      
       "filtersdata":filterList
      }    
      this.appSvc.getInstallationsDetails(data).subscribe({next:res => {      
        this.filterAPICall = "InstallationsDetails";
        this.loading = false;      
        this.installationsData = res.returnObj.totalInstallations;      
        this.configPlan2.totalItems = res.returnObj.totalNoOfSubstations;    
      }, error: () => {      
        this.loading = false;    
      }});    
    }

onSortInst(event) {
  this.updateSortDirectionInst(event);
  this.sortColumnInst= this.updateSortColumn(event.sorts[0].prop);
  this.getAllInstallations();
  this.loading = false;
}

updateSortDirectionInst(event) {
  if (this.sortOrderInst == "ASC" && this.updateSortColumn(event.sorts[0].prop) == this.sortColumnInst) {
    this.sortOrderInst = "DESC";
    document.getElementsByClassName("sort-btn")[0].classList.add("sort-desc");
    document.getElementsByClassName("sort-btn")[0].classList.add("datatable-icon-up");
  }
  else {
    this.sortOrderInst = "ASC";
    document.getElementsByClassName("sort-btn")[0].classList.add("sort-asc");
    document.getElementsByClassName("sort-btn")[0].classList.add("datatable-icon-up");
  }
}

pageChangeInst(event) {
  this.configPlan2.currentPage = event;
  this.getAllInstallations();
}
pageSizeChangedInst(event){
  this.configPlan2.currentPage = 1;
  this.configPlan2.itemsPerPage = event.target.value;
  this.getAllInstallations();
}
//Common functions for assets and installations table

search(str : string) {
  if(this.active === 1) {
    this.filterStrAssets = str;
    this.configPlan1.currentPage = 1;
    this.getAllAssets();
  } else {
    this.filterStrInst = str;
    this.configPlan2.currentPage = 1;
    this.getAllInstallations();
  }
}

updateSortColumn(sortColumn): string {
  sortColumn = sortColumn.slice(sortColumn.indexOf(".") + 1, sortColumn.length);
  return sortColumn.charAt(0) + sortColumn.slice(1);
}

onRowClick(event: any) {
  if (event.type === 'click') {
    this.ibaseAssetId = event.row?.assetId;
    this.isExternal = event.row?.isExternal;
    sessionStorage.setItem('assetId', this.ibaseAssetId);
    this.ibaseInstallationId = event.row?.installationId;
    sessionStorage.setItem('substationId', this.ibaseInstallationId);
    this.projectId = event.row?.projectId;
    sessionStorage.setItem('projectId', event.row?.projectId);
    if(this.isProjectEnv) {
      sessionStorage.setItem('projectIdfromOpCenter', event.row?.projectId);
      sessionStorage.setItem('projectNamefromOpCenter', event.row?.project); 
      if(this.active == 1){
        this.router.navigate([RouteHelper.addProjectPrefix(`/assets/${this.ibaseAssetId}/:view`)], {queryParams: {isInstBase: true, assetName: event.row?.technology, assetType: event.row?.eqptType, country: event.row?.country, installationName: event.row?.installation}});
      }
      else if(this.active == 2){
        this.router.navigate([RouteHelper.addProjectPrefix(`/substationinfo`)], { queryParams: { substationId: event.row?.installationId, classId: this.constService.substationClassId, equipmentName: this.constService.markerType, name: event.row?.installation, isInstBase: true, country: event.row?.country}, queryParamsHandling:'merge' });
      }
    }
    else {
      this.goToProjectList();
    }
  }
}

  goToProjectList(){
    const modalRef = this.modalService.open(ProjSelectionDialogComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl',
      windowClass: 'projSelectionModalClass'
    });
    modalRef.componentInstance.activeTab = this.active;
    modalRef.componentInstance.isExternal = this.isExternal;
  }
  getInstallationMasterData(){

    this.loading = true;
    this.appSvc.GetInstallationsMasterDataForOperationCenter(this.projectId).subscribe({next:(res) => {
      this.masterData2 = res.returnObj;
      let flag = 0;
      Object.keys(this.masterData2).forEach((key) => {
        if (this.projectId == this.constService.emptyGuid && this.constService.installationMasterDataForSystem.includes(key)) {
          flag = flag + this.masterData2[key].length;
          this.installMainFilterList.push({
            key: key,
            value: key
          });
        }
        else if (this.projectId != this.constService.emptyGuid && this.constService.installationMasterDataForProject.includes(key)) {
          flag = flag + this.masterData2[key].length;
          this.installMainFilterList.push({
            key: key,
            value: key
          });
        }
      }
      );   
      Object.keys(this.masterData2).forEach((key) => 
      {
        this.installCompFilterList.push({
        menuId: key,
        menuValues: Object.values(this.masterData2[key])
      });
    });
       this.installAllFilterListCount = flag;
      this.loading = false;
    }, error: () => {
      this.loading = false;      
    }});
  }

    getAssetMasterData(){
  this.loading = true;
  this.appSvc.GetAssetsMasterData(this.projectId).subscribe({next:(res) => {
    this.masterData2 = res.returnObj;
    let flag = 0;
    Object.keys(this.masterData2).forEach((key) => {
      if((key != "UnderSLA" && this.projectId != this.constService.emptyGuid) ||
        this.projectId == this.constService.emptyGuid){
          flag = flag + this.masterData2[key].length;
      this.assetMainFilterList.push({
      key: key,
      value: key
    });}
  });      
    Object.keys(this.masterData2).forEach(key => this.assetCompFilterList.push({
      menuId: key,
      menuValues: Object.values(this.masterData2[key])
    }));
    this.assetAllFilterListCount = flag;
    this.loading = false;
  }, error: () => {
    this.loading = false;      
  }});
}
mainFilterTotalCount(req){
  let flag = 0;
  Object.keys(req).forEach((key) =>  
    {
    flag = flag + req[key].length;
  });
  return flag;
} 

filterItemEmit(event) {
  this.filterData = event;
}
tabChanged(event) {
  this.filterData = undefined;
  this.filterStrAssets = '';
  this.filterStrInst = '';
  if (event.nextId ===1) {
    this.getAllAssets();
  } if (event.nextId === 2) {
    this.getAllInstallations();
  }

}
}