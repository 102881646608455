import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { QrCodeService } from 'src/services/qr-code.service';

@Component({
  selector: 'app-qr-scan-popup',
  templateUrl: './qr-scan-popup.component.html',
  styleUrls: ['./qr-scan-popup.component.scss']
})
export class QRScanPopupComponent implements OnInit {
  @Input() message;
  @Input() title;
  value = "";
  projectId: string = "";

  constructor(private qrCodeService: QrCodeService,
    public activeModal: NgbActiveModal, config: NgbModalConfig) {
    this.projectId = sessionStorage.getItem('projectId');
  }

  ngOnInit(): void {
    this.qrCodeService.getQRCodeJson(this.projectId).subscribe((res: any) => {
      if (res?.returnObj?.item2) {
        this.value = res?.returnObj?.item2;
      }
    });
  }
  close() {
    this.activeModal.dismiss('close');
  }
}
