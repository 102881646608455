<div class="loading-box" *ngIf="loading">
  <div class="img" alt="loading"></div>
</div>

<div class="page-wrapper">
  <header class="header-container">
    <h3 i18n>Audit Logs</h3>
  </header>
  <div class="d-flex justify-content-end">
  <div class="page-header">
    <span class="pr-3">Start :</span>
   <mat-form-field>
    <input matInput [ngxMatDatetimePicker]="pickerStart" placeholder="Date & Time" 
    [(ngModel)]="startDateTime" [formControl]="start_dt" [class.invalid]="validation(start_dt)">
    <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker #pickerStart [showSpinners]="showSpinners" [showSeconds]="showSeconds"
      [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"
      [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian" 
      [disableMinute]="disableMinute">
    </ngx-mat-datetime-picker>
   </mat-form-field>
    <div [class.show]="validation(start_dt)"
      class="error-message" i18n>Date & time is required.</div>
    <div [class.show]="rangeValidation()"        
      class="error-message" i18n>Invalid input.</div>
  </div>

  <div class="page-header" style="padding-left: 0px">
    <span class="pr-3">End :</span>
    <mat-form-field>
    <input matInput [ngxMatDatetimePicker]="pickerEnd" placeholder="Date & Time" 
    [(ngModel)]="endDateTime" [formControl]="end_dt" [class.invalid]="validation(end_dt)">
    <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker #pickerEnd [showSpinners]="showSpinners" [showSeconds]="showSeconds"
      [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"
      [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian" 
      [disableMinute]="disableMinute">
    </ngx-mat-datetime-picker>
    </mat-form-field>
    <div [class.show]="validation(end_dt)"
      class="error-message" i18n>Date & time is required.</div>
    <div [class.show]="rangeValidation()"        
      class="error-message" i18n>Invalid input.</div>
  </div>

  <div class="page-header download-logs">
  <a href="javascript:;" ngbTooltip="Download Logs" tooltipClass="custom-tooltip">
    <i class="icon icon-table-download" (click)="downloadAuditLogs(start_dt, end_dt)" 
       aria-hidden="true"></i>
  </a>
  </div>

  <div class="d-flex align-items-center search-audit-log">
    <input type="search" class="input-ctrl search-ctrl" placeholder="Search" 
    (keyup)="search($event.target.value)"/>
  </div>
</div>


  <div class="p-2 table-wrapper">
    <div class="freeze-table">
      <div class="filter-section">
        <div class="filter-container" [ngClass]="{'filter-hide':!filterShow}">
          <div class="header">
            <i class="icon icon-filter" aria-hidden="true" (click)="filterShow=!filterShow" [ngClass]="{'checked':!filterShow}"></i>
              <span i18n>Column filter</span>
          </div>
          <div class="filter-option" *ngFor="let item of filterColumn;let i = index" (click)="item.selected=!item.selected;">
            <i class="icon icon-checkbox"  aria-hidden="true" [ngClass]="{'checked':item.selected}"></i>
              <span>{{item.name}}</span>
          </div>
        </div>
      </div>
      <div class="scroll-panel">
        <perfect-scrollbar class="scrollbar">
          <div class="wrapper"  [perfectScrollbar]="{suppressScrollX: true}">
          <ng-container [ngTemplateOutlet]="table"></ng-container>
          </div> 
        </perfect-scrollbar>
          <div class="fixed-wrapper">
          <ng-container [ngTemplateOutlet]="table"></ng-container>
          </div>  
      </div>
    </div>
    <ng-template #table>
    <table class="base-table" aria-hidden="true">
    <mat-accordion>
    <mat-panel-title >
      <tr>
              <th class= "base-table-dateTime" *ngIf="filterColumn[0].selected" (click)="sort('dateTime')">Date
                <i class="default-sort-btn" [class.sort-asc]="this.sortDirection && (this.sortBy === 'dateTime')"
                    [class.sort-desc]="!this.sortDirection && (this.sortBy === 'dateTime')" aria-hidden="true"></i>
              </th>
              <th class= "base-table-dateTime" *ngIf="filterColumn[1].selected" (click)="sort('dateTime')">Time
                <i class="default-sort-btn" [class.sort-asc]="this.sortDirection && (this.sortBy === 'dateTime')"
                    [class.sort-desc]="!this.sortDirection && (this.sortBy === 'dateTime')" aria-hidden="true"></i>
              </th>
              <th class= "base-table-dt" *ngIf="filterColumn[2].selected" (click)="sort('substationName')">Substation Name
                <i class="default-sort-btn" [class.sort-asc]="this.sortDirection && (this.sortBy === 'substationName')"
                    [class.sort-desc]="!this.sortDirection && (this.sortBy === 'substationName')" aria-hidden="true"></i>
              </th>
              <th class= "base-table-dt" *ngIf="filterColumn[3].selected" (click)="sort('moduleName')">Module Name
                <i class="default-sort-btn" [class.sort-asc]="this.sortDirection && (this.sortBy === 'moduleName')"
                    [class.sort-desc]="!this.sortDirection && (this.sortBy === 'moduleName')" aria-hidden="true"></i>
              </th>
              <th class= "base-table-dt" *ngIf="filterColumn[4].selected" (click)="sort('userRoleName')">User Role
                <i class="default-sort-btn" [class.sort-asc]="this.sortDirection && (this.sortBy === 'userRoleName')"
                    [class.sort-desc]="!this.sortDirection && (this.sortBy === 'userRoleName')" aria-hidden="true"></i>
              </th>
              <th class= "base-table-dt" *ngIf="filterColumn[5].selected" (click)="sort('userName')">User ID
                <i class="default-sort-btn" [class.sort-asc]="this.sortDirection && (this.sortBy === 'userName')"
                    [class.sort-desc]="!this.sortDirection && (this.sortBy === 'userName')" aria-hidden="true"></i>
              </th>
              <th *ngIf="filterColumn[6].selected" (click)="sort('userActivity')">Activity
                <i class="default-sort-btn" [class.sort-asc]="this.sortDirection && (this.sortBy === 'userActivity')"
                    [class.sort-desc]="!this.sortDirection && (this.sortBy === 'userActivity')" aria-hidden="true"></i>
              </th>
              <th *ngIf="filterColumn[7].selected" (click)="sort('detailsDescription')">Detail Description
                <i class="default-sort-btn" [class.sort-asc]="this.sortDirection && (this.sortBy === 'detailsDescription')"
                    [class.sort-desc]="!this.sortDirection && (this.sortBy === 'detailsDescription')"  aria-hidden="true"></i>
              </th>
        </tr>
        </mat-panel-title>
        <tr *ngFor="let item of allLogs | paginate: config;">
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <td *ngIf="filterColumn[0].selected" title="{{item.dateTime}}" class="base-table-dateTime td_overflow">
                  {{item.dateTime}}</td>
                <td *ngIf="filterColumn[1].selected" title="{{item.time}}" class="base-table-dateTime td_overflow">
                  {{item.time}}</td>
                <td *ngIf="filterColumn[2].selected" title="{{item.substationName}}" class= "base-table-dt td_overflow">
                  {{item.substationName}}</td>
                <td *ngIf="filterColumn[3].selected" title="{{item.moduleName}}" class="base-table-dt td_overflow">
                  {{item.moduleName}}</td>
                <td *ngIf="filterColumn[4].selected" title="{{item.userRoleName}}" class="base-table-dt td_overflow">
                  {{item.userRoleName}}</td>
                <td *ngIf="filterColumn[5].selected" title="{{item.userName}}" class="base-table-dt td_overflow">
                  {{item.userName}}</td>
                <td *ngIf="filterColumn[6].selected" title="{{item.userActivity}}" class="td_overflow">
                  {{item.userActivity}}</td>
                <td *ngIf="filterColumn[7].selected" title="{{item.detailsDescription}}" class="td_overflow">
                  {{item.detailsDescription}}</td>
              </mat-expansion-panel-header>
              <mat-panel-description>
          <tr class="detail-description-row">
            <td title="{{item.detailsDescription}}" class="detail-description-col"
              *ngIf="!item.isJson && !item.isExcelFile">
              {{item.detailsDescription}}
            </td>
            <td title="{{item.detailsDescription}}" 
              *ngIf="!item.isJson && item.isExcelFile">
              {{item.detailsDescription}} <a href="javascript:;" ngbTooltip="Download Excel" (click)="downloadAuditExcelFile(item.path)"
                tooltipClass="custom-tooltip" style="width: auto;display: inline-flex;">
                {{item.path}}
              </a>
            </td>
            <td title="{{item.detailsDescription}}" class="detail-description-col" *ngIf="item.isJson">
              <ngx-json-viewer [json]="item.detailsJSON" [expanded]="false"></ngx-json-viewer>
            </td>
          </tr>
          </mat-panel-description>
          </mat-expansion-panel>
            </tr>
        </mat-accordion>
          </table>
    </ng-template> 
  </div>
</div>
<pagination-controls id="pagination1" (pageChange)="paginateData($event)" class="my-pagination"></pagination-controls>