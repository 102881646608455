import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BnNgIdleService } from 'bn-ng-idle';
import { KeycloakService } from 'keycloak-angular';
import { interval } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { AppAuthGuard } from './utility/app.guard';
import { ApplicationService } from 'src/services/application.service';
import { MainHeaderService } from './components/main-header/main-header.service';
import { NavigationEnd } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  status = 'ONLINE';
  isConnected = true;
  simpleItems = [
    { 'value': 1, 'label': 'Vilnius' },
    { 'value': 2, 'label': '22-Vilnius' },
    { 'value': 3, 'label': '33-Vilnius' }

  ];
  selectsimpleItems: any;
  showContent = true;
  RefreshTokenInterval: number = 270;
  refreshlogin;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private mainHeaderService: MainHeaderService,
   // private connectionService: ConnectionService,
    private modalService: NgbModal,
    private bnIdle: BnNgIdleService,
    private keycloak:KeycloakService,
    private appSvc:ApplicationService,
    public authGuard: AppAuthGuard) {
   // this.connectionService.monitor().subscribe(isConnected => {
   //   this.isConnected = isConnected;
   //   if (this.isConnected) {
   //     this.status = "ONLINE";
        // this.toastr.success("You are online.please refresh the page to continue the work.");
        // alert("You are online.please refresh the page to continue the work.");
    //    const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
    // modalRef.componentInstance.title = 'Message';
    //     modalRef.componentInstance.message = "You are online. Please refresh the page to continue the work."
    //  }
    //  else {
    //    this.status = "OFFLINE";
        // alert("You are offline. Please check the internet.");
        // this.toastr.error("You are offline. Please check your internet.")
     //   const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
     // modalRef.componentInstance.title = 'Message';
     //    modalRef.componentInstance.message = "You are offline. Please check your internet."
    //  }
   // });
   this.refreshlogin = this.keycloak.getKeycloakInstance();
   if(this.refreshlogin.refreshToken){
    const tokenInfo1 = this.getDecodedAccessToken(this.refreshlogin.refreshToken);
    const timeLocal = new Date().getTime();
    const timeSkew = Math.floor(timeLocal / 1000) - tokenInfo1.iat;
    const expiresIn = (tokenInfo1['exp'] - (new Date().getTime() / 1000) + timeSkew) * 1000;
    const sessionIdle = Math.round(expiresIn / 1000) - 30;
    sessionStorage.setItem('sessionIdle' , sessionIdle.toString());
    this.bnIdle.startWatching(Number(sessionStorage.getItem('sessionIdle'))).subscribe((res) => {
      if (res) {
        sessionStorage.clear();
          this.keycloak.logout(window.location.origin+'/login').then(() => this.keycloak.clearToken());
        }
    });
    this.keycloak.getToken().then(token => {
    sessionStorage.setItem('AccessToken', 'Bearer ' + token);

    this.getTokenExpireTime(token);
    });
  }else {
    sessionStorage.clear();
  }
}
  getDecodedAccessToken(token: string): any {
    try {
        return jwt_decode(token);
    }  catch (Error) {
        return null;
    }
  }
  loginRefresh(){
    this.keycloak.updateToken(30).then(a =>{
      if(!a) {
         this.loginRefresh();
      }else {
        this.keycloak.getToken().then(token => {
          sessionStorage.setItem('AccessToken', 'Bearer ' + token);
          });
      }
    });
  }

  getTokenExpireTime(token){
    const tokenInfo = this.getDecodedAccessToken(token);
    var timeLocal = new Date().getTime();
    let timeSkew = Math.floor(timeLocal / 1000) - tokenInfo.iat;
    var expiresIn = (tokenInfo['exp'] - (new Date().getTime() / 1000) + timeSkew) * 1000;
    this.RefreshTokenInterval = Math.round(expiresIn / 1000) - 30;
    interval(this.RefreshTokenInterval * 1000).subscribe((func => {
      this.loginRefresh();
      }));
  }

  ngOnInit() {
    // if we are on the landing page for a project (just project name in url),
    // then we invoke an auto-redirect to home page using the header roles refresh flow
    this.router.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe(() => {
        const params = this.route.firstChild?.snapshot.params;
        if (params && params.projectName && this.router.url === '/' + params.projectName) {
          this.appSvc.loginUserKeycloak().subscribe(res => {
            const roles = res.roles;
            sessionStorage.setItem('currentRole', roles[0]);
            sessionStorage.setItem('UserId', res.userId);
            sessionStorage.setItem('currentRoleSelected', roles[0]);
            this.mainHeaderService.onRolesUpdated();
            if (roles[0] === 'Quality SME') {
              this.router.navigateByUrl(`/${params.projectName}/execution/landing/qualitySME`);
            } else if(roles[0] === 'Execution Team Lead' || roles === 'Execution Lead') {
              this.router.navigateByUrl(`/${params.projectName}/execution/landing/teamLead`);
            } else if(roles[0] === 'Execution Owner') {
              this.router.navigateByUrl(`/${params.projectName}/execution/landing/ExecutionOwner`);
            } else if(roles[0] === 'Project Manager' || roles[0] === 'Project Support' || roles[0] === 'Project Manager (C)') {
              this.router.navigateByUrl(`/${params.projectName}/dashboard/reliability-center`);
            } else if(roles[0] === 'Policy SME') {
              this.router.navigateByUrl(`/${params.projectName}/policy`);
            } else if(roles[0] === 'Maintenance Manager') {
              this.router.navigateByUrl(`/${params.projectName}/planning/manager`);
            } else if(roles[0] === 'Operation SME' || roles === 'Remote Operator') {
              this.router.navigateByUrl(`/${params.projectName}/operations/view`);
            } else if(roles[0] === 'Planner') {
              this.router.navigateByUrl(`/${params.projectName}/planning/planner`);
            } else {
              this.router.navigateByUrl('/operationscenter');
            }
          });
        }
      });
  }
}
