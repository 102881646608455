import { ConstService } from 'src/services/const.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { MainHeaderService } from 'src/app/components/main-header/main-header.service';
import { ApplicationService } from '../../../../services/application.service';
import { ToastrService } from 'ngx-toastr';
import * as RouteHelper from 'src/app/utility/routing.helper';
import { Subscription } from 'rxjs';
import { GoToProjectService } from 'src/services/go-to-project.service';
import { SuccesspopupComponent } from '../../../containers/dashboard/modals/successpopup/successpopup.component';

@Component({
  selector: 'app-proj-selection-dialog',
  templateUrl: './proj-selection-dialog.component.html',
  styleUrls: ['./proj-selection-dialog.component.scss']
})
export class ProjSelectionDialogComponent implements OnInit {
  loading = false;
  filterAPICall : string;
  filterColumnsProjects = [
    { prop: 'project', name: 'Project', selected: true },
    { prop: 'status', name: 'Status', selected: true },
    { prop: 'startDate', name: 'Start Date', selected: true },
    { prop: 'endDate', name: 'End Date', selected: true },
  ];
  filterStyles: any = { button: 30, space: 50, search: 20 };

  projectsData : any; 
  @Input() activeTab: number;
  @Input() isExternal:string;
  footerEnabled: boolean;
  configPlan;
  sortOrderProjects = 'ASC'
  sortColumnProjects = 'ProjectName';
  selectedRow: any;
  selected: any = [];
  projectId: string;
  projectName: string;
  assetId: string;
  assetName: string;
  assetType: string;
  installationId: string;
  projectStatusName:string;
  sldRef: string;
  isInstBase: boolean;
  installationName: string;
  filterStr = '';
  country: any;
  masterData2: [] ;
  filterData:any;
  filterDataFetch: Subscription;
  projMainFilterList = [];
  projCompFilterList = [];
  projAllFilterCount: number;

constructor(private modalService: NgbModal, config: NgbModalConfig,
            private router: Router, 
            private mainHeaderService: MainHeaderService,
            private appSvc: ApplicationService,
            private toastr: ToastrService,
            private route : ActivatedRoute,
            private constService: ConstService,
            private goToProjectSvc: GoToProjectService) {
              this.filterDataFetch = this.appSvc.getFilterList().subscribe(() => {
                this.getAllProjects();
                });
              if(this.router.routerState.snapshot.url.includes('/installedbase')){
              this.getProjectMasterData();
              }
              this.configPlan = {
                id: "pagination",
                currentPage: 1,
                itemsPerPage: 5,
                totalItems: 0
              };
}

ngOnInit() {
  this.getAllProjects();
}

getAllProjects() {
    this.loading = true;
    let instID = sessionStorage.getItem('substationId');
    let assetID = sessionStorage.getItem('assetId');
    let filterList  = this.filterData?.filterDataList == undefined ? [] :   (this.mainFilterTotalCount(this.filterData?.filterDataList) == 0 ? [] : [this.filterData?.filterDataList]) ; 
      if(this.activeTab == 1){
        this.callProjectAssetsAPI(assetID,filterList);
      }
      if(this.activeTab == 2){
        this.callProjectInstallationsAPI(instID, filterList);
      }
}

callProjectAssetsAPI(assetID,filterList){
  const assetsData = {
    "AssetId": assetID, 
    "pageNumber": this.configPlan?.currentPage, 
    "pageSize": this.configPlan?.itemsPerPage, 
    "searchText": this.filterStr, 
    "sortOrder": this.sortOrderProjects, 
    "sortColumn": this.sortColumnProjects,
    "filtersdata":filterList
   };
  this.appSvc.getProjectAssetsDetails(assetsData).subscribe({next:res => {
    this.loading = false;
    this.projectsData = res.returnObj?.projectAssets;
    this.configPlan.totalItems = res.returnObj?.totalNoOfProjects;
    this.selectActiveRecord();
  }, error: () => {
    this.loading = false;
  }});
}

callProjectInstallationsAPI(instID, filterList){
  const installationsData = {
    "InstallationId": instID,
    "pageNumber": this.configPlan?.currentPage, 
    "pageSize": this.configPlan?.itemsPerPage, 
    "searchText": this.filterStr, 
    "sortOrder": this.sortOrderProjects, 
    "sortColumn": this.sortColumnProjects,
    "filtersdata": filterList
   }
  this.appSvc.getProjectInstallationsDetails(installationsData).subscribe({next:res => {
    this.loading = false;
    this.projectsData = res.returnObj?.projectInstallations;
    this.configPlan.totalItems = res.returnObj?.totalNoOfProjects;
    this.selectActiveRecord();
  }, error: () => {
    this.loading = false;
  }});
}


getRowClass = (row: any) => {
  return {
    'highlight-row': this.selected?.includes(row)
  }
}

onSortProjects(event) {
  this.updateSortDirectionProjects(event);
  this.sortColumnProjects= this.updateSortColumn(event.sorts[0]?.prop);
  this.getAllProjects();
  this.loading = false;
}

pageChangeProjects(event) {
  this.configPlan.currentPage = event;
  this.getAllProjects();
}

updateSortDirectionProjects(event) {
  if (this.sortOrderProjects == "ASC" && this.updateSortColumn(event.sorts[0]?.prop).toLowerCase() == this.sortColumnProjects) {
    this.sortOrderProjects = "DESC";
    document.getElementsByClassName("sort-btn")[0].classList.add("sort-desc");
    document.getElementsByClassName("sort-btn")[0].classList.add("datatable-icon-up");
  }
  else {
    this.sortOrderProjects = "ASC";
    document.getElementsByClassName("sort-btn")[0].classList.add("sort-asc");
    document.getElementsByClassName("sort-btn")[0].classList.add("datatable-icon-up");
  }
}
updateSortColumn(sortColumn): string {
  sortColumn = sortColumn.slice(sortColumn.indexOf(".") + 1, sortColumn?.length);
  return sortColumn.charAt(0) + sortColumn.slice(1);
}

search(str : string) {
    this.filterStr = str;
    this.getAllProjects();
}

onRowClick(event: any) {
  if(event.type === 'click') {
    this.traverseSingleRecord(event.row);
  }
}


selectActiveRecord(){
  if(this.configPlan.totalItems) 
  this.projectsData.every(record => 
    {
      if(record?.projectStatusName == 'Active'){
        this.traverseSingleRecord(record);
        return false;
      }
      return true;
    });
}


traverseSingleRecord(record:any){
  this.selectedRow = record;
  this.projectId = record?.projectId;
  this.projectName = record?.projectName;
  this.assetId = record?.assetId;
  this.installationId = record?.installationId;
  this.installationName = record?.installationName;
  this.country = record?.country;
  this.assetName = record?.assetName;
  this.assetType = record?.technology;
  this.projectStatusName = record?.projectStatusName;
  this.isInstBase = true;
  if(this.selected.includes(record)) {
    this.selected = this.selected.filter(row => row !== record);
    this.footerEnabled = false;
  }else {
    this.selected = [record];
    this.footerEnabled = true;
  }
}


close() {
  this.modalService.dismissAll();
}

goToInfoFile(){
  if(this.activeTab == 2 && this.isExternal === 'true') {
    this.close();
    const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
    modalRef.componentInstance.title = 'Message';
    modalRef.componentInstance.message = 'External projects cannot be accessed.'; 
  }
  else{
    this.close();
    sessionStorage.setItem('projectIdfromOpCenter', this.projectId);
    sessionStorage.setItem('projectNamefromOpCenter', this.projectName); 
    if(this.activeTab == 1){
      this.router.navigate([RouteHelper.addProjectPrefix(`/assets/${this.assetId}/:view`)], {queryParams: {isInstBase: this.isInstBase, assetName: this.assetName, assetType: this.assetType, country: this.country, installationName: this.installationName}});
    }
    if(this.activeTab == 2){
      this.router.navigate([RouteHelper.addProjectPrefix(`/substationinfo`)], { queryParams: { substationId: this.installationId, classId: this.constService.substationClassId, equipmentName: this.constService.markerType, name: this.installationName, isInstBase: this.isInstBase, country: this.country}, queryParamsHandling:'merge' });
    }
  }
}

goToProject() {
  this.close();
  let currentRole = sessionStorage.getItem('currentRole')
  if(currentRole === 'System Manager' || currentRole === 'System Support' || currentRole === 'Policy Manager'){
    this.toastr.error("You are not authorised to access this project.");
  }else if(currentRole === 'Control Room' && this.isExternal === 'true') {
    this.toastr.error("You are not authorised to access this project.");
  }
  else{
    sessionStorage.setItem('projectId', this.projectId);
    sessionStorage.setItem('projectName', this.projectName?.replace(/\s/g, "").toLowerCase());
    this.goToProjectSvc.goToProject();
  }
}

getProjectMasterData(){
  this.loading = true;
  this.appSvc.GetProjectsMasterDataForOperationCenter().subscribe({next:(res) => {
    this.filterAPICall = "ProjectsMasterData";
    this.masterData2 = res.returnObj;
    Object.keys(this.masterData2).forEach(key => this.projMainFilterList.push({
      key: key,
      value: key
    }));    
    Object.keys(this.masterData2).forEach(key => this.projCompFilterList.push({
      menuId: key,
      menuValues: Object.values(this.masterData2[key])
    }));
    this.projAllFilterCount = this.mainFilterTotalCount(this.masterData2);
    this.loading = false;
  }, error: () => {
    this.loading = false;      
  }});
}
  mainFilterTotalCount(req){
    let flag = 0;
    Object.keys(req).forEach(key =>{
      flag = flag + req[key].length;
    });
    return flag;
  } 
  filterItemEmit(event) {
    this.filterData = event;
  }
}
