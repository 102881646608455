<div class="loading-box" *ngIf="authGuard.showLoader$ | async">
  <div class="img" alt="loading"></div>
</div>

<div class="container" [ngClass]="{'fix-scroll':showContent}">
  <app-main-header
    *ngIf="((router.url.indexOf('/login') === -1) && (router.url.indexOf('/passwordchange') === -1) && (router.url.indexOf('/userConfirmation') === -1) && (router.url.indexOf('/report-dashboard') === -1)) && (router.url.indexOf('/forgotPassword') === -1) && (this.router.url.indexOf('/importorexportComponentData') === -1)&& (this.router.url.indexOf('/planning/download-report') === -1)"
    (callback)="callbackHeader($event)" [showExtraInfo]="router.url.indexOf('/sld-editor') !== -1">
  </app-main-header>
  <div class="main-content add-box"
    [ngClass]="{'show-content':showContent, 'login-page':router.url === '/' || router.url === '/login' || router.url === '/changePassword' || router.url === '/userConfirmation' || router.url === '/report-dashboard'}">
    <router-outlet></router-outlet>
  </div>
</div>
<app-main-footer></app-main-footer>
