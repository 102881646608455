<div class="loading-box" *ngIf="loading">
  <div class="img" alt="loading"></div>
</div>
<div class="sub-page edit-user" [class.modal-page]="mode === 'as-modal'">
  <div class="viewport">
    <header class="sub-header sub-header-sm" [class.modal-header]="mode === 'as-modal'">
      <i class="icon icon-back" aria-hidden="true" (click)="backWithoutSave()" *ngIf="mode!=='as-modal'"></i>
      <h3>{{title}}</h3>
      <button *ngIf="mode==='as-modal'" type="button" class="close" aria-label="Close"
        (click)="activeModal.dismiss('back')">
        <span aria-hidden="false">&times;</span>
      </button>
    </header>

    <div class="scroll-container" style="overflow-y: scroll;">
      <div class="role-info-section container-fluid">
        <div class="loading-box" *ngIf="loading">
          <div class="img" alt="loading"></div>
        </div>
        <div class="create-user-form" style="min-height: 10px !important" *ngIf="!addNewUser">
          <div class="emp-info">
            <div class="fig">
              <img [src]="member['userProfilePhoto']" [class.no-image]="!member['userProfilePhoto']"  alt="" accept="image/*">
              <a class="btn-upload-img">+
                <input type='file' class="file" (change)="onSelectFile($event)"></a>
            </div>
          </div>
        </div>
        <div class="group-header first-header" i18n *ngIf="mode!=='as-modal'" i18n>Profile</div>
        <div class="frow row">
          <div class="col-lg-6">
            <div class="fieldset">
              <div class="fs-lbl">
                <span i18n><sup>*</sup>Name</span>
                
              </div>
              <div class="fs-val">
                <input type="text" (change)="validateForm()" (keypress)="omit_special_char($event)" maxlength="25"
                  class="input-ctrl" [(ngModel)]="member['userName']" />
              </div>
              <span class="error-indicate" [ngClass]="{'invalid':submitted && !member['userName']}" i18n>Name is
                required</span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="fieldset">
              <div class="fs-lbl">
                <span i18n><sup>*</sup>Surname</span>
               
              </div>
              <div class="fs-val">
                <input type="text" (change)="validateForm()" (keypress)="omit_special_char($event)" maxlength="25"
                  class="input-ctrl" [(ngModel)]="member['surName']" />
              </div>
              <span class="error-indicate" [ngClass]="{'invalid':submitted && !member['surName']}" i18n>Surname is
                required</span>
            </div>
          </div>
        </div>

        <div class="frow row">
          <div class="col-lg-6">
            <div class="fieldset">
              <div class="fs-lbl">
                <span i18n><sup>*</sup>Employer</span>
               
              </div>
              <div class="fs-val">
                <input type="text" (change)="validateForm()" (keypress)="allow_special_char($event)" maxlength="50"
                  class="input-ctrl" [(ngModel)]="member['employer']" />
              </div>
              <span class="error-indicate" [ngClass]="{'invalid':submitted && !member['employer']}" i18n>Employer is
                required</span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="fieldset">
              <div class="fs-lbl">
                <span i18n><sup>*</sup>Job position</span>
               
              </div>
              <div class="fs-val">
                <input type="text" (change)="validateForm()" (keypress)="allow_special_char($event)" maxlength="50"
                  class="input-ctrl" [(ngModel)]="member['jobPosition']" />
              </div>
              <span class="error-indicate" [ngClass]="{'invalid':submitted && !member['jobPosition']}" i18n>Job position
                is required</span>
            </div>
          </div>
        </div>

        <div class="frow row">
          <div class="col-lg-6">
            <div class="fieldset">
              <div class="fs-lbl">
                <span i18n><sup>*</sup>Email</span>

              </div>
              <div class="fs-val">
                <input type="email" maxlength="50" [class.disabled]="title === 'Edit member'" (change)="validateForm()"
                  (keypress)="allow_emailCharacters($event)" class="input-ctrl" [(ngModel)]="member['emailId']" />
              </div>
              <span class="error-indicate" [ngClass]="{'invalid':submitted && !isEmail(member['emailId'])}" i18n>Should
                enter email</span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="fieldset">
              <div class="fs-lbl">
                <span i18n><sup>*</sup>Phone</span>
 
              </div>
              <div class="fs-val fs-val-fx">
                <div class="col-md-2">

                  <select class="select-ctrl" [(ngModel)]="member.countryId" (change)="changeIsd($event.target.value )">
                    <option *ngFor="let a of IsdCode" [value]="a.countryId">{{a.countryShortNameWithIsdCode}}</option>
                  </select>
                </div>
                <div class="col-md-9">
                  <input type="text" class="input-ctrl" (keypress)="only_numbers($event) " (change)="validateForm()"
                    [(ngModel)]="member['phone']" maxlength="20" />
                </div>
              </div>
              <span class="error-indicate" [ngClass]="{'invalid':submitted && !(member['phone'])}" i18n>Should enter
                phone
                number</span>
              <span class="error-indicate" [ngClass]="{'invalid':submitted && isdcodeError}" i18n>Should enter isd code
              </span>
            </div>
          </div>
        </div>

        <div class="group-header" i18n *ngIf="!addNewUser" i18n>Role</div>
        <div class="items" *ngIf="!addNewUser">
          <div [class.error]="isNoRoleSelected()" class="item" *ngFor="let role of member['userRolesDtoList']">
            <label [class.error]="isNoRoleSelected()" (click)="deleteRole(role)" [class.disabled]="isUserSelectedProjectMember()" >
              <i class="icon icon-remove" aria-hidden="true"></i>
            </label>
            <span>{{role.roleName}}</span>
          </div>
          <a class="item add-new" [class.disabled]="isUserSelectedPMC() || isUserSelectedProjectMember()"  *ngIf="!addNewUser" (click)="assignNewRole()">
            <label>
              <i class="icon icon-add-new" aria-hidden="true"></i>
            </label>
            <span [class.error]="isNoRoleSelected()" i18n>Assign role</span>
            <span *ngIf="member['userRolesDtoList']?.length === 0" class="error-indicate "
              [ngClass]="{'invalid':submitted && !isRole(member['userRolesDtoList'])}" i18n>Should add role
            </span>
          </a>
        </div>
        <div class="group-header" i18n *ngIf="addNewUser" i18n>Assign role & privileges</div>

        <div class="role-items" *ngIf="addNewUser && systemRoles">
          <a class="role-item" *ngFor="let role of allRoles" (click)="roleSelected($event,role)">
            <i class="icon icon-checkbox" aria-hidden="true" [class.checked]="role.selected"></i>
            <span>{{role.roleName}}</span>
          </a>
          <span *ngIf='isNoRoleSelected()' [class.error]="isNoRoleSelected()" class="error-indicate "
            [ngClass]="{'invalid':submitted && !isRole(member['userRolesDtoList'])}" i18n>Should add role
          </span>
        </div>

        <div *ngIf="addNewUser && !systemRoles" [class.error]="isNoRoleSelected()">
          <div>
            <app-role-card (click)="checkMaintenanceInfoVisbility()"  [role]="role" [allRoles]="allRoles">
            </app-role-card>

          </div>
          <span *ngIf='isNoRoleSelected()' [class.error]="isNoRoleSelected()" class="error-indicate "
            [ngClass]="{'invalid':submitted && !isRole(member['userRolesDtoList'])}" i18n>Should add role
          </span>
        </div>
        <div class="group-header" i18n *ngIf="showMaintenanceInfo && !addNewUser" i18n>Maintenance information</div>
        <div class="tabs tabs-maintenance" *ngIf="showMaintenanceInfo  && !addNewUser">
        <ul ngbNav #nav="ngbNav" class="nav-tabs">
          <li [ngbNavItem]="1">
           <a ngbNavLink>Execution Team</a>
           <ng-template ngbNavContent> 

              <div class="tab tab-execution-team">
                <div class="items">
                  <div class="item-row">
                    <div class="item header"></div>
                    <div class="label hourly-rate">
                      <span i18n>*Hourly rate</span>
                      <span class="error-indicate" [class.invalid]="hourlyRateMissing" i18n>Required</span>
                    </div>
                  </div>
                  <div class="item-row" *ngFor="let team of member['teamUsersDtoList']">
                    <div class="item">
                      <label (click)="removeFromTeam(team)">
                        <i class="icon icon-remove" aria-hidden="true"></i>
                      </label>
                      <span>{{team.teamName}}</span>
                    </div>
                  </div>
                  <div class="item-row">
                    <a class="item add-new" (click)="assignNewTeam()" [class.disabled]="member['teamUsersDtoList'] && member['teamUsersDtoList'].length > 0">
                      <label>
                        <i class="icon icon-add-new" aria-hidden="true"></i>
                      </label>
                      <span i18n>Assign to execution team</span>
                    </a>
                    <div class="fs-val">
                      <div class="hourly-rate">
                        <input appNumberOnly maxlength="8" [(ngModel)]="member['hourlyRate']" required>
                      </div>
                      <span class="error-indicate" [ngClass]="{'invalid':submitted && !member['hourlyRate']}"
                        i18n>Hourly rate is Required</span>
                    </div>
                    <div class="currency-text">
                      <div class="currency-value" i18n> <span>{{currency}}</span></div>
                    </div>
                  </div>
                </div>
              </div>

           </ng-template>
          </li>

          <li [ngbNavItem]="2">
            <a ngbNavLink>Skills & certificates</a>
            <ng-template ngbNavContent> 

              <div class="tab tab-skills">
                <div class="items">
                  <div class="item header" *ngIf="member['userSkillsDtoList'] && member['userSkillsDtoList'].length">
                    <span class="label" i18n>Skill name</span>
                    <div class="detail label" i18n>File</div>
                    <div class="detail label" i18n>Validity</div>
                  </div>
                  <div class="item" *ngFor="let skill of member['userSkillsDtoList']">
                    <label (click)="toggleSkill(skill)" *ngIf="userRolesObject.SkillsEdit">
                      <i class="icon icon-checkbox" aria-hidden="true" [class.checked]="skill.selected"></i>
                    </label>
                    <span>{{ skill.skillName }}</span>
                    <div class="detail" *ngIf="skill.skillCertificateName">
                      <i class="icon icon-file" aria-hidden="true"></i>
                      <span class="filename"
                        (click)="downloadFile(skill.skillCertificateFile, skill.skillCertificateName)">{{ skill.skillCertificateName }}</span>
                    </div>
                    <div class="detail" *ngIf="skill.skillCertificateName">
                      <span class="date-value">{{ skill.skillCertificateValidityPeriodMonth }}</span>
                      <span class="date-value">{{ skill.skillCertificateValidityPeriodYear }}</span>
                    </div>
                  </div>
                  <a class="item add-new" (click)="assignOrEditSkill()">
                    <label>
                      <i class="icon icon-add-new" aria-hidden="true"></i>
                    </label>
                    <span i18n>Assign skill</span>
                  </a>
                  <div class="skill-buttons">
                    <a class="btn btn-edit" [class.disabled]="!selectedSkills.length"
                      (click)="deleteSelectedSkills(member['userSkillsDtoList'])" i18n>Delete</a>
                    <a class="btn btn-edit" [class.disabled]="selectedSkills.length!==1"
                      (click)="selectedSkills.length > 0 && assignOrEditSkill(selectedSkills[0])" i18n>Edit</a>
                  </div>
                </div>
              </div>

            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
        
        <div *ngIf="addNewUser" class="bar-action fx-r">
          <a class="btn btn-save btn-save-form" (click)="save()">Save</a>
        </div>
        <div *ngIf="!addNewUser" class="bar-action fx-r">
          <a class="btn btn-save btn-save-form" (click)="save2()">Save</a>
        </div>
      </div>
    </div>
  </div>
</div>
