<div class="loading-box"  *ngIf="loading">
  <div class="img"  alt="loading"></div>
</div>
<div class="map-wrapper" [class.view-mode]="viewMode" [class.embedded]="embedded" [class.operations-mode]="operationMode">
    <app-information-explorer-side #IeSideTool
    (substationHover)="substationHover($event)"
    (assetHover)="assetHover($event)"
    [hoverOverAsset]="hoverOverAsset"
    (InstallationHover)="InstallationHover($event)"
    (projectHover) = "projectHover($event)"
    (tabChange)="tabChange($event)"
    (searchEnd)="substationFiltered($event)"
    [onlySubstations]="operationMode"
    (updateMap)="updateMap()"
    (updateFilters)="updateFilters(currentTab)"
    [tab]="viewTab"
    [installMainFilterList]="installMainFilterList"
    [installCompFilterList]="installCompFilterList"
    [installAllFilterListCount]="installAllFilterListCount"
    [projMainFilterList]="projMainFilterList"
    [projCompFilterList]="projCompFilterList"
    [projAllFilterListCount]="projAllFilterListCount"
    [data]="mapData ? { assets: mapData.riskMatrix, substations: mapData.markers, totalProjects:mapData.totalProjects, totalInstallations:mapData.totalInstallations} : null"
    [hoverOverMarker]="hoverOverMarker"
    [selectedRegion]="selectedRegion"
    (filterItemPassEmit)="filterItemPassEmit($event)"
    *ngIf="mapData && markerList.length !== 0 && ((!embedded && viewMode) || operationMode)"></app-information-explorer-side>
  <div class="markers" *ngIf="markerList.length !== 0 && !embedded && !viewMode && !isInformationExplorer && !isOperationsCenter">


    <div class="marker-tab">
      <a (click)="legendTab = 'Substations'" [class.active]="legendTab === 'Substations'" href="javascript:;">Installations</a>
      <a (click)="legendTab = 'Connections'" [class.active]="legendTab === 'Connections'" href="javascript:;">Connections</a>
    </div>
<ng-container *ngIf="legendTab === 'Substations'">
  <div *ngIf="legend" class="search-wrapper">
    <input [(ngModel)]="legendQuery" class="form-input" type="search"
           placeholder="Search {{markerList.length}} Installations"/>
    <a href="javascript:;">
      <i class="icon icon-export" aria-hidden="true"></i>
      <div>
        <a href="javascript:;" (click)="importConnection()" *ngIf="!gridViewMode">Import .csv</a>
        <a href="javascript:;" (click)="exportConnection()">Export .csv</a>
      </div>
    </a>
  </div>
  <ul *ngIf="legend">

    <div class="table-header">
      <div class="name" (click)="sortSubstation('name')">Name
        <i class="sort-btn" aria-hidden="true"
           [class.sort-asc]="this.sortDirectionSubstation && (this.sortBySubstation === 'name')"
           [class.sort-desc]="!this.sortDirectionSubstation && (this.sortBySubstation === 'name')"></i>
      </div>
      <div (click)="sortSubstation('region')" class="region">Country
        <i class="sort-btn" aria-hidden="true"
           [class.sort-asc]="this.sortDirectionSubstation && (this.sortBySubstation === 'region')"
           [class.sort-desc]="!this.sortDirectionSubstation && (this.sortBySubstation === 'region')"></i>
      </div>
    </div>

    <perfect-scrollbar fxFlex="auto">
    <div class="substation-list">
      <ng-container *ngFor="let marker of markerList">
      <li *ngIf="marker.name.toLowerCase().indexOf(legendQuery.toLowerCase()) !== -1"
          (mouseover)="hoverOnSubstation(marker.id)"
          (mouseleave)="hoverOnSubstation(null)"
          [class.active]="hoverOverMarker !== null ? hoverOverMarker.getId() === marker.id : false">
          <div class="name" title="{{marker.name}}">{{marker.name}}</div>
          <div class="region">{{marker.region}}</div>
      </li>
    </ng-container>
    </div>
    </perfect-scrollbar>
  </ul>
</ng-container>

<ng-container *ngIf="legendTab === 'Connections'">
  <div class="search-wrapper">
    <input [(ngModel)]="connectionQuery" class="form-input" type="search"
           placeholder="Search {{connectionList.length}} connections"/>
    <a href="javascript:;">
      <i class="icon icon-export" aria-hidden="true"></i>
      <div>
        <a href="javascript:;" (click)="importConnection()" *ngIf="!gridViewMode">Import .csv</a>
        <a href="javascript:;" (click)="exportConnection()">Export .csv</a>
      </div>
    </a>
  </div>
  <div class="table-header">
    <div class="name" (click)="sortConnection('name')">Connection
      <i class="sort-btn" aria-hidden="true"
         *ngIf="this.sortByConnection === 'name'"
         [class.sort-desc]="this.sortDirectionConnection"
         [class.sort-asc]="!this.sortDirectionConnection"></i>
    </div>
  </div>
  <perfect-scrollbar fxFlex="auto" [scrollIndicators]="true">
  <div class="accordion-wrapper">
    <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
      <ng-container
        *ngFor="let con of connectionList">
        <ng-container *ngIf="con.name.toLowerCase().indexOf(connectionQuery.toLowerCase()) !== -1">
          <ngb-panel
          >
            <ng-template ngbPanelTitle>
              <h5 (click)="showConnections($event, con.line)">{{con.name}}</h5>
              <a href="javascript:;"></a>
            </ng-template>
            <ng-template ngbPanelContent>
              <ul>
                <li *ngFor="let itm of con.items">
                  <div class="label text-truncate">{{itm.reference}}</div>
                  <div class="label text-truncate">{{itm.origin}}</div>
                  <div class="label text-truncate">→</div>
                  <div class="label text-truncate">{{itm.destination}}</div>
                </li>
              </ul>
            </ng-template>
          </ngb-panel>
        </ng-container>
      </ng-container>
    </ngb-accordion>
  </div>
  </perfect-scrollbar>
</ng-container>
</div>


  <div id="map" class="map" [class.image-selected]="imageSelected"></div>

  <div *ngIf="!viewMode && userRolesObject.GISSLDEditorEdit && showIcons && !isOperationsCenter" class="right-side-controls"
  [class.disabled]="gridViewMode">
    <a [class.fade-out]="enableForConnect" (click)="addMarkerEvent(nodeTypes.substation)"
      [class.active]="selectedNodeType === nodeTypes.substation" class="add-button add-substation" href="javascript:;">
      <small i18n>Add<br />
        Installation
      </small>
      <i aria-hidden="true"></i>
    </a>

    <a *ngIf="userRolesObject.GISSLDEditorEdit" [class.fade-out]="enableForConnect" class="add-button add-load-source"
      href="javascript:;">
      <small i18n>Source
        and Load
      </small>
      <div class="icon-wrapper">
        <i (click)="addMarkerEvent(nodeTypes.source)" [class.active]="selectedNodeType === nodeTypes.source"
          class="source" aria-hidden="true"></i>
        <i (click)="addMarkerEvent(nodeTypes.load)" [class.active]="selectedNodeType === nodeTypes.load"
          class="load" aria-hidden="true"></i>
      </div>
    </a>

    <a *ngIf="userRolesObject.GISSLDEditorEdit" [class.fade-out]="enableForConnect"
      (click)="addMarkerEvent(nodeTypes.joint)" [class.active]="selectedNodeType === nodeTypes.joint"
      class="add-button add-joint" href="javascript:;">
      <small i18n>Joint</small>
      <i aria-hidden="true"></i>
    </a>

    <a [class.fade-out]="userRolesObject.GISSLDEditorEdit" [class.disabled]="!isPossibleToConnect()"
      (click)="enableConnections();" class="add-button add-connection" href="javascript:;">
      <small i18n>Connect</small>
      <i aria-hidden="true"></i>
    </a>
  </div>
  <div class="zoom" *ngIf="!embedded || operationMode">
    <a href="javascript:;" (click)="zoomOut()"></a>
    <a href="javascript:;" (click)="isOperationsCenter ? opCenterFocus() : focus()"></a>
    <a href="javascript:;" (click)="zoomIn()"></a>
  </div>

 <div class="view" *ngIf="!embedded && !isInformationExplorer && !isOperationsCenter">
    <button></button>
    <button [disabled]="isSLDNavigationDisabled()" *ngIf="!gridViewMode" [routerLink]="projectRoutePrefix + '/sld-editor'" [queryParams]="getParameters()"></button>
    <button [disabled]="isSLDNavigationDisabled()" *ngIf="gridViewMode" [routerLink]="projectRoutePrefix + '/sld-editor/true'"></button>
    <button></button>
  </div>

  <div *ngIf="!imageSelected" class="menu-bar-container">
    <div #menuBarDiv class="menu-bar">
      <div *ngFor="let item of menuBar" class="menu-item" (click)="toggleMenuItem(item)">
        <div class="menu-item-box" [class.active]="selectedMenuItem===item">
          <div class="icon-box">
            <img [src]="iconsPath + item.icon + '.svg'" alt="Box" [hidden]="selectedMenuItem===item">
            <img [src]="iconsPath + item.icon + '-white.svg'" alt="Box" [hidden]="selectedMenuItem!==item">
          </div>
          <div class="separator"></div>
          <div class="chevron">
            <img [src]="iconsPath + 'chevron.svg'" alt="Chevron" [hidden]="selectedMenuItem===item">
            <img [src]="iconsPath + 'chevron-white.svg'" alt="Chevron" [hidden]="selectedMenuItem!==item">
          </div>
        </div>
        <div class="menu-sub-item-list-container" *ngIf="selectedMenuItem === item">
          <div class="menu-sub-item-list">
            <div *ngFor="let subItem of item.subItems" class="menu-sub-item" [class.disabled]="!subItem.enabled" [class.disabled]="!subItem.enabled"
              (click)="subItem.enabled && onClickMenuSubItem(subItem)">
              {{ subItem.name }}
              <img *ngIf="(subItem.icon && !subItem.isActivated) || (subItem.icon && subItem.activated)" [src]="iconsPath + subItem.icon + '-white.svg'" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="input-file-container">
        <input #uploadImageRef type="file" accept=".geojson" (change)="onMapBackgroundImageUpload($event)">
      </div>
    </div>
  </div>

  <div class="component-data-dialog-container-wrapper" *ngIf="componentDataDialog.show">
    <div class="component-data-dialog-container">
      <app-asset-info (close)="componentDataDialog={ show: false }" (deleteMarker)="deleteMarker($event)"
        (sld)="goToSLD()" [viewMode]="viewMode" [queryParam]="getParameters()" [nodeType]="componentDataDialog.nodeType"
        (save)="saveComponentData($event)" [data]="componentDataDialog.data">
      </app-asset-info>
    </div>
  </div>
  <div class="risk-matrix-wrapper" *ngIf="!embedded && viewMode">
    <app-budget-risk-matrix [chartOnly]="false"
    (substationHover)="substationHover($event)"
    (assetHover)="assetHover($event)"
    [pointSize]="3"
    [viewTab]="viewTab"
    [riskValues]="riskValues"
    [hoverOverMarker]="hoverOverMarker"
    [hoverOverAsset]="hoverOverAsset"
    [selectedRegion]="selectedRegion"></app-budget-risk-matrix>

    <div class="slider-margin" *ngIf="isInformationExplorer && currentYear">
    <mat-slider class="slider" [min]="min" [max]="max" [step]="1" discrete="true"
        (input)="sliderChange($event)" showTickMarks="true" discrete="true">
        <input matSliderThumb [(ngModel)]="currentYear" [(ngValue)]="currentYear">
    </mat-slider>
  </div>
  </div>


</div>
<input #inputConnectionCVSFileRef type="file" class="hide" accept="text/csv"
       (change)="onConnectionCVSSelected($event)">
