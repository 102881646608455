export class UserRole {
    public static ProjectManager = "Project Manager";
    public static ProjectManager_C ="Project Manager (C)";
    public static ProjectSupport = "Project Support";
    public static QualitySME = "Quality SME";
    public static PolicySME = "Policy SME";
    public static OperationSME = "Operation SME";
    public static RemoteOperator = "Remote Operator";
    public static Planner = "Planner";
    public static ExecutionTeamLead = "Execution Team Lead";
    public static MaintenanceManager = "Maintenance Manager";
    public static ExecutionOwner = "Execution Owner";
    public static SystemManager = "System Manager";
    public static SystemSupport = "System Support";
    public static TenantAdmin = "Tenant Admin";
    public static PolicyManager = "Policy Manager";
    public static ProjectMember = "Project Member";  
    public static ControlRoom = "Control Room";
}