import { Component,OnInit } from '@angular/core';

import { ApplicationService } from '../../../services/application.service';



@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss'],
})
export class MainFooterComponent implements OnInit {
 message;
 loading = false;


  constructor(
    public appSvc: ApplicationService) {
  }

 


 

  ngOnInit() {
    this.appSvc.GetCopyrightDetails().subscribe(res => {
     this.message= res.returnObj.item2;
    });
  }

}
