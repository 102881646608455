<div class="all-roles-list" [perfectScrollbar]="{suppressScrollY: true}">
 <div *ngFor="let role of allRoles">
  <div class="card-role-container">
   <div class="card-role" [class.disabled]="role.eventSelected">
   <div class="card-role-wrap">
     <div class="card-header">
      <input id="{{role.roleName}}" type="checkbox" [checked]="role.selected" (change)="Changed(role,$event)"/>
       <i class="icon icon-role-black" aria-hidden="true"></i>
       <span class="role">{{role.roleName}} </span>
     </div>
     <div class="card-body" (click)="roleClick.emit()">
      <div class="item" *ngFor="let privilege of role.assignDescriptions[0].description">
         <div class="permission">
           
           <ul>
             <li>
               <div style="margin-top: -20px;margin-left: 20px;">{{privilege}}</div>
             </li>
           </ul>
         </div>
         
       </div>
     </div>     </div>
    </div>
  </div>
 </div>
</div>
